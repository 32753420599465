export const white = "#FFFFFF";
export const black_10 = "#464356";
export const offWhite = "#F4F4F6";
export const blue_500 = "#1142AA";
export const black = "#0C1D37";
export const blue_400 = "#1550CF";
export const red_300 = "#FF5355";
export const systemRed = "#CC353D";
export const blackLight= "#1E3557";
export const formBlack = "#0C1D37";
export const snow = "#FDF8F4";
export const ando_offWhite = "#F7F7F7";
export const royalBlue = "#044EBD";
export const royalBlueBackground = "rgba(4, 78, 189, 0.2)";
export const blackLightest = 'rgba(61, 89, 130, 1)';
export const antiqueWhite = 'rgba(253, 230, 214, 1)';
export const gray = "rgb(244, 244, 246)";
export const blackLighter = '#364E72';  
export const red = "#FF0105";
