import fetchIntercept from 'fetch-intercept';
import login from './login';

const unregister = fetchIntercept.register({
    request: function (url, config) {
        // Modify the url or config here
        console.log("URL is:", url);
        // console.log()
        const token = login.getToken();  
        if (token != null) {
              config.headers.authorization = `Bearer ${token}`;
        }
        if (config) {
            return [url, config];
        } else {
            return url;
        }
        
    
        // ,
        //   error => {
        //     return Promise.reject(error);
        //   }

    },

    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        console.log("request error is:", error);
        return Promise.reject(error);
    },

    response: function (response) {
        // Modify the reponse object
        // console.log("response is", response);
        // let status = response.status;

        // If token present update in local storage
        return response;
    },

    responseError: function (error) {
        // Handle an fetch error
        console.log("response error is", error)
        return Promise.reject(error);
    }
});

export default fetch;
