import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    typography: {
      fontFamily: [
        'Work Sans', 'sans-serif'
      ].join(','),
    },
    palette: {
      primary: {
        main: '#0C1D37'
      },
      secondary: {
        main: '#044EBD'
      },
    }
  });

  export default theme