import React, { useEffect, useState } from "react";
import { AppBar, Button, CircularProgress, Collapse, Container, createTheme, FormControlLabel, IconButton, Link, TextField, Toolbar, Typography } from "@material-ui/core";
import { makeStyles, styled, ThemeProvider, withStyles } from "@material-ui/styles";
// import andoLogo from ''
import { black, blackLight,  systemRed, formBlack, offWhite,   white, gray } from "../constants/colors";
import * as yup from 'yup';
import { useFormik } from 'formik';
import fetch from '../utils/interceptor';
import apiService from "../api";
import login from "../utils/login";
import { useHistory } from 'react-router-dom';
import useStyles from "./styles";
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { getToken, onMessageListener, pataToken } from "../firebase";



const validationSchema = yup.object({
    userName: yup
      .string('Enter your email')
      // .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string('Enter your password')
      .min(5, 'Password should be of minimum 5 characters length')
      .required('Password is required'),
  });

    
    const theme = createTheme({
      typography: {
        fontFamily: [
          'Work Sans', 'sans-serif'
        ].join(','),
      },
      palette: {
        primary: {
          main: '#0C1D37'
        }
      }
    });
      theme.shadows.push("0px 3px 5px rgba(26, 25, 32, 0.15)" )
      
    const ContentContainer = styled(Container)(({ theme }) => ({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: offWhite,
      flex: 1,
    }));
  
  const RootContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    maxHeight: "100vh",
    width: "100%",
    backgroundColor: offWhite,
    // margin: 0,
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0,
  }));
  
  
  

  function Copyright(props) {
    return (
      <Typography
        className={props.className}
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright © "}
        <Link color="inherit" href="https://andofoods.co/">
          AndoFoods
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  const textInputStyles = () => ({
    input: {
      backgroundColor: white
    },
    focused: {
      // background: "red"
    }
  });
  

export default function SignIn() {
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [severity, setSeverity] = useState("error");
  const showNotification = (severity, text) => {
    setSeverity(severity);
    setShowError(true)
    setError(text);
  };

  const history = useHistory();
  const [isTokenFound, setTokenFound] = useState(false);
  const [savedToken, setSavedToken] = useState(null);
  // pataToken(setTokenFound);

  // Redirect if already logged in
  const userIsLogged = login.useLoginStatus();
  if (userIsLogged) {
    history.push('/batch');
    // window.location.reload({ forcedReload: true });

  } else {
    // Do nothing
  }
  onMessageListener().then(payload => {
    // setShow(true);
    // setNotification({title: payload.notification.title, body: payload.notification.body})
    Alert("Notification Received, pls check console");
    console.log(payload);
  }).catch(err => console.log('failed: ', err));

    const formik = useFormik({
        initialValues: {
          userName: '',
          password: '',
        },
        validationSchema: validationSchema,
        onSubmit:  async (values) => {
          // Login here
          if (savedToken){
            let data ={username: values.userName, password: values.password, fcmToken: savedToken};
            console.log("data is:", data)
            new Headers({'content-type': 'application/json'})
  
            let response = await apiService.signIn(data);
            console.log("response is", response);
            try {
              console.log(response.status); // Will show you the status
              if (!response.ok) {
                  throw new Error("HTTP status " + response.status);
              }
              let result = await response.json();
              console.log("result is", result);
              if (result.authToken != null) {
                login.setToken(result.authToken)
                login.setKitchenId(result.kitchenId)
                login.setKitchenUserId(result.id)
                console.log("Body is", result)
                setSeverity("success");
                setError("Successfully Signed in!");
                setShowError(true);
                // Redirect to /form
                history.push('/batch');
                window.location.reload({ forcedReload: true });
  
              } else {
                throw new Error("HTTP status " + result.authToken);
              }
            } catch (error) {
              console.log('error', error);
              formik.setSubmitting(false)
              if (error === 'Error: HTTP status 401') {
                setError("Sign in Failed: Invalid credentials" );
                setShowError(true);
              } else{
                setError("Sign in Failed" );
                setShowError(true);
              } 
            }
         } else {
          setSeverity("info");
          setError("You need to allow notifications to sign in" );
          setShowError(true); 
         }
   
        },
      });

      useEffect(() => {
        pataToken(setTokenFound, setSavedToken, showNotification)
        
      }, []);

      let classes = useStyles();
    return (
      <ThemeProvider theme={theme}>
        <RootContainer
          className={`${classes.rootContainer} "rootContainer"`}
          id="rootContainer"
        >
          <AppBar className={classes.appBar} position="static">
            <Toolbar className={classes.toolbar}>
              <Container sx={{ flexGrow: 1 }}>
                <Link href="/">
                  <img
                    className={classes.logoAppbar}
                    sx={{ maxWidth: "inherit", maxHeight: "inherit" }}
                    src="/ando-logo.svg"
                    alt="ando logo"
                    layout="fixed"
                  />
                </Link>
              </Container>
            </Toolbar>
          </AppBar>
          {showError && (
            <Collapse in={showError}>
              <Alert
                severity={severity}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShowError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {error}
              </Alert>
            </Collapse>
          )}
          {/* {isTokenFound && <h1> Notification permission enabled 👍🏻 </h1>}
          {!isTokenFound && <h1> Need notification permission ❗️ </h1>} */}
          <ContentContainer sx={{ marginTop: "5vh" }} maxWidth="sm">
            <Container className={classes.intro}>
              <Typography
                className={classes.topIntro}
                variant="h5"
                component="div"
              >
                Sign In
              </Typography>
              <Typography
                className={classes.requiredText}
                variant="p"
                component="div"
              >
                *Required Field
              </Typography>
            </Container>
            <Container className={classes.formContainer}>
              <form
                className={classes.formikForm}
                onSubmit={formik.handleSubmit}
              >
                <Container
                  className={`${classes.formikWrapper} "rootContainer"`}
                  id="wrapperContainer"
                >
                  <Container
                    className={classes.inputFields}
                    id="wrapperContainer"
                  >
                    <Container
                      className={classes.textFieldWrapper}
                      id="wrapperContainer"
                    >
                      <Typography
                        className={`${classes.textLabel} required`}
                        variant="body2"
                        component="div"
                      >
                        Username
                      </Typography>
                      <TextField
                        className={`${classes.nameEntryField} "txtInput"`}
                        fullWidth
                        id="userName"
                        name="userName"
                        // label="Username"
                        value={formik.values.userName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.userName &&
                          Boolean(formik.errors.userName)
                        }
                        // helperText={
                        //   formik.touched.userName && formik.errors.userName
                        // }
                        helperText={
                          (formik.touched.userName && formik.errors.userName) ||
                          "Enter username"
                        }
                        FormHelperTextProps={{
                            className: classes.helperText,
                            
                          }}
                        placeholder="Username"
                        variant="filled"
                        margin="normal"
                        required={true}
                        inputProps={{
                          style: {
                            // backgroundColor: white,
                            paddingBottom: "1.25em",
                          },
                        }}
                        InputProps={{
                          className: classes.txtInput,
                          classes: {
                            root: classes.txtInput,
                          },
                        }}
                        onFocus={(e) => {
                          if (isTokenFound) {
                            // Do nothing
                          } else {
                            // Request for permissions
                            pataToken(setTokenFound, setSavedToken, showNotification);
                          }
                        }}
                      />
                    </Container>
                    <Container
                      className={classes.textFieldWrapper}
                      id="wrapperContainer"
                    >
                      <Typography
                        className={`${classes.textLabel} required`}
                        variant="body2"
                        component="div"
                      >
                        Password
                      </Typography>
                      <TextField
                        className={classes.phoneNumberEntryField}
                        fullWidth
                        id="password"
                        name="password"
                        // label="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        type="password"
                        required={true}
                        helperText={
                          (formik.touched.password && formik.errors.password) ||
                          "Enter your password"
                        }
                        FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                        placeholder="Password"
                        variant="filled"
                        margin="normal"
                        inputProps={{
                          style: {
                            backgroundColor: white,
                            paddingBottom: "1.25em",
                          },
                        }}
                      />
                    </Container>
                    <Container className={classes.buttonContainer}>
                      <Button
                        className={classes.sigInButton}
                        id="styledButton"
                        loading={formik.isSubmitting}
                        disabled={!formik.dirty || formik.isSubmitting}
                        size="large"
                        color="primary"
                        type="submit"
                        fullWidth
                        onClick={ async () => {
                          if (isTokenFound) {
                            // Submit Form
                            formik.setSubmitting(true);
                            formik.handleSubmit();
                          } else {
                            // Prompt for token
                            if ( await pataToken(setTokenFound, setSavedToken, showNotification)){ 
                              formik.setSubmitting(true);
                              formik.handleSubmit();
                            } else {
                              setSeverity("info");
                              setError(
                                "You need to allow notifications to sign in"
                              );
                              setShowError(true); 
                            }
                          }

                        }}
                        variant="contained"
                        sx={{
                          mt: 3,
                          mb: 2,
                          backgroundColor: black,
                          borderRadius: 40,
                        }}
                      >
                        Sign In
                      </Button>
                      <Copyright className={classes.copyRightText} />
                    </Container>
                  </Container>
                </Container>
              </form>
            </Container>
          </ContentContainer>
        </RootContainer>
      </ThemeProvider>
    );
}
