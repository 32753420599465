import "./App.css";
import SignIn from "./SignIn";
import { BrowserRouter as Router, Route } from "react-router-dom";
// import FullForm from "./FullForm";
import PrivateRoute from "./PrivateRoute";
import Dispatch from "./Dispatch";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import BatchedOrder from "./BatchedOrder";


function App() {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router>
        <Route exact path={"/"} component={SignIn} />
        <Route path={"/signin"} component={SignIn} />
        <PrivateRoute path={"/dispatch"}>
          <BatchedOrder />
        </PrivateRoute>
        <PrivateRoute exact path={"/batch"}  >
          <BatchedOrder />
        </PrivateRoute>
      </Router>
    </MuiPickersUtilsProvider>
  );
}

export default App;
