import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '0.5em',
    padding: 0
  },
  labelIcon: {
    order: 0,
  },
  labelText: {
    order: 1,
  },
});

export default useStyles;