import * as yup from "yup";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const validationSchema = yup.object({
  orderId: yup.string("Enter the order number"),
  orderAcceptedTime: yup
    .date()
    .nullable()
    .required("Order accepted time is required"),
  orderSource: yup
    .string("Enter the order source")
    .required("Order source is required"),
  brandId: yup
    .string("Enter the restaurant")
    .required("Restaurant is required"),
  paymentStatus: yup
    .string("Enter the payment status")
    .required("Payment status is required"),
  customerName: yup
    .string("Enter the customer's name")
    .required("Name is required"),
  customerNumber: yup
    .string("Enter the Customer's phone number").min(2, "Enter num")
    .when("orderSource", {
      is: (value) => {
        let shouldHaveNums = ["GLOVO", "ORGANIC"].includes(value);
        console.log("Should have nums:", shouldHaveNums);
        console.log("Value:", value);
        return shouldHaveNums;
      },
      then: yup
        .string()
        .min(1, "Customer number is required")
        .required("Customer number is required")
        .test('is-valid-phone', 'Please enter a valid phone number. It should be a 10-digit local number or a valid international number.', (value) => {
          if (!value) return false;
          const localPhoneRegex = /^0\d{9}$/;
          if (localPhoneRegex.test(value)) {
            return true;
          }
          const phoneNumber = parsePhoneNumberFromString(value);
          return phoneNumber ? phoneNumber.isValid() : false;
        }),
    }),
    // .test('is-valid-phone', 'Please enter a valid phone number. It should be a 10-digit local number or a valid international number.', (value) => {
    //   if (!value) return false;
    //   const localPhoneRegex = /^0\d{9}$/;
    //   if (localPhoneRegex.test(value)) {
    //     return true;
    //   }
    //   const phoneNumber = parsePhoneNumberFromString(value);
    //   return phoneNumber ? phoneNumber.isValid() : false;
    // }),
    // .matches(/^[0]\d{9}/, "Pls enter a 10 digit number of the form 0712345678"),

  // Add regex for 10 digit phone number?
  customerAddress: yup
    .string("Enter the customer's address")
    .required("Customer address is required"),
  CBD: yup
    .string("Enter the CBD status")
    .required("CBD status is required")
    .oneOf(["true", "false"]),
  deliveryTime: yup
    .string("Enter the estimated delivery time")
    .notOneOf(["No route found"], "Pls select a valid route")
    .required("Delivery time is required"),
  deliveryDistance: yup
    .string("Enter the delivery distance")
    .notOneOf(["No route found"], "Pls select a valid route")
    .required("The delivery distance is required"),
  riderName: yup
    .string("Enter the rider name")
    .required("Rider name is required"),
});

export default validationSchema;
