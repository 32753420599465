
// Switch to 8.10 version
import firebase from 'firebase/app';
import 'firebase/messaging';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const {
  REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_APP_ID,
  REACT_APP_MEASUREMENT_ID,
  REACT_APP_VAPID_KEY,
} = process.env;
// console.log(REACT_APP_API_KEY);
const VAPID_KEY = REACT_APP_VAPID_KEY;

const firebaseConfig = {
  apiKey: REACT_APP_API_KEY,

  authDomain: REACT_APP_AUTH_DOMAIN,

  projectId: REACT_APP_PROJECT_ID,

  storageBucket: REACT_APP_STORAGE_BUCKET,

  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,

  appId: REACT_APP_APP_ID,

  measurementId: REACT_APP_MEASUREMENT_ID



};

// Initialize Firebase

firebase.initializeApp(firebaseConfig);

// Initialize messaging

let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();

}


export const pataToken = (setTokenFound, setSavedToken, showNotification) => {
  if (firebase.messaging.isSupported()) {
    return messaging.getToken({vapidKey: `${VAPID_KEY}`}).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setTokenFound(true);
        setSavedToken(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  } else {
    console.log("Browser is not supported");
    setTokenFound(true);
    setSavedToken("Browser is not supported");
    showNotification("info", "You will not get notifications on this browser; pls switch to Chrome or Firefox")

  }
};


  export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      console.log('Message received. ', payload);
      resolve(payload);
    });
});