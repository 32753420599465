import { makeStyles } from "@material-ui/styles";
import * as colors from "../constants/colors";


const useStyles = makeStyles({
  rootContainer: {
    padding: 0,
    backgroundColor: "#EFEFEF"
  },

  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: 0,
    padding: 0,
    backgroundColor: "#E5E5E5",
  },
  toolbar: {
    backgroundColor: colors.white,
    gap: 30,
  },
  appBar: {
    backgroundColor: colors.white,
    color: colors.black_10,
  },
  logoAppbar: {
    //   maxHeight: "80%",
    // scale: 0.5,
    transform: "scale(0.5, 0.5)",
    // transform: scale(0.5, 0.5);
  },
  headerText: {
    order: 0,
    marginTop: "3vh",
    // marginBottom: "2vh",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    order: 1,
    flex: 1,
    maxHeight: "70vh",
  },
  formikForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flexBasis: "100%",
    minWidth: "100%",
    flex: 1,
  },
  formikWrapper: {
    display: "flex",
    flexBasis: "100%",
    minHeight: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    padding: 0,
  },
  inputFields: {
    padding: 0,
    flexBasis: "100%",
    margin: 0,
    display: "flex",
    minWidth: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    // flexWrap: "wrap" ,
    order: 0,
    flex: 1,
  },
  textFieldWrapper: {
    padding: 0,
    flexBasis: "100%",
    margin: 0,
    display: "flex",
    minWidth: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    order: 0,
    marginTop: "3vh",
    // flex: 1,
  },
  textLabel: {
    order: 0,
    margin: 0,
    color: colors.blackLight,
  },
  phoneNumberEntryField: {
    color: colors.black_10,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    order: 1,
    marginTop: '0.5em',
    marginBottom: '0.5em',
  },
  nameEntryField: {
    color: colors.black_10,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    order: 1,
    backgroundColor: colors.white,
    marginTop: '0.5em',
    marginBottom: '0.5em',
    // "& .MuiFormControl": {
    //   marginTop: '0.5em',
    //   marginBottom: '0.5em'
    // }
  },
  submitButton: {
    order: 1,
    marginTop: "auto",
    flexGrow: 0,
    color: colors.white,
    textTransform: "none",
    borderRadius: 18,
    backgroundColor: colors.blue_500,
  },
  bottomTextWrapper: {
    backgroundColor: "inherit",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    flex: 1,
    marginTop: "auto",
  },
  bottomText: {
    color: colors.black_10,
    flex: "none",
    order: 0,
    flexGrow: 0,
  },
  bottomSignIn: {
    color: colors.red_300,
    flex: "none",
    order: 1,
    flexGrow: 0,
  },
  checkBoxContainer: {},

  intro: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "3vh"
  },

  topIntro: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  requiredText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: colors.systemRed,
    marginTop: "1.5vh"
  },
  buttonContainer: {
    order: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "8vh",
    width: "70%",
  },
  txtInput: {
    backgroundColor: colors.white,
  },
  sigInButton: {
    borderRadius: 40,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",

  },
  copyRightText: {
    marginTop: "8vh",
    marginBottom: "3vh",
    color: colors.blackLighter
  },
  helperText: {
    margin: 0,
    backgroundColor: colors.gray,
    // marginBottom: '0.5em'
  }
});

export default useStyles;
