import { makeStyles } from "@material-ui/core";
import { antiqueWhite, white } from "../../constants/colors";
import * as colors from "../../constants/colors";

const itemStyles = {
  normal: {
    backgroundColor: white,
  },
  new: {
    backgroundColor: antiqueWhite,
  },
};
const useStyles = makeStyles({
  notificationMenu: {
    "& .MuiList-padding" : {
      paddingTop: 0,
      paddingBottom: 0
    },
  },
  menuItemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexGrow: 1,
    justifyContent: "center"

  },
  menuItemTextContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 0,
    margin: 0,
    
  },
  menuItemBodyContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    margin: 0,
    flexWrap: "wrap",
    // overflow: "auto", 
    // textOverflow: "ellipsis"
  },
  menuItemDurationContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    flex: "none",
    order: 1,
    flexGrow: 0,
    alignSelf: "center",
    marginLeft: "auto",
    marginRight: 0
  },
  notificationText: {
    color: colors.black_10,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: 14,
    // lineHeight: 20,
  },
  notificationDurationText: {
    color: colors.blackLightest,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: 12,
  },
})
export { itemStyles, useStyles };