import { Container, Menu, MenuItem, Typography } from "@material-ui/core";
import moment from "moment";
import { red_300 } from "../../constants/colors";
import { useStyles, itemStyles } from "./styles";

const NotificationMenu = ({
  anchorEl,
  open,
  handleClose,
  notifications,
  haveNewNotification,
  setHaveNewNotification,
  setManualReassignOpen,
  handleClickReassignOrder,
}) => {
  const classes = useStyles();
  return (
    <Menu
      className={classes.notificationMenu}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{
        marginTop: 64,
      }}
      open={open}
      onClose={() => {
        setHaveNewNotification(false);
        handleClose();
      }}
    >
      {notifications.length >= 1 ? (
        notifications.map((notification) => {
          let now = moment(new Date());
          let isNew = notification.isNew;
          return (
            <MenuItem
              style={{
                whiteSpace: "normal",
                margin: 0,
                ...(isNew && haveNewNotification
                  ? itemStyles.new
                  : itemStyles.normal),
              }}
              onClick={
                // setManualReassignOpen(true)
                () => {
                  // console.log("notification is",notification)
                  if (notification.data) {
                    handleClickReassignOrder(notification.data, notification);
                  } else {
                    handleClose();
                  }
                }
              }
            >
              <Container
                maxWidth="lg"
                className={classes.menuItemContainer}
                disableGutters={true}
                style={{
                  width: "auto",
                  minWidth: 476,
                  minHeight: "7vh",
                }}
              >
                <Container
                  maxWidth="lg"
                  className={classes.menuItemTextContainer}
                  disableGutters={true}
                  style={{
                    width: "100%",
                  }}
                >
                  <Container
                    maxWidth="md"
                    className={classes.menuItemBodyContainer}
                    disableGutters={true}
                    style={{
                      // width: "auto",
                      maxWidth: "60%",
                      width: "20em",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {notification.isNew && (
                      <span
                        style={{
                          height: 7,
                          width: 7,
                          backgroundColor: red_300,
                          borderRadius: "50%",
                          display: "flow",
                          marginRight: 4,
                        }}
                      />
                    )}
                    <Typography
                      style={{
                        wordWrap: "break-word",
                        width: "90%",
                      }}
                      display="inline"
                      component="p"
                      variant="body1"
                      className={classes.notificationText}
                      noWrap={false}
                    >
                      {notification.body}
                    </Typography>
                  </Container>
                  <Container
                    maxWidth="md"
                    className={classes.menuItemDurationContainer}
                    disableGutters={true}
                    style={{
                      width: "auto",
                      height: "100%",
                    }}
                  >
                    <Typography
                      component="p"
                      variant="subtitle2"
                      className={classes.notificationDurationText}
                    >
                      {moment
                        .duration(-now.diff(notification.addedAt))
                        .humanize(true)}
                    </Typography>
                  </Container>
                </Container>
              </Container>
            </MenuItem>
          );
        })
      ) : (
        <MenuItem
          style={{
            whiteSpace: "normal",
            margin: 0,
          }}
          onClick={() => {
            setManualReassignOpen(true);
            // handleClose()
          }}
        >
          <Container
            maxWidth="lg"
            className={classes.menuItemContainer}
            disableGutters={true}
            style={{
              width: "auto",
              minWidth: 476,
              minHeight: "7vh",
            }}
          >
            <Container
              maxWidth="lg"
              className={classes.menuItemTextContainer}
              disableGutters={true}
              style={{
                width: "100%",
              }}
            >
              <Container
                maxWidth="md"
                className={classes.menuItemBodyContainer}
                disableGutters={true}
                style={{
                  // width: "auto",
                  maxWidth: "60%",
                  width: "20em",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography
                  style={{
                    wordWrap: "break-word",
                    width: "90%",
                  }}
                  display="inline"
                  component="p"
                  variant="body1"
                  className={classes.notificationText}
                  noWrap={false}
                >
                  No new notifications
                </Typography>
              </Container>
              <Container
                maxWidth="md"
                className={classes.menuItemDurationContainer}
                disableGutters={true}
                style={{
                  width: "auto",
                  height: "100%",
                }}
              >
                <Typography
                  component="p"
                  variant="subtitle2"
                  className={classes.notificationDurationText}
                >
                  Now
                </Typography>
              </Container>
            </Container>
          </Container>
        </MenuItem>
      )}
    </Menu>
  );
};

export default NotificationMenu;
