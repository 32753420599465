import React, { useCallback, useEffect, useRef, useState } from "react";
import theme from "../theme";
import { ThemeProvider } from "@material-ui/styles";
import useStyles from "./styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Badge,
  Button,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import TransitionUp from "../components/TransitionUp";
import CloseIcon from "@material-ui/icons/Close";
import { Link, useHistory } from "react-router-dom";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationMenu from "../components/NotificationMenu";
import Reassign from "../components/Reassign";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import ProfileMenu from "../components/ProfileMenu";
import DropDown from "../components/DropDown";
import login from "../utils/login";
import Pickup from "../Pickup";
import Alert from "@material-ui/lab/Alert";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import * as colors from "../constants/colors";
import {
  validationSchema,
  individualOrderValidationSchema,
  singleOrderValidationSchema,
} from "./validation";
import { useFormik, useFormikContext } from "formik";
import IOSSwitch from "../components/IOSSwitch";
import { KeyboardTimePicker } from "@material-ui/pickers";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { orderSources } from "../constants/sources";
import apiService from "../api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import throttle from "lodash/throttle";
import parse from "autosuggest-highlight/parse";
import StyledTableCell from "../components/TableCell";
import { io } from "socket.io-client";
import ModeOfTransport from "../components/ModeOfTransport";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { MapWithWayPoints } from "../components/MapWithWayPoints";
import { onMessageListener } from "../firebase";
import { MapWithADirectionsRenderer } from "../withDirectionsRenderer";
import duplicateOrderReasons from "./duplicateReasons";
// ---------------------AutoComplete Location section-------------------
function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

function calculateDistance(lat1, lng1, lat2, lng2) {
  // Radius of the Earth in kilometers
  const R = 6371;

  // Convert latitude and longitude from degrees to radians
  const lat1Rad = lat1 * (Math.PI / 180);
  const lng1Rad = lng1 * (Math.PI / 180);
  const lat2Rad = lat2 * (Math.PI / 180);
  const lng2Rad = lng2 * (Math.PI / 180);

  // Differences in coordinates
  const dLat = lat2Rad - lat1Rad;
  const dLng = lng2Rad - lng1Rad;

  // Haversine formula
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1Rad) * Math.cos(lat2Rad) *
            Math.sin(dLng / 2) * Math.sin(dLng / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in kilometers
  return R * c;
}

const center = { lat: -1.26606, lng: 36.80394 }; // This is set to Purvi House
// Create a bounding box with sides ~10km away from the center point
const defaultBounds = {
  north: center.lat + 0.5,
  south: center.lat - 0.5,
  east: center.lng + 0.5,
  west: center.lng - 0.5,
};

const autocompleteService = { current: null };
const geocoder = { current: null };
function BatchedOrder() {
  const history = useHistory();

  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [haveNewNotification, setHaveNewNotification] = useState(false);
  const [showNewRider, setShowNewRider] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = React.useState(null);
  const profileMenuOpen = Boolean(profileMenuAnchorEl);
  const [notifications, setNotifications] = useState([]);
  const [dropDownAnchorEl, setDropDownAnchorEl] = useState(null);
  const isDropDownOpen = Boolean(dropDownAnchorEl);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [severity, setSeverity] = useState("error");
  const [kitchenUserDetails, setKitchenUserDetails] = useState({});
  const [showPickupForm, setShowPickupForm] = useState(false);
  const [batchedOrder, setBatchedOrder] = useState("single");
  const [expanded, setExpanded] = React.useState(false);
  const [firstOrder, setFirstOrder] = useState(0);
  const [brands, setBrands] = useState([]);
  const [rows, setRows] = useState([]);
  const [riderNames, setRiderNames] = useState([]);
  const [socket, setSocket] = useState(null);
  const [enableRiderName, setEnableRiderName] = useState(false);
  const [kitchenOrigin, setKitchenOrigin] = useState(
    login.getLocalKitchenDetails()
  );
  const [kitchenDetails, setKitchenDetails] = useState({});
  const [destinationPlaceId, setDestinationPlaceId] = useState("");
  const [wayPoints, setWaypoints] = useState([]);
  const [destination, setDestination] = useState("");
  const [origin, setOrigin] = useState(null);
  const [mapWaypoints, setMapWaypoints] = useState([]);
  const [route, setRoute] = useState(null);
  const [form1formikContext, setForm1FormikContext] = useState(null);
  const [form2formikContext, setForm2FormikContext] = useState(null);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [singleOrderContext, setSingleOrderContext] = useState(null);


  const formik = useFormik({
    initialValues: {
      "riderName": null,
      "riderId": "",

    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // console.log(values);
      // Validate the form values in form2formikContext and form1formikContext using individualOrderValidationSchema.isValid
      // If there are errors, show the errors in the form
      // If there are no errors, submit the form
      let isForm1Valid = await individualOrderValidationSchema.isValid(form1formikContext.values);
      let isForm2Valid = await individualOrderValidationSchema.isValid(form2formikContext.values);
      if (isForm1Valid && isForm2Valid) {
        let radius = 0;
        if (firstOrder === 1) {
          radius = calculateDistance(kitchenOrigin.lat, kitchenOrigin.lng, form1formikContext?.values?.customerLat, form1formikContext?.values?.customerLng) + calculateDistance(form1formikContext?.values?.customerLat, form1formikContext?.values?.customerLng, form2formikContext?.values?.customerLat, form2formikContext?.values?.customerLng)
        }
        else {
          radius = calculateDistance(kitchenOrigin.lat, kitchenOrigin.lng, form2formikContext?.values?.customerLat, form2formikContext?.values?.customerLng) + calculateDistance(form2formikContext?.values?.customerLat, form2formikContext?.values?.customerLng, form1formikContext?.values?.customerLat, form1formikContext?.values?.customerLng)
        }
        console.log("radius", radius);
        const riderId = parseInt(values.riderId, 10);
        const foundRow = rows.find(row => row.id === riderId);
        const modeOfTransportation = foundRow ? foundRow.modeOfTransportation : 'Not Found';
        const isPedalBikeRider = modeOfTransportation === 'PEDAL BIKE' && radius > 7.0;
        if(isPedalBikeRider){
          alert("A Pedal Bike Rider can't be assigned due to the radius being greater than 7km.")
          return;
        }

        // Submit the form
        // Add the values from form1formikContext and form2formikContext to an orders array
        // Add the array to a submission object of the form
        /* 
        type Submission = {
          "riderId": "string",
          "brandId": "string",
          "kitchenId": "string",
          "riderName": "string",
          "orders": [
            {
              "orderId": "string",
              "customerNumber": "string",
              "orderAcceptedTime": "string",
              "orderSource": "UBER",
              "orderAmount": 0,
              "paymentStatus": "PAID",
              "customerName": "string",
              "customerAddress": "string",
              "customerLat": "string",
              "customerLng": "string",
              "deliveryTime": 0,
              "deliveryDistance": 0,
              "CBD": "string"
            }
          ]
        }
        */
        let orders = [];
        orders.push(form1formikContext.values);
        orders.push(form2formikContext.values);
        let submission = {
          "riderId": values.riderId,
          "brandId": kitchenDetails.brandId,
          "kitchenId": login.getKitchenId(),
          "riderName": values.riderName,
          "orders": orders
        }
        // Check if the order source is Pickups then remove the order amount and set the payment status to paid. Use array.map to do this
        submission.orders = submission.orders.map((order) => {
          if (order.orderSource === orderSources.Pickups.value || order.orderSource === orderSources.Staff_DropOff.value) {
            order.orderAmount = 0;
            order.paymentStatus = "PAID";
          }
          return order;
        });
        console.log("Submission",submission);
        // Submit the submission object to the backend
        // If the submission is successful, show a success message
        // If the submission is not successful, show an error message
        let submissionResponse = await apiService.batchSubmit(submission);
        console.log(submissionResponse);
      if (submissionResponse.status === 200) {
        let body = await submissionResponse.json();
        console.log("Success, data is:", body);
        setSeverity("success");
        setError("Successfully Dispatched!");
        setShowError(true);
        window.location.reload({ forcedReload: true });
      } else {
        let body = await submissionResponse.json();
        console.log("Failed, data is:", body);
        formik.setSubmitting(false);
        setError("Something went wrong");
        setShowError(true);
      }
      } else {
        // Show the errors
      }
    },
    validateOnBlur: true,
  });

  function resetForm() {
    formik.resetForm();
    form1formikContext.resetForm();
    form2formikContext.resetForm();
    setForm1FormikContext(null);
    setForm2FormikContext(null);
    setEnableSubmit(false);
    setEnableRiderName(false);
    setDestinationPlaceId("");
    setWaypoints([]);
    setDestination("");
    // setOrigin(null);
    setMapWaypoints([]);
    setRoute(null);
    setSingleOrderContext(null);
  }
  // console.log("form 1 context", form1formikContext);
  // console.log("form 2 context", form2formikContext);

  // If form1 and form 2 isValid is true then enable the submit button
  useEffect(() => {
    if (form1formikContext && form2formikContext) {
      if (form1formikContext.isValid && form2formikContext.isValid && formik.values.riderName !== null) {
        setEnableSubmit(true);
      } else {
        setEnableSubmit(false);
      }
    }
    // console.log("enable submit", enableSubmit);
  }, [enableSubmit, form1formikContext, form2formikContext, formik.isValid, formik.values.riderName]);

  const handleDestinationPlaceId = (value) => {
    if (value) {
      setDestinationPlaceId(value.place_id);
    } else {
      // Do nothing
      // console.log("PlaceId is", value);
    }
  };
  function handleLocationUpdate  ({ geocodedResult, orderNumber, hooks }) {
    /* 
      type waypointObject = {
        location: {
          lat: number,
          lng: number
        },
        stopover: boolean,
        orderNumber: number
        placeId: string,
        hooks: {
          showComputedDistance: function,
          showComputedTime: function
        }
      }
    */
    // Whehn this function is called we add the location to the wayPoints array, if the location is already in the array we update the entry
    const index = wayPoints.findIndex(
      (wayPoint) => wayPoint.orderNumber === orderNumber
    );
    let newWayPoints = [];
    if (index !== -1) {
      newWayPoints = [...wayPoints];

      newWayPoints[index] = {
        location: geocodedResult[0].geometry.location.toJSON(),
        stopover: true,
        placeId: geocodedResult[0].place_id,
        orderNumber,
        hooks,
      };
    } else {
      // console.log("geocodedResult", geocodedResult);
      newWayPoints = [ ...wayPoints, {
        location: geocodedResult[0].geometry.location.toJSON(),
        stopover: true,
        placeId: geocodedResult[0].place_id,
        orderNumber,
        hooks,
      },];
      setWaypoints([
        ...wayPoints,
        {
          location: geocodedResult[0].geometry.location.toJSON(),
          stopover: true,
          placeId: geocodedResult[0].place_id,
          orderNumber,
          hooks,
        },
      ]);
    }
    // Now pass the result to the determineWaypointsAndDestination function to get the waypoints and destination
    let result = determineWaypointsAndDestination(newWayPoints);
    // console.log("Result is", result);
    setMapWaypoints(result.waypoints);
    setWaypoints(newWayPoints);
    setDestination(result.destination);
  };

  const determineWaypointsAndDestination = useCallback((detWayPoints) => {
    /* 
      type waypointObject = {
        location: {
          lat: number,
          lng: number
        },
        stopover: boolean,
      }
      type wayPointsArray = Array<waypointObject>
    */

    // If the wayPoints array is empty, we return an empty array
    // console.log("Waypoints are", detWayPoints);
    if (detWayPoints.length === 0) {
      return { waypoints: [], destination: null };
    }
    // If the wayPoints array has only one element, we return an empty waypoints array and the destination field as the first elements placeId
    if (detWayPoints.length === 1) {
      return { waypoints: [], destination: {placeId: detWayPoints[0].placeId} };
    }
    // If the wayPoints array has 2 elements, we return the waypoints array as the waypoint whose orderNumber is the firstOrder and the destination as the other waypoint's placeId
    if (detWayPoints.length === 2) {
      const firstWayPoint = detWayPoints.find(
        (wayPoint) => wayPoint.orderNumber === firstOrder
      );
      // console.log("First waypoint is", firstWayPoint);
      // If the firstwaypoint is not found, show an error and return an empty array
      if (firstWayPoint === undefined || firstWayPoint === null) {
        console.error("First waypoint not found");
        showNotification("error", "First waypoint not found, pls mark the first order");
        return { waypoints: [], destination: null };
      } 
      const secondWayPoint = detWayPoints.find(
        (wayPoint) => wayPoint.orderNumber !== firstOrder
      );
      return {
        waypoints: [{ location: firstWayPoint.location, stopover: true }],
        destination: {placeId: secondWayPoint.placeId},
      };
    }
    // If the wayPoints array has more than 2 elements, we return the waypoints array with the waypoints whose orderNumber is the firstOrder as the first element and the other waypoints as the rest of the array and the destination as the last waypoint's placeId
    if (detWayPoints.length > 2) {
      const firstWayPoint = detWayPoints.find(
        (wayPoint) => wayPoint.orderNumber === firstOrder
      );
      const secondWayPoint = detWayPoints.find(
        (wayPoint) => wayPoint.orderNumber !== firstOrder
      );
      const otherWayPoints = detWayPoints
        .filter(
          (wayPoint) =>
            wayPoint.orderNumber !== firstOrder &&
            wayPoint.orderNumber !== secondWayPoint.orderNumber
        )
        .map((wayPoint) => ({ location: wayPoint.location, stopover: true }));
      return {
        waypoints: [
          { location: firstWayPoint.location, stopover: true },
          ...otherWayPoints,
        ],
        destination: secondWayPoint.placeId,
      };
    }
  }, [firstOrder]);

  const handleSingleOrderLocationUpdate = ({ geocodedResult, orderNumber, hooks }) => {
    // For the single order form there is no wayPoint but we still need to update the destination
    setMapWaypoints([]);
    // console.log("geocodedResult", geocodedResult);
    setDestination({placeId: geocodedResult[0].place_id});
    setWaypoints([]);
    setRoute(undefined);
    // setShowComputedDistance(hooks.showComputedDistance);
    // setShowComputedTime(hooks.showComputedTime);
    // setShowCom
  };    

  const handleRoute = (route) => {
    setRoute(route);
  };

  // When the route changes, we update the distance and time for each waypoint
  useEffect(() => {
    if (route) {
      // console.log("Route is", route);
      const legs = route.legs;
      // console.log("legs", legs);
      let totalDistance = 0;
      let totalTime = 0;
      legs.forEach((leg) => {
        totalDistance += leg.distance.value;
        totalTime += leg.duration.value;
      });
      // If there is only one waypoint, we update the distance and time for that waypoint
      if (wayPoints.length === 1) {
        const index = wayPoints.findIndex(
          (wayPoint) => wayPoint.orderNumber === firstOrder
        );
        // console.log("firstOrder", firstOrder);
        // console.log("index is", index);
        const newWayPoints = [...wayPoints];
        // console.log("NewWayPoints are", newWayPoints);
        newWayPoints[0].hooks.showComputedDistance(
          convertToKm(totalDistance)
        );
        newWayPoints[0].hooks.showComputedTime(convertToMinutes(totalTime));
        // setWaypoints(newWayPoints);
      }
      // If there are 2 waypoints we update the distance and time of the waypoint whose orderNumber is the firstOrder as the first leg's distance and time and the distance and time of the other waypoint as the second leg's distance and time
      if (wayPoints.length === 2) {
        const firstWayPoint = wayPoints.find(
          (wayPoint) => wayPoint.orderNumber === firstOrder
        );
        // If the firstwaypoint is not found, show an error and return an empty array
      if (firstWayPoint === undefined || firstWayPoint === null) {
        console.error("First waypoint not found");
        showNotification("error", "First waypoint not found, pls mark the first order");
        return;
      } else {
        setShowError(false);
      }
        const secondWayPoint = wayPoints.find(
          (wayPoint) => wayPoint.orderNumber !== firstOrder
        );
        // console.log("firstWayPoint", firstWayPoint);
        // console.log("secondWayPoint", secondWayPoint);
        firstWayPoint.hooks.showComputedDistance(
          convertToKm(legs[0].distance.value)
        );
        firstWayPoint.hooks.showComputedTime(
          convertToMinutes(legs[0].duration.value)
        );
        secondWayPoint.hooks.showComputedDistance(
          convertToKm(totalDistance - legs[0].distance.value)
        );
        secondWayPoint.hooks.showComputedTime(
          convertToMinutes(totalTime)
        );
      }
      // If there are more than 2 waypoints, we update the distance and time of the waypoint whose orderNumber is the firstOrder as the first leg's distance and time and the distance and time of the other waypoints as the rest of the legs' distance and time
      if (wayPoints.length > 2) {
        const index = wayPoints.findIndex(
          (wayPoint) => wayPoint.orderNumber === firstOrder
        );
        const firstWayPoint = wayPoints.find(
          (wayPoint) => wayPoint.orderNumber === firstOrder
        );
        const otherWayPoints = wayPoints.filter(
          (wayPoint) => wayPoint.orderNumber !== firstOrder
        );
        firstWayPoint.hooks.showComputedDistance(
          convertToKm(legs[0].distance.value)
        );
        firstWayPoint.hooks.showComputedTime(
          convertToMinutes(legs[0].duration.value)
        );
        otherWayPoints.forEach((wayPoint, index) => {
          wayPoint.hooks.showComputedDistance(
            convertToKm(legs[index + 1].distance.value)
          );
          wayPoint.hooks.showComputedTime(
            convertToMinutes(legs[index + 1].duration.value)
          );
        });
      }
    }
  }, [route, wayPoints, firstOrder]);

  // When the firstOrder changes, we check if more than one waypoint exists we update the mapWaypoints and the destination with the result of the detemineWayPoints function
  useEffect(() => {
    if (wayPoints !== undefined && wayPoints !== null ) {
      if (wayPoints?.length > 1) {
      const { waypoints, destination } = determineWaypointsAndDestination(wayPoints);
      setMapWaypoints(waypoints);
      setDestination(destination);
      }
    }
  }, [determineWaypointsAndDestination, firstOrder, wayPoints]);

  function convertToKm(meters) {
    return meters / 1000;
  }

  function convertToMinutes(seconds) {
    return Math.round(seconds / 60);
  }

  const showComputedDistance = (value) => {
    if (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      !isNaN(value) &&
      value !== false
    ) {
      formik.setFieldValue("deliveryDistance", value);
      // setDeliveryDistance(value);
    } else if (value === "No route found") {
      formik.setFieldValue("deliveryDistance", "No route found");
      // formik.setErrors({deliveryDistance: "No route found"});
    } else {
      // console.log("GMaps Says distance is", value);
    }
  };

  const tumiaSnack = (message) => {
    setOpenSnack(true);
    setSnackMessage(message);
  };
  
  onMessageListener()
  .then((payload) => {
    // setShow(true);
    // setNotification({title: payload.notification.title, body: payload.notification.body})
    let currentNotifications = notifications;
    var now = moment(new Date());
    currentNotifications.forEach((value, index, array) => {
      value.isNew = false;
      let prevDuration = value["duration"];
      value["duration"] = moment
        .duration(-now.diff(prevDuration))
        .humanize(true);
    });
    currentNotifications.unshift({
      title: payload.notification.title,
      body: payload.notification.body,
      addedAt: moment(new Date()),
      isNew: true,
      duration: moment.duration(-now.diff(now)).humanize(true),
      data: payload.data ?  payload.data : null,
      reassigned: false,
    });
    setNotifications(currentNotifications);
    setHaveNewNotification(true);
    tumiaSnack("A rider declined your order request.");
    // console.log("Notification Payload", payload);
  })
  .catch((err) => console.log("failed: ", err));

  const showComputedTime = (value) => {
    if (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      !isNaN(value) &&
      value !== false
    ) {
      formik.setFieldValue("deliveryTime", value);
      // setDeliveryTime(value);
    } else if (value === "No route found") {
      formik.setFieldValue("deliveryTime", "No route found");
      // formik.setErrors({deliveryTime: "No route found"});
      formik.setFieldError("deliveryTime", "No route found");
    } else {
      // console.log("GMaps Says distance is", value);
    }
  };


  //  The socket implementation has 3 useEffect functions:
  // 1. The initialization useEffect to start the socket connection and instance
  // 2. The main useEffect function with the event handlers
  // 3. The queue update useEffect to emit the event to request the queue. Should this be in the main useEffect?
  // is there any value in having its own function?
  // A. Initialization useEffect
  useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_BASE_URL}`, {
      withCredentials: true,
      extraHeaders: {
        authorization: login.getToken(),
      },
      auth: {
        token: login.getToken()
      },
      transports: ["websocket"],
    });

    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket]);
  // B. A useEffect function for the socket events with the handlers in the useEffect function as well
  // The useEffect should handle:
  // 1. Queue updates
  // 2. Notifications
  useEffect(() => {
    // const queueUpdateHandler = (queueArray) => {
    //   console.info("Socket queue", queueArray);
    // };
    const queueUpdateHandler = async (queueArray) => {
      console.info("Socket queue", queueArray);
      let getRidersConfig = {
        checkedIn: false,
        online: true,
        offset: 0,
        limit: 100,
        useKitchenId: false,
      };
      // let queueRiders = await apiService.getQueue();
      let queueRiders = queueArray;
      let currentRows = [];
      let currentRidersInQueue = [];

      currentRows = queueRiders.map((rider, idx) =>
        formatQueueRiders(
          rider.name,
          rider.createdAt,
          rider.modeOfTransport,
          idx + 1,
          rider.riderId
        )
      );
      currentRidersInQueue = queueRiders.map((rider) => {
        return {
          label: rider.name,
          riderId: rider.riderId,
        };
      });
      /*             // Changing the riders based on Staff Drop off or Pickups
            if (orderSourceRef.current === orderSources.Staff_DropOff.value || orderSourceRef.current === orderSources.Pickups.value) {
              // Get the online riders and the riders in the queue 
              let onlineRiders = await getOnlineRiders(getRidersConfig);
              currentRidersInQueue = currentRidersInQueue.concat(onlineRiders);
            }  */
      setRows(currentRows);
      setRiderNames(currentRidersInQueue);
    };
    const pollQueue = () => {
      if (socket && socket.connected) {
        socket.emit(`/queue`, { id: login.getKitchenId() });
      }
    };

    if (socket) {
      socket.on(`/kitchen/${login.getKitchenId()}/queue`, queueUpdateHandler);
      socket.emit(`/queue`, { id: login.getKitchenId() });
      // Poll the queue every 30 seconds
      // Set up polling every 30 seconds
      const pollInterval = setInterval(pollQueue, 5000);
      return () => {
        if (socket) {
          socket.off(
            `/kitchen/${login.getKitchenId()}/queue`,
            queueUpdateHandler
          );
          clearInterval(pollInterval);
  
        }
      };
    }
  }, [socket, formik.values.orderSource]);

  // Get brands
  useEffect(() => {
    let fetchBrands;
    async function getKitchenBrands() {
      fetchBrands = await apiService.getBrands();
      fetchBrands = await fetchBrands.json();
      // await sleep(100e3); // For demo purposes.
      setBrands(fetchBrands);
    }
    getKitchenBrands();
  }, []);

  useEffect(() => {
    let kitchenDetails = apiService.getKitchenDetails();
    let kitchenUserDetails = apiService.getKitchenUserDetails();
    async function getKitchenDetails() {
      kitchenDetails = await kitchenDetails;
      kitchenUserDetails = await kitchenUserDetails;
      kitchenUserDetails = await kitchenUserDetails.json();
      kitchenDetails = await kitchenDetails.json();
      // console.log("Details are", kitchenDetails);
      setKitchenDetails(kitchenDetails);
      setKitchenOrigin({
        lat: kitchenDetails.lat,
        lng: kitchenDetails.lng,
      });
      login.setKitchenDetails(kitchenDetails);
      setKitchenUserDetails(kitchenUserDetails);
      // console.log(kitchenUserDetails);
    }
    getKitchenDetails();
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // const handleOrderNumber = (panel) =>  (event, orderNumber) => {
  //   console.log("Order Number is", orderNumber);
  //   console.log(event.target.value)
  //   setFirstOrder(orderNumber);
  // }
  function handleOrderNumber(orderNumber) {
    setFirstOrder((initial) => {
      return initial === orderNumber ? 0 : orderNumber;
    });
  }
  const handleBatchedOrderChange = (event) => {
    setBatchedOrder(event.target.value);
  };

  const isBatchedOrder = batchedOrder === "batched";

  const signOut = async () => {
    let sendSignOutRequest = await login.logOut();
    if (sendSignOutRequest) {
      history.push("/");
      // window.location.reload({ forcedReload: true });
    } else {
      setError("Something went wrong signing out");
      setShowError(true);
    }
  };

  const profileMenuId = "profileMenu";
  const handleDropDownMenuOpen = (event) => {
    setDropDownAnchorEl(event.currentTarget);
    // setIsDropDownOpen()
  };
  const handleDropDownClose = (event) => {
    setDropDownAnchorEl(null);
  };
  // Manage the manual reassignment state
  const [manualReassignOpen, setManualReassignOpen] = useState(false);
  const [reassignedRiderValue, setReassignedRiderValue] = useState("");
  const [reassignOrderId, setReassignOrderId] = useState('');
  const [clickedNotification, setClickedNotification] = useState({})

  const showNotification = (severity, text) => {
    setSeverity(severity);
    setShowError(true);
    setError(text);
  };
  const handleProfileMenuOpen = (event) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = (event) => {
    setProfileMenuAnchorEl(null);
  };
  const handleClickReassignOrder = (data, notification) => {
    // Add logic for the manual assignment and successful assignment
    let {dispatchId} = data;
    let {reassigned} = notification;
    console.log("Reassigning order", dispatchId);
    console.log("Reassigning order notification", notification);
    if (reassigned) {
      // Do nothing? Set reassign success?
    } else {
      setReassignOrderId(dispatchId);
      setClickedNotification(notification);
      setManualReassignOpen(true);
    }
  };

  const handleReassignDialogClose = (newValue) => {
    setManualReassignOpen(false);

    if (newValue) {
      setReassignedRiderValue(newValue);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const classes = useStyles();
  return (
    // <div>hello</div>
    <ThemeProvider theme={theme}>
      <Snackbar
        className={classes.snackBar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={openSnack}
        autoHideDuration={15000}
        onClose={handleCloseSnack}
        message={snackMessage}
        TransitionComponent={TransitionUp}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnack}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Container maxWidth="xl" className={classes.rootContainer} disableGutters>
        <AppBar className={classes.appBar} position="static">
          <Toolbar className={classes.toolbar}>
            <Link href="/">
              <img
                className={classes.logoAppbar}
                src="/ando-logo.svg"
                alt="ando logo"
              />
            </Link>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton
                aria-label="show new notifications"
                className={classes.notificationIcon}
                sx={{ mr: "1vw" }}
                onClick={handleMenu}
              >
                <Badge
                  invisible={!haveNewNotification}
                  variant="dot"
                  overlap="circular"
                  color={showNewRider ? "error" : "default"}
                  className={classes.badge}
                >
                  <NotificationsIcon className={classes.notificationIcon} />
                </Badge>
              </IconButton>
              {
                <NotificationMenu
                  anchorEl={anchorEl}
                  open={open}
                  handleClose={handleClose}
                  notifications={notifications}
                  haveNewNotification={haveNewNotification}
                  setHaveNewNotification={setHaveNewNotification}
                  setManualReassignOpen={setManualReassignOpen}
                  handleClickReassignOrder={handleClickReassignOrder}
                />
              }
              <Reassign
                classes={{
                  paper: classes.paper,
                }}
                id="ringtone-menu"
                keepMounted
                open={manualReassignOpen}
                onClose={handleReassignDialogClose}
                valueProp={reassignedRiderValue}
                dispatchId={reassignOrderId}
                setShowError={setShowError}
                setError={setError}
                setSeverity={setSeverity}
                notificationObject={clickedNotification}
                notificationsArray={notifications}
                setNotificationsArray={setNotifications}
              />
              <IconButton
                sx={{ mr: "1vw" }}
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                onClose={handleProfileMenuClose}
                className={classes.notificationIcon}
              >
                <AccountCircle className={classes.notificationIcon} />
              </IconButton>
              <IconButton
                sx={{ mr: "1vw" }}
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={handleDropDownMenuOpen}
                onClose={handleDropDownClose}
                className={classes.notificationIcon}
              >
                <MenuIcon className={classes.notificationIcon} />
              </IconButton>
              {
                <ProfileMenu
                  profileMenuAnchorEl={profileMenuAnchorEl}
                  profileMenuId={profileMenuId}
                  handleProfileMenuClose={handleProfileMenuClose}
                  profileMenuOpen={profileMenuOpen}
                  signOut={signOut}
                  kitchenUserDetails={kitchenUserDetails}
                />
              }
              {
                <DropDown
                  dropDownAnchorEl={dropDownAnchorEl}
                  isDropDownOpen={isDropDownOpen}
                  handleDropDownClose={handleDropDownClose}
                  setShowPickupFor={setShowPickupForm}
                />
              }
            </div>
          </Toolbar>
        </AppBar>
        {showError && (
          <Collapse in={showError}>
            <Alert
              severity={severity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {error}
            </Alert>
          </Collapse>
        )}
        {showPickupForm ? (
          <Pickup showNotification={showNotification} />
        ) : (
          <Container className={classes.contentContainer} maxWidth="lg">
            <Container className={classes.headerContainer}>
              <Typography
                // m={1}
                // display="block"
                sx={{ mt: "3vh" }}
                component="h1"
                variant="h4"
                className={classes.headingText}
              >
                Dispatch Ops
              </Typography>
              <Typography
                // m={1}
                // display="block"
                sx={{ mt: "1.5vh" }}
                component="p"
                variant="body1"
                className={classes.requiredText}
              >
                *Required Field
              </Typography>
            </Container>
            {/* Batched Order or Single Order Radio */}
            <Container className={classes.headerContainer}>
              <FormControl
                component="fieldset"
                className={classes.formControl}
                sx={{ mt: "1vh" }}
                fullWidth={true}
              >
                <RadioGroup
                  row
                  aria-label="batchedOrder"
                  name="batchedOrder"
                  value={batchedOrder}
                  onChange={handleBatchedOrderChange}
                >
                  <FormControlLabel
                    value="single"
                    control={<Radio color="secondary" />}
                    label="Single Order"
                  />
                  <FormControlLabel
                    value="batched"
                    control={<Radio color="secondary" />}
                    label="Batch Order"
                  />
                </RadioGroup>
              </FormControl>
            </Container>
            {isBatchedOrder ? (
              /* This renders 2 accordion panels each with the dispatch form fields */
              <>
                <Container className={classes.headerContainer}>
                  <Accordion
                    expanded={expanded === "order1"}
                    onChange={handleChange("order1")}
                    // fullWidth={true}
                    className={classes.accordion}
                    square={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownIcon
                          className={classes.arrowDropDownIcon}
                        />
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      className={classes.accordionSummary}
                    >
                      <Typography className={classes.heading}>
                        Order 1
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <BatchedOrderView
                        brands={brands}
                        setBrands={setBrands}
                        enableRiderName={enableRiderName}
                        setEnableRiderName={setEnableRiderName}
                        orderNumber={1}
                        firstOrder={firstOrder}
                        handleOrderNumber={handleOrderNumber}
                        handleDestinationPlaceId={handleDestinationPlaceId}
                        handleLocationUpdate={handleLocationUpdate}
                        formikContext={form1formikContext}
                        setFormikContext={setForm1FormikContext}
                      />
                      {/* <DispatchForm
                      orderNumber={1}
                      setOrder1={setOrder1} /> */}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "order2"}
                    onChange={handleChange("order2")}
                    className={classes.accordion}
                    square={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownIcon
                          className={classes.arrowDropDownIcon}
                        />
                      }
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                      className={classes.accordionSummary}
                    >
                      <Typography className={classes.heading}>
                        Order 2
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      className={classes.accordionDetails}
                      fullWidth={true}
                    >
                      <BatchedOrderView
                        brands={brands}
                        setBrands={setBrands}
                        enableRiderName={enableRiderName}
                        setEnableRiderName={setEnableRiderName}
                        orderNumber={2}
                        firstOrder={firstOrder}
                        handleOrderNumber={handleOrderNumber}
                        handleDestinationPlaceId={handleDestinationPlaceId}
                        handleLocationUpdate={handleLocationUpdate}
                        formikContext={form2formikContext}
                        setFormikContext={setForm2FormikContext}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Container>
                <Container
                  className={classes.textFieldWrapper}
                  disableGutters={true}
                  id="wrapperContainer"
                  maxWidth="md"
                >
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead className={classes.tableHeader}>
                        <TableRow>
                          <StyledTableCell className={classes.tableRow}>
                            Rider name
                          </StyledTableCell>
                          <StyledTableCell
                            className={classes.tableRow}
                            align="right"
                          >
                            Check-in time
                          </StyledTableCell>
                          <StyledTableCell
                            className={classes.tableRow}
                            align="right"
                          >
                            Mode of Transportation
                          </StyledTableCell>
                          <StyledTableCell
                            className={classes.tableRow}
                            align="right"
                          >
                            Status
                          </StyledTableCell>
                          <StyledTableCell
                            className={classes.tableRow}
                            align="center"
                          >
                            Action
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => {
                          return (
                            <TableRow key={row.name}>
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell align="right">
                                {row.checkInTime}
                              </TableCell>
                              <TableCell align="right">
                                {
                                  <ModeOfTransport
                                    mode={row.modeOfTransportation}
                                  />
                                }
                              </TableCell>
                              <TableCell align="right">{row.number}</TableCell>
                              <TableCell align="right">
                                <Button
                                  className={classes.selectRiderButton}
                                  variant="outlined"
                                  onClick={() => {
                                    setEnableRiderName(true);
                                    // console.log("enableRiderName", enableRiderName);
                                    formik.setFieldValue("riderName", row.name);
                                    // console.log("riderName", formik.values.riderName);
                                    formik.setFieldValue(
                                      "riderId",
                                      row.id.toString()
                                    );
                                    // console.log("riderId", formik.values.riderId);
                                  }}
                                >
                                  Select Rider
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Container>
                <Container
                  className={classes.textFieldWrapper}
                  disableGutters={true}
                  id="wrapperContainer"
                  maxWidth="sm"
                >
                  <Typography
                    className={`${classes.textLabel} required`}
                    variant="body2"
                    component="div"
                  >
                    Rider's Name
                  </Typography>
                  <Autocomplete
                    disabled={!enableRiderName}
                    freeSolo={true}
                    fullWidth={true}
                    size="large"
                    name="riderName"
                    id="riderName"
                    value={formik.values.riderName}
                    onChange={(event, newValue) => {
                      // console.log("New Value is:", newValue);
                      if (newValue && newValue.label) {
                        formik.setFieldValue("riderName", newValue.label);
                        formik.setFieldValue(
                          "riderId",
                          newValue.riderId.toString()
                        );
                      } else {
                        formik.setFieldValue("riderName", newValue);
                        // console.log("Rider name is:", newValue);
                      }
                      // formik.setFieldValue("riderName", newValue.label);
                    }}
                    options={riderNames}
                    getOptionLabel={(option) => {
                      if (
                        typeof option === "string" ||
                        typeof option === "number"
                      ) {
                        return option.toString();
                      } else {
                        return option.label;
                      }
                    }}
                    getOptionSelected={(option, value) =>
                      option.label === value.label
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={`${classes.autocompleteField} `}
                        required
                        // helperText="Enter the rider's name"
                        placeholder="Enter the rider's name"
                        error={
                          formik.touched.riderName &&
                          Boolean(formik.errors.riderName)
                        }
                        // helperText={
                        //   formik.touched.customerLocation ?
                        //   formik.errors.customerLocation :
                        //   "Type and select the location (DropDown)"
                        // }
                        helperText={
                          formik.touched.riderName && formik.errors.riderName
                        }
                        variant="filled"
                        size="small"
                      />
                    )}
                  />
                </Container>
                <Container
                  className={classes.mapViewWrapper}
                  disableGutters={true}
                  id="wrapperContainer"
                  maxWidth="sm"
                >
                  <Typography
                    className={`${classes.mapViewText}`}
                    sx={{ textAlign: "center" }}
                    variant="body2"
                    component="div"
                  >
                    Map View
                  </Typography>
                  {/* <MapView
                      pickupLocation={{lat:-1.26606, lng: 36.80394}}
                      dropLocation={{lat: -1.2609781530466984, long: 36.80204207090601}}
                      // deliveryDistance={showComputedDistance}
                      // deliveryTime={showComputedTime}
                    /> */}
                  <MapWithWayPoints
                    origin={kitchenOrigin}
                    dest={destination}
                    wayPoints={mapWaypoints}
                    routeHook={setRoute}
                    deliveryDistance={showComputedDistance}
                    deliveryTime={showComputedTime}
                  />
                </Container>
                <Container
                    className={classes.textFieldWrapper}
                    disableGutters={true}
                    sx={{ mb: "10vh" }}
                    id="wrapperContainer"
                    maxWidth="sm"
                  >
                    {(formik.isSubmitting === false) ? (
                      <>
                      <Button
                        // type="submit"
                        fullWidth={true}
                        // className={{classes.}}
                        className={classes.submitButton}
                        onClick={(event) => {
                          // setSubmiting(true);
                          formik.handleSubmit();
                          // resetForm()
                          // console.log(formik.errors);
                        }}
                        variant="contained"
                        size="large"
                        sx={{ mt: "3vh", mb: "5vh" }}
                        disabled={enableSubmit ? false : true}
                      >
                        Submit
                      </Button>
                      {!enableSubmit ? (
                        <Typography
                        className={classes. mapViewText}
                        variant="body2"
                        component="div"
                        sx={{ textAlign: "center" }}
                      >
                      {enableSubmit ? "Looking Good!" : "Pls check order fields"}
                      </Typography>
                      ): null}
                      </>
                    ) : (
                      <CircularProgress
                        alignContent="center"
                        alignItems="center"
                        sx={{ mt: 3, mb: 2 }}
                      />
                    )}
                  </Container>
              </>
            ) : (
              /* This renders a single dispatch form */
              <SingleOrderView
              brands={brands}
              setBrands={setBrands}
              enableRiderName={enableRiderName}
              setEnableRiderName={setEnableRiderName}
              orderNumber={1}
              firstOrder={firstOrder}
              handleOrderNumber={handleOrderNumber}
              handleDestinationPlaceId={handleDestinationPlaceId}
              handleLocationUpdate={handleSingleOrderLocationUpdate}
              formikContext={singleOrderContext}
              setFormikContext={setSingleOrderContext}
              rows={rows}
              riderNames={riderNames}
              kitchenOrigin={kitchenOrigin}
              kitchenId={login.getKitchenId()}
              tumiaSnack={tumiaSnack}
              />
            )}
          </Container>
        )}
      </Container>
    </ThemeProvider>
  );
}

function BatchedOrderView({
  brands,
  enableRiderName,
  setEnableRiderName,
  orderNumber,
  handleOrderNumber,
  firstOrder,
  handleDestinationPlaceId,
  handleLocationUpdate,
  formikContext,
  setFormikContext,
}) {
  const classes = useStyles();
  const [selectedOrderSource, setSelectedOrderSource] = useState(
    orderSources.Organic.value
  );
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("Paid");
  const [customerNameFieldText, setCustomerNameFieldText] =
    useState("Customer Name");
  const [customerNameFieldHelperText, setCustomerNameFieldHelperText] =
    useState("");
  // const [enableRiderName, setEnableRiderName] = useState(false);

  // const orderSourceRef = useRef(formik.values.orderSource);
  //   useEffect(() => {
  //     orderSourceRef.current = formik.values.orderSource;
  // });

  const formik = useFormik({
    initialValues: {
      orderId: undefined,
      orderAcceptedTime:  moment().format('YYYY-MM-DDTHH:mm:ss.sssZZ'),
      orderSource: orderSources.Organic.value,
      brandId: "1",
      paymentStatus: "Paid".toUpperCase(),
      customerName: "",
      customerNumber: "",
      customerAddress: null,
      deliveryTime: "",
      deliveryDistance: "",
      customerLat: "",
      customerLng: "",
      CBD: "false",
      orderAmount: 0,
    },
    validationSchema: individualOrderValidationSchema,
    validateOnBlur: true,
    onSubmit: (values) => {},
  });
  // let [errors] = useFormikContext();
  // console.log("errors from sub form",orderNumber,"are", formik.errors);
  // console.log("Form", orderNumber," is valid?", formik.isValid);
  // setFormikContext(formik);
  // When formik.values and formik.errors changes update the setFormikContext
  useEffect(() => {
    let context = {values:formik.values, errors: formik.errors, isValid:formik.isValid, resetForm: formik.resetForm}
    setFormikContext(context);
  }, [formik.values, setFormikContext, formik.errors, formik.isValid, formik.resetForm]);

  const handlePaymentStatusView = () => {
    switch (formik.values.orderSource) {
      case orderSources.Staff_DropOff.value:
        return false;
      case orderSources.Pickups.value:
        return false;

      default:
        return true;
    }
  };
  const handleCustomerNumber = () => {
    switch (formik.values.orderSource) {
      case orderSources.Uber.value:
        return true;
      case orderSources.Staff_DropOff.value:
        return false;
      case orderSources.Pickups.value:
        return false;
      default:
        return true;
    }
  };
  useEffect(() => {
    switch (formik.values.orderSource) {
      case orderSources.Staff_DropOff.value:
        setCustomerNameFieldText("Ando Staff Name");
        setCustomerNameFieldHelperText("Enter the first name only");
        setEnableRiderName(true);
        break;
      case orderSources.Pickups.value:
        setCustomerNameFieldText("Store Name");
        setCustomerNameFieldHelperText("Enter the store name");
        setEnableRiderName(true);
        break;

      default:
        setCustomerNameFieldText("Customer Name");
        break;
    }
  }, [
    customerNameFieldHelperText,
    customerNameFieldText,
    formik.values,
    formik,
  ]);
  //----------------Location Autocomplete-----------
  const [destinationPlaceId, setDestinationPlaceId] = useState("");
  const [customerLocationCoordinates, setCustomerLocationCoordinates] =
    useState("");
  const [customerAddressValue, setCustomerAddressValue] = React.useState(null);
  const [customerAddressInputValue, setCustomerAddressInputValue] =
    React.useState("");
  const [customerAddressOptions, setCustomerAddressOptions] = React.useState(
    []
  );
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GCLOUD_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const showComputedDistance = (value) => {
    if (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      !isNaN(value) &&
      value !== false
    ) {
      formik.setFieldValue("deliveryDistance", value);
      // setDeliveryDistance(value);
    } else if (value === "No route found") {
      formik.setFieldValue("deliveryDistance", "No route found");
      // formik.setErrors({deliveryDistance: "No route found"});
    } else {
      // console.log("GMaps Says distance is", value);
    }
  };

  const showComputedTime = (value) => {
    if (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      !isNaN(value) &&
      value !== false
    ) {
      formik.setFieldValue("deliveryTime", value);
      // setDeliveryTime(value);
    } else if (value === "No route found") {
      formik.setFieldValue("deliveryTime", "No route found");
      // formik.setErrors({deliveryTime: "No route found"});
      formik.setFieldError("deliveryTime", "No route found");
    } else {
      // console.log("GMaps Says distance is", value);
    }
  };

  // -----------------Location UseEffect------------------------
  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      geocoder.current = new window.google.maps.Geocoder();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (customerAddressInputValue === "") {
      setCustomerAddressOptions(
        formik.values.customerAddress ? [formik.values.customerAddress] : []
      );
      return undefined;
    }

    fetch(
      {
        input: customerAddressInputValue,
        bounds: defaultBounds,
        componentRestrictions: { country: "ke" },
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (formik.values.customerAddress) {
            newOptions = [formik.values.customerAddress];
            // setDestinationPlaceId(customerAddressValue.placeId);
            // console.log(formik.values.customerAddress);
            // console.log(formik.values.customerAddress.place_id);
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setCustomerAddressOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [formik.values.customerAddress, customerAddressInputValue, fetch]);
  return (
    <Container
      className={classes.formContainer}
      disableGutters={true}
      maxWidth="lg"
    >
      <form className={classes.formikForm} onSubmit={formik.handleSubmit}>
        <Container className={classes.inputFields} disableGutters={true}>
          <Container
            className={classes.textFieldWrapper}
            disableGutters={true}
            id="wrapperContainer"
            maxWidth="sm"
          >
            <Typography
              className={classes.textLabel}
              variant="body2"
              component="div"
              maxWidth="sm"
            >
              Mark order as first
            </Typography>
            <IOSSwitch
              checked={firstOrder === orderNumber}
              onChange={() => {
                handleOrderNumber(orderNumber);
              }}
              name="firstOrder"
            />
            <Typography
              className={classes.textLabel}
              variant="body2"
              component="div"
              maxWidth="sm"
            >
              Set this order as the first stop for dispatch
            </Typography>
          </Container>
        </Container>
        <Container className={classes.inputFields} disableGutters={true}>
          <Container
            className={classes.textFieldWrapper}
            disableGutters={true}
            id="wrapperContainer"
            maxWidth="sm"
          >
            <Typography
              className={classes.textLabel}
              variant="body2"
              component="div"
              maxWidth="sm"
            >
              Order Number
            </Typography>
            <TextField
              className={`${classes.textEntryField} "txtInput"`}
              sx={{ mt: "1vh" }}
              fullWidth
              id="orderId"
              name="orderId"
              // label="Order Number"
              value={formik.values.orderId}
              onChange={formik.handleChange}
              error={formik.touched.orderId && Boolean(formik.errors.orderId)}
              helperText={formik.touched.orderId && formik.errors.orderId}
              placeholder="Order Number"
              variant="filled"
              margin="normal"
              inputProps={{
                style: {
                  backgroundColor: colors.white,
                  paddingBottom: "1em",
                },
              }}
              InputProps={{
                className: classes.txtInput,
                classes: {
                  root: classes.txtInput,
                },
              }}
              size="small"
            />
          </Container>
        </Container>
        <Container
          className={classes.textFieldWrapper}
          disableGutters={true}
          id="wrapperContainer"
          maxWidth="sm"
        >
          <Typography
            className={`${classes.textLabel} required`}
            variant="body2"
            component="div"
          >
            Order Accepted Time
          </Typography>
          <KeyboardTimePicker
            // label="Masked timepicker"
            className={`${classes.timePicker} "txtInput"`}
            sx={{ mt: "1vh" }}
            // ref={orderTime}
            ampm={true}
            autoOk={false}
            required={true}
            variant="dialog"
            name="orderAcceptedTime"
            fullWidth
            id="orderAcceptedTime"
            key="orderAcceptedTime "
            helperText={formik.errors.orderAcceptedTime}
            // placeholder="08:00 AM"
            mask="__:__ _M"
            value={formik.values.orderAcceptedTime}
            onError={(error) => {
              // handle as a side effect
              if (
                error !== formik.errors.orderAcceptedTime &&
                error.length > 0
              ) {
                formik.setFieldError("orderAcceptedTime", error);
              } else if (error === "") {
                // Empty string- do nothing
              }
            }}
            onChange={(value) => {
              if (value != null) {
                formik.setFieldValue("orderAcceptedTime", value.toDate(), true);
              } else {
                formik.setFieldValue("orderAcceptedTime", "", false);
              }
            }}
            // onChange={formik.handleChange}
            inputProps={{
              style: {
                backgroundColor: colors.white,
                paddingBottom: "1em",
              },
            }}
            keyboardIcon={<ScheduleIcon />}
            size="small"
          />
        </Container>
        <Container
          className={classes.textFieldWrapper}
          disableGutters={true}
          id="wrapperContainer"
          maxWidth="sm"
        >
          <Typography
            className={`${classes.textLabel} required`}
            variant="body2"
            component="div"
          >
            Order Source
          </Typography>
          <FormControl component="fieldset" fullWidth={true}>
            <RadioGroup
              name="orderSource"
              value={formik.values.orderSource.toString()}
              onChange={(event) => {
                formik.setFieldValue("orderSource", event.currentTarget.value);
              }}
              fullWidth={true}
              className={classes.radioButtonsRowView}
            >
              {Object.keys(orderSources).map((keyName, i) => (
                <FormControlLabel
                  key={i}
                  fullWidth={true}
                  value={orderSources[keyName]["value"].toString()}
                  control={<Radio color="primary" />}
                  label={orderSources[keyName]["label"].toString()}
                  onChange={() => {
                    setSelectedOrderSource(
                      orderSources[keyName]["value"].toString()
                    );
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Container>
        {brands.length >= 1 && handlePaymentStatusView() ? (
          <Container
            className={classes.textFieldWrapper}
            disableGutters={true}
            id="wrapperContainer"
            maxWidth="sm"
          >
            <Typography
              className={`${classes.textLabel} required`}
              variant="body2"
              component="div"
            >
              Restaurants
            </Typography>
            <FormControl component="fieldset" fullWidth={true}>
              <RadioGroup
                name="restaurant"
                value={formik.values.brandId.toString()}
                onChange={(event) => {
                  formik.setFieldValue("brandId", event.currentTarget.value);
                }}
                fullWidth={true}
                className={classes.radioButtonsRowView}
              >
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {brands
                  .sort(function (a, b) {
                    return a.id - b.id;
                  })
                  .map((element, index, array) => (
                    <Grid item xs={2} sm={4} md={4} key={index}>
                    <FormControlLabel
                      key={index}
                      fullWidth={true}
                      value={element["id"].toString()}
                      control={<Radio color="primary" />}
                      label={element["name"].toString()}
                      onChange={() => {
                        // formik.setFieldValue(
                        //   "restaurant",
                        //   element["name"].toString()
                        // );
                      }}
                    />
                    </Grid>
                  ))}
                  </Grid>
              </RadioGroup>
            </FormControl>
          </Container>
        ) : null}
        {handlePaymentStatusView() ? (
          <Container
            className={classes.textFieldWrapper}
            disableGutters={true}
            id="wrapperContainer"
            maxWidth="sm"
          >
            <Typography
              className={`${classes.textLabel} required`}
              variant="body2"
              component="div"
            >
              Payment Status
            </Typography>
            <FormControl component="fieldset" fullWidth={true}>
              <RadioGroup
                name="orderSource"
                value={formik.values.paymentStatus.toString()}
                onChange={(event) => {
                  formik.setFieldValue(
                    "paymentStatus",
                    event.currentTarget.value
                  );
                }}
                fullWidth={true}
              >
                <FormControlLabel
                  fullWidth={true}
                  value="PAID"
                  control={<Radio color="primary" />}
                  label="Paid"
                  onChange={() => {
                    setSelectedPaymentStatus("Paid");
                    formik.setFieldValue("orderAmount", 0);
                    formik.setFieldValue("paymentStatus", "PAID");
                  }}
                  error={
                    formik.touched.paymentStatus &&
                    Boolean(formik.errors.paymentStatus)
                  }
                  helperText={
                    formik.touched.paymentStatus && formik.errors.paymentStatus
                  }
                />
                <FormControlLabel
                  sx={{
                    display: "flex",
                    width: "100%",
                    padding: "1.2em 1px 0.5em",
                  }}
                  // className={classes.radioText}
                  fullWidth={true}
                  value="UNPAID"
                  control={<Radio color="primary" />}
                  label={
                    <TextField
                      className={classes.radioTextField}
                      // type="number"
                      fullWidth={true}
                      margin="normal"
                      placeholder="Amount"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "orderAmount",
                          Number(event.target.value)
                        );
                        setSelectedPaymentStatus(event.target.value);
                      }}
                      onFocus={(event) => {
                        formik.setFieldValue("paymentStatus", "UNPAID");
                        formik.setFieldValue(
                          "orderAmount",
                          Number(event.target.value)
                        );
                        // console.log(event.target);
                        // setSelectedOrderSource(event.target.value);
                      }}
                      inputProps={{
                        style: {
                          backgroundColor: colors.white,
                          paddingBottom: "1em",
                        },
                      }}
                      size="small"
                      value={
                        formik.values.orderAmount
                          ? formik.values.orderAmount
                          : ""
                      }
                    />
                  }
                  onFocus={(event) => {
                    setSelectedPaymentStatus(event.target.value);
                    // formik.setFieldValue(
                    //   "orderAmount",
                    //   event.currentTarget.value
                    // );
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Container>
        ) : (
          <div />
        )}
        <Container
          className={classes.textFieldWrapper}
          disableGutters={true}
          id="wrapperContainer"
          maxWidth="sm"
        >
          <Typography
            className={`${classes.textLabel} required`}
            variant="body2"
            component="div"
          >
            {customerNameFieldText}
          </Typography>
          <TextField
            className={`${classes.textEntryField} "txtInput"`}
            sx={{ mt: "1vh" }}
            fullWidth
            id="customerName"
            name="customerName"
            // label="First Name"
            value={formik.values.customerName}
            onChange={formik.handleChange}
            error={
              formik.touched.customerName && Boolean(formik.errors.customerName)
            }
            helperText={
              formik.touched.customerName
                ? formik.errors.customerName
                  ? formik.errors.customerName
                  : customerNameFieldHelperText
                : customerNameFieldHelperText
            }
            placeholder="First Name"
            variant="filled"
            margin="normal"
            inputProps={{
              style: {
                backgroundColor: colors.white,
                paddingBottom: "1em",
              },
            }}
            InputProps={{
              className: classes.txtInput,
              classes: {
                root: classes.txtInput,
              },
            }}
            size="small"
            onBlur={formik.handleBlur}
          />
        </Container>
        {handleCustomerNumber() ? (
          <Container
            className={classes.textFieldWrapper}
            disableGutters={true}
            id="wrapperContainer"
            maxWidth="sm"
          >
            <Typography
              className={`${classes.textLabel} ${
                formik.values.orderSource === orderSources.Uber.value
                  ? ""
                  : "required"
              }`}
              variant="body2"
              component="div"
            >
              Customer Number
            </Typography>
            <TextField
              className={`${classes.textEntryField} "txtInput"`}
              sx={{ mt: "1vh" }}
              fullWidth
              id="customerNumber"
              name="customerNumber"
              // label="First Name"
              value={formik.values.customerNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.customerNumber &&
                Boolean(formik.errors.customerNumber)
              }
              helperText={
                formik.touched.customerNumber && formik.errors.customerNumber
                  ? formik.errors.customerNumber
                  : ""
              }
              placeholder="0712 345 678"
              variant="filled"
              margin="normal"
              inputProps={{
                style: {
                  backgroundColor: colors.white,
                  paddingBottom: "1em",
                },
              }}
              InputProps={{
                className: classes.txtInput,
                classes: {
                  root: classes.txtInput,
                },
              }}
              size="small"
              onBlur={formik.handleBlur}
            />
          </Container>
        ) : null}
        <Container
          className={classes.textFieldWrapper}
          disableGutters={true}
          id="wrapperContainer"
          maxWidth="sm"
        >
          <Typography
            className={`${classes.textLabel} required`}
            variant="body2"
            component="div"
          >
            Customer's Address
          </Typography>
          <Autocomplete
            sx={{ backgroundColor: colors.white }}
            color="primary"
            fullWidth={true}
            name="customerAddress"
            id="customerAddress"
            getOptionLabel={(option) => {
              if (typeof option === "string" || typeof option === "number") {
                return option.toString();
              } else {
                return option.description;
              }
              // return ( typeof option === "string" ? option : option.description)
            }}
            getOptionSelected={(option, value) =>
              option.description === value.description
            }
            filterOptions={(x) => x}
            options={customerAddressOptions}
            value={formik.values.customerAddress}
            onChange={async (event, newValue) => {
              // console.log("new Value is: ", JSON.stringify(newValue));
              setCustomerAddressOptions(
                newValue
                  ? [newValue, ...customerAddressOptions]
                  : customerAddressOptions
              );
              if (newValue) {
                formik.setFieldValue("customerAddress", newValue.description);
                let geocodedResult;
                await geocoder.current.geocode(
                  { placeId: newValue.place_id },
                  (res) => {
                    /* console.log("res is:", JSON.stringify(res));
                    console.log(
                      "location is",
                      res[0].geometry.location.toJSON()
                    ); */
                    setCustomerLocationCoordinates(
                      res[0].geometry.location.toJSON()
                    );

                    geocodedResult = res;
                    let hooks = {
                      showComputedDistance: showComputedDistance,
                      showComputedTime: showComputedTime,
                    };
                    formik.setFieldValue(
                      "customerLat",
                      String(res[0].geometry.location.toJSON().lat)
                    );
                    formik.setFieldValue(
                      "customerLng",
                      String(res[0].geometry.location.toJSON().lng)
                    );
                    handleLocationUpdate({
                      geocodedResult: geocodedResult,
                      orderNumber: orderNumber,
                      hooks: hooks,
                    });
                    // console.log("handleLocationUpdate called");
                  }
                );
                // setCustomerAddressValue(newValue);
                handleDestinationPlaceId(newValue);
              } else {
                // console.log("newValue is falsy");
              }
            }}
            onInputChange={(event, newInputValue) => {
              setCustomerAddressInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                // label="Add an address"
                className={`${classes.autocompleteField} `}
                // color="primary"
                placeholder="Address"
                fullWidth
                // helperText="Enter the Customer’s address (Map View)"
                error={
                  formik.touched.customerAddress &&
                  Boolean(formik.errors.customerAddress)
                }
                helperText={
                  formik.touched.customerAddress &&
                  formik.errors.customerAddress
                }
                variant="filled"
                size="small"
                onBlur={formik.handleBlur}
                // margin="normal"
              />
            )}
            renderOption={(option) => {
              if (typeof option === "object") {
                // console.log("option is", option);
                const matches =
                  option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                  option.structured_formatting.main_text,
                  matches.map((match) => [
                    match.offset,
                    match.offset + match.length,
                  ])
                );

                return (
                  <Grid container alignItems="center">
                    <Grid item>
                      <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}

                      <Typography variant="body2" color="textSecondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              } else {
                return <> </>;
              }
            }}
          />
        </Container>
        <Container
          className={classes.distanceFieldsSideBySide}
          disableGutters={true}
          id="wrapperContainer"
          maxWidth="sm"
        >
          {/* Delivery Time */}
          <Container
            className={classes.textFieldWrapper}
            disableGutters={true}
            id="wrapperContainer"
            maxWidth="sm"
          >
            <Typography
              className={`${classes.textLabel} required`}
              variant="body2"
              component="div"
            >
              Estimated Delivery Time (Minutes)
            </Typography>
            <TextField
              className={`${classes.textEntryField} "txtInput"`}
              sx={{ mt: "1vh" }}
              fullWidth
              id="deliveryTime"
              name="deliveryTime"
              // label="First Name"
              value={formik.values.deliveryTime}
              onChange={formik.handleChange}
              error={
                formik.touched.customerAddress &&
                Boolean(formik.errors.deliveryTime)
              }
              helperText={
                formik.touched.customerAddress && formik.errors.deliveryTime
              }
              placeholder="minutes"
              variant="filled"
              margin="normal"
              inputProps={{
                style: {
                  backgroundColor: colors.white,
                  paddingBottom: "1em",
                },
              }}
              InputProps={{
                className: classes.txtInput,
                classes: {
                  root: classes.txtInput,
                },
              }}
              size="small"
              disabled={true}
            />
          </Container>
          {/* Delivery Distance */}
          <Container
            className={classes.textFieldWrapper}
            disableGutters={true}
            id="wrapperContainer"
            maxWidth="sm"
          >
            <Typography
              className={`${classes.textLabel} required`}
              variant="body2"
              component="div"
            >
              Estimated Distance (Km)
            </Typography>
            <TextField
              className={`${classes.textEntryField} "txtInput"`}
              sx={{ mt: "1vh" }}
              fullWidth
              id="deliveryDistance"
              name="deliveryDistance"
              // label="First Name"
              value={formik.values.deliveryDistance}
              onChange={formik.handleChange}
              error={
                formik.touched.customerAddress &&
                Boolean(formik.errors.deliveryDistance)
              }
              helperText={
                formik.touched.customerAddress && formik.errors.deliveryDistance
              }
              placeholder="km"
              variant="filled"
              margin="normal"
              inputProps={{
                style: {
                  backgroundColor: colors.white,
                  paddingBottom: "1em",
                },
              }}
              InputProps={{
                className: classes.txtInput,
                classes: {
                  root: classes.txtInput,
                },
              }}
              size="small"
              disabled={true}
            />
          </Container>
        </Container>
      </form>
    </Container>
  );
}
function formatQueueRiders(name, time, mode, postionInQueue, id) {
  // console.log(`id`, id)
  let checkInTime = new Date(time).toLocaleTimeString("en-US");
  // console.log("Checkin Time is:", checkInTime);
  let number;
  if (postionInQueue === 1) {
    number = "Up Next";
  } else {
    number = "In Line";
  }
  let modeOfTransportation = mode;
  let output = { name, checkInTime, modeOfTransportation, number, id: id };
  // console.log(`Output:`, JSON.stringify(output))
  return { name, checkInTime, modeOfTransportation, number, id: id };
}

function SingleOrderView({
  brands,
  enableRiderName,
  setEnableRiderName,
  orderNumber,
  handleOrderNumber,
  firstOrder,
  handleLocationUpdate,
  formikContext,
  setFormikContext,
  rows,
  riderNames,
  kitchenOrigin,
  kitchenId,
  tumiaSnack

}) {
  const classes = useStyles();
  const [selectedOrderSource, setSelectedOrderSource] = useState(
    orderSources.Organic.value
  );
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("Paid");
  const [customerNameFieldText, setCustomerNameFieldText] =
    useState("Customer Name");
  const [customerNameFieldHelperText, setCustomerNameFieldHelperText] =
    useState("");
  // const [enableRiderName, setEnableRiderName] = useState(false);

  // const orderSourceRef = useRef(formik.values.orderSource);
  //   useEffect(() => {
  //     orderSourceRef.current = formik.values.orderSource;
  // });

  const formik = useFormik({
    initialValues: {
      orderId: "",
      orderAcceptedTime: moment().format("YYYY-MM-DDTHH:mm:ss.sssZZ"),
      orderSource: orderSources.Organic.value,
      brandId: "1",
      paymentStatus: "Paid".toUpperCase(),
      customerName: "",
      customerNumber: "",
      customerAddress: null,
      deliveryTime: "",
      deliveryDistance: "",
      customerLat: "",
      customerLng: "",
      CBD: "false",
      orderAmount: 0,
      riderName: null,
      riderId: "",
      kitchenId: login.getKitchenId(),
      duplicateOrder: "false",
      duplicateReason: "",
    },
    validationSchema: singleOrderValidationSchema,
    validateOnBlur: true,
    onSubmit: async (values) => {
      console.log("Single order form ", "is valid?", formik.isValid);
      console.log("Single order form", "values", formik.values);

      const riderId = parseInt(formik?.values?.riderId, 10);
      const radius = calculateDistance(kitchenOrigin.lat, kitchenOrigin.lng, formik?.values?.customerLat, formik?.values?.customerLng);
      console.log("radius", radius);
      const foundRow = rows.find(row => row.id === riderId);
      const modeOfTransportation = foundRow ? foundRow.modeOfTransportation : 'Not Found';
      const isPedalBikeRider = modeOfTransportation === 'PEDAL BIKE' && radius > 7.0;
      if(isPedalBikeRider){
        alert("A Pedal Bike Rider can't be assigned due to the radius being greater than 7km.")
        return;
      }
      // Check if the order source is Pickups OR Staff Dropoff then remove the order amount and set the payment status to paid
      if (values.orderSource === orderSources.Pickups.value || values.orderSource === orderSources.Staff_DropOff.value) {
        values.orderAmount = 0;
        values.paymentStatus = "Paid".toUpperCase();
      }
      // Submit the form
      let submissionResponse = await apiService.submit(values);
      console.log(submissionResponse);
      if (submissionResponse.status === 200) {
        let body = await submissionResponse.json();
        console.log("Success, data is:", body);
        // setSeverity("success");
        // setError("Successfully Dispatched!");
        // setShowError(true);
        tumiaSnack("Successfully Dispatched!", "success");
        resetForm();
        window.location.reload({ forcedReload: true });
      } else {
        let body = await submissionResponse.json();
        console.log("Failed, data is:", body);
        formik.setSubmitting(false);
        // setError("Something went wrong");
        // setShowError(true);
        tumiaSnack("Something went wrong", body);
      }
      formik.setSubmitting(false);
    },
  });
  function resetForm() {
    formik.resetForm();
    setDestinationPlaceId(null);

  }
  // console.log('duplicateOrder', formik.values.duplicateOrder);
  // console.log('duplicateReason', formik.values.duplicateReason);
  // let [values] = useFormikContext();
  // console.log("values from Single order form","are", formik.values);
  // console.log("errors from Single order form","are", formik.errors);
  // console.log("Single order form"," is valid?", formik.isValid);
  // setFormikContext(formik);
  // When formik.values and formik.errors changes update the setFormikContext
  useEffect(() => {
    let context = {
      values: formik.values,
      errors: formik.errors,
      isValid: formik.isValid,
    };
    setFormikContext(context);
  }, [formik.values, setFormikContext, formik.errors, formik.isValid]);

  const handlePaymentStatusView = () => {
    switch (formik.values.orderSource) {
      case orderSources.Staff_DropOff.value:
        return false;
      case orderSources.Pickups.value:
        return false;

      default:
        return true;
    }
  };
  const handleCustomerNumber = () => {
    switch (formik.values.orderSource) {
      case orderSources.Uber.value:
        return true;
      case orderSources.Staff_DropOff.value:
        return false;
      case orderSources.Pickups.value:
        return false;
      default:
        return true;
    }
  };
  useEffect(() => {
    switch (formik.values.orderSource) {
      case orderSources.Staff_DropOff.value:
        setCustomerNameFieldText("Ando Staff Name");
        setCustomerNameFieldHelperText("Enter the first name only");
        setEnableRiderName(true);
        break;
      case orderSources.Pickups.value:
        setCustomerNameFieldText("Store Name");
        setCustomerNameFieldHelperText("Enter the store name");
        setEnableRiderName(true);
        break;

      default:
        setCustomerNameFieldText("Customer Name");
        break;
    }
  }, [
    customerNameFieldHelperText,
    customerNameFieldText,
    formik.values,
    formik,
  ]);
  //----------------Location Autocomplete-----------
  const [destinationPlaceId, setDestinationPlaceId] = useState("");
  const [customerLocationCoordinates, setCustomerLocationCoordinates] =
    useState("");
  const [customerAddressValue, setCustomerAddressValue] = React.useState(null);
  const [customerAddressInputValue, setCustomerAddressInputValue] =
    React.useState("");
  const [customerAddressOptions, setCustomerAddressOptions] = React.useState(
    []
  );
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GCLOUD_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const showComputedDistance = (value) => {
    if (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      !isNaN(value) &&
      value !== false
    ) {
      formik.setFieldValue("deliveryDistance", value);
      // setDeliveryDistance(value);
    } else if (value === "No route found") {
      formik.setFieldValue("deliveryDistance", "No route found");
      // formik.setErrors({deliveryDistance: "No route found"});
    } else {
      // console.log("GMaps Says distance is", value);
    }
  };

  const showComputedTime = (value) => {
    if (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      !isNaN(value) &&
      value !== false
    ) {
      formik.setFieldValue("deliveryTime", value);
      // setDeliveryTime(value);
    } else if (value === "No route found") {
      formik.setFieldValue("deliveryTime", "No route found");
      // formik.setErrors({deliveryTime: "No route found"});
      formik.setFieldError("deliveryTime", "No route found");
    } else {
      // console.log("GMaps Says distance is", value);
    }
  };
  const handleDestinationPlaceId = (value) => {
    if (value) {
      setDestinationPlaceId(value.place_id);
    } else {
      // Do nothing
      console.log("PlaceId is", value);
    }
  };
  // -----------------Location UseEffect------------------------
  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      geocoder.current = new window.google.maps.Geocoder();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (customerAddressInputValue === "") {
      setCustomerAddressOptions(
        formik.values.customerAddress ? [formik.values.customerAddress] : []
      );
      return undefined;
    }

    fetch(
      {
        input: customerAddressInputValue,
        bounds: defaultBounds,
        componentRestrictions: { country: "ke" },
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (formik.values.customerAddress) {
            newOptions = [formik.values.customerAddress];
            // setDestinationPlaceId(customerAddressValue.placeId);
            // console.log(formik.values.customerAddress);
            // console.log(formik.values.customerAddress.place_id);
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setCustomerAddressOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [formik.values.customerAddress, customerAddressInputValue, fetch]);
  return (
    <Container
      className={classes.formContainer}
      disableGutters={true}
      maxWidth="lg"
    >
      <form className={classes.formikForm} onSubmit={formik.handleSubmit}>
        <Container
          className={classes.inputFields}
          disableGutters={true}
        ></Container>
        <Container className={classes.inputFields} disableGutters={true}>
          <Container
            className={classes.textFieldWrapper}
            disableGutters={true}
            id="wrapperContainer"
            maxWidth="sm"
          >
            <Typography
              className={classes.textLabel}
              variant="body2"
              component="div"
              maxWidth="sm"
            >
              Order Number
            </Typography>
            <TextField
              className={`${classes.textEntryField} "txtInput"`}
              sx={{ mt: "1vh" }}
              fullWidth
              id="orderId"
              name="orderId"
              // label="Order Number"
              value={formik.values.orderId}
              onChange={formik.handleChange}
              error={formik.touched.orderId && Boolean(formik.errors.orderId)}
              helperText={formik.touched.orderId && formik.errors.orderId}
              placeholder="Order Number"
              variant="filled"
              margin="normal"
              inputProps={{
                style: {
                  backgroundColor: colors.white,
                  paddingBottom: "1em",
                },
              }}
              InputProps={{
                className: classes.txtInput,
                classes: {
                  root: classes.txtInput,
                },
              }}
              size="small"
              onBlur={formik.handleBlur}
            />
          </Container>
        </Container>
        <Container
          className={classes.textFieldWrapper}
          disableGutters={true}
          id="wrapperContainer"
          maxWidth="sm"
        >
          <Typography
            className={`${classes.textLabel} required`}
            variant="body2"
            component="div"
          >
            Order Accepted Time
          </Typography>
          <KeyboardTimePicker
            // label="Masked timepicker"
            className={`${classes.timePicker} "txtInput"`}
            sx={{ mt: "1vh" }}
            // ref={orderTime}
            ampm={true}
            autoOk={false}
            required={true}
            variant="dialog"
            name="orderAcceptedTime"
            fullWidth
            id="orderAcceptedTime"
            key="orderAcceptedTime "
            helperText={formik.errors.orderAcceptedTime}
            // placeholder="08:00 AM"
            mask="__:__ _M"
            value={formik.values.orderAcceptedTime}
            onError={(error) => {
              // handle as a side effect
              if (
                error !== formik.errors.orderAcceptedTime &&
                error.length > 0
              ) {
                formik.setFieldError("orderAcceptedTime", error);
              } else if (error === "") {
                // Empty string- do nothing
              }
            }}
            onChange={(value) => {
              if (value != null) {
                formik.setFieldValue("orderAcceptedTime", value.toDate(), true);
              } else {
                formik.setFieldValue("orderAcceptedTime", "", false);
              }
            }}
            // onChange={formik.handleChange}
            inputProps={{
              style: {
                backgroundColor: colors.white,
                paddingBottom: "1em",
              },
            }}
            keyboardIcon={<ScheduleIcon />}
            size="small"
          />
        </Container>
        <Container
          className={classes.textFieldWrapper}
          disableGutters={true}
          id="wrapperContainer"
          maxWidth="sm"
        >
          <Typography
            className={`${classes.textLabel} required`}
            variant="body2"
            component="div"
          >
            Order Source
          </Typography>
          <FormControl component="fieldset" fullWidth={true}>
            <RadioGroup
              name="orderSource"
              value={formik.values.orderSource.toString()}
              onChange={(event) => {
                formik.setFieldValue("orderSource", event.currentTarget.value);
              }}
              fullWidth={true}
              className={classes.radioButtonsRowView}
            >
              {Object.keys(orderSources).map((keyName, i) => (
                <FormControlLabel
                  key={i}
                  fullWidth={true}
                  value={orderSources[keyName]["value"].toString()}
                  control={<Radio color="primary" />}
                  label={orderSources[keyName]["label"].toString()}
                  onChange={() => {
                    setSelectedOrderSource(
                      orderSources[keyName]["value"].toString()
                    );
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Container>
        {brands.length >= 1 && handlePaymentStatusView() ? (
          <Container
            className={classes.textFieldWrapper}
            disableGutters={true}
            id="wrapperContainer"
            maxWidth="sm"
          >
            <Typography
              className={`${classes.textLabel} required`}
              variant="body2"
              component="div"
            >
              Restaurants
            </Typography>
            <FormControl component="fieldset" fullWidth={true}>
              <RadioGroup
                name="restaurant"
                value={formik.values.brandId.toString()}
                onChange={(event) => {
                  formik.setFieldValue("brandId", event.currentTarget.value);
                }}
                fullWidth={true}
                className={classes.radioButtonsRowView}
              >
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {brands
                    .sort(function (a, b) {
                      return a.id - b.id;
                    })
                    .map((element, index, array) => (
                      <Grid item xs={2} sm={4} md={4} key={index}>
                        <FormControlLabel
                          key={index}
                          fullWidth={true}
                          value={element["id"].toString()}
                          control={<Radio color="primary" />}
                          label={element["name"].toString()}
                          onChange={() => {
                            // formik.setFieldValue(
                            //   "restaurant",
                            //   element["name"].toString()
                            // );
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </RadioGroup>
            </FormControl>
          </Container>
        ) : null}
        {handlePaymentStatusView() ? (
          <Container
            className={classes.textFieldWrapper}
            disableGutters={true}
            id="wrapperContainer"
            maxWidth="sm"
          >
            <Typography
              className={`${classes.textLabel} required`}
              variant="body2"
              component="div"
            >
              Payment Status
            </Typography>
            <FormControl component="fieldset" fullWidth={true}>
              <RadioGroup
                name="orderSource"
                value={formik.values.paymentStatus.toString()}
                onChange={(event) => {
                  formik.setFieldValue(
                    "paymentStatus",
                    event.currentTarget.value
                  );
                }}
                fullWidth={true}
              >
                <FormControlLabel
                  fullWidth={true}
                  value="PAID"
                  control={<Radio color="primary" />}
                  label="Paid"
                  onChange={() => {
                    setSelectedPaymentStatus("Paid");
                    formik.setFieldValue("orderAmount", 0);
                    formik.setFieldValue("paymentStatus", "PAID");
                  }}
                  error={
                    formik.touched.paymentStatus &&
                    Boolean(formik.errors.paymentStatus)
                  }
                  helperText={
                    formik.touched.paymentStatus && formik.errors.paymentStatus
                  }
                />
                <FormControlLabel
                  sx={{
                    display: "flex",
                    width: "100%",
                    padding: "1.2em 1px 0.5em",
                  }}
                  // className={classes.radioText}
                  fullWidth={true}
                  value="UNPAID"
                  control={<Radio color="primary" />}
                  label={
                    <TextField
                      className={classes.radioTextField}
                      // type="number"
                      fullWidth={true}
                      margin="normal"
                      placeholder="Amount"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "orderAmount",
                          Number(event.target.value)
                        );
                        setSelectedPaymentStatus(event.target.value);
                      }}
                      onFocus={(event) => {
                        formik.setFieldValue("paymentStatus", "UNPAID");
                        formik.setFieldValue(
                          "orderAmount",
                          Number(event.target.value)
                        );
                        // console.log(event.target);
                        // setSelectedOrderSource(event.target.value);
                      }}
                      inputProps={{
                        style: {
                          backgroundColor: colors.white,
                          paddingBottom: "1em",
                        },
                      }}
                      size="small"
                      value={
                        formik.values.orderAmount
                          ? formik.values.orderAmount
                          : ""
                      }
                    />
                  }
                  onFocus={(event) => {
                    setSelectedPaymentStatus(event.target.value);
                    // formik.setFieldValue(
                    //   "orderAmount",
                    //   event.currentTarget.value
                    // );
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Container>
        ) : (
          <div />
        )}
        <Container
          className={classes.textFieldWrapper}
          disableGutters={true}
          id="wrapperContainer"
          maxWidth="sm"
        >
          <Typography
            className={`${classes.textLabel} required`}
            variant="body2"
            component="div"
          >
            {customerNameFieldText}
          </Typography>
          <TextField
            className={`${classes.textEntryField} "txtInput"`}
            sx={{ mt: "1vh" }}
            fullWidth
            id="customerName"
            name="customerName"
            // label="First Name"
            value={formik.values.customerName}
            onChange={formik.handleChange}
            error={
              formik.touched.customerName && Boolean(formik.errors.customerName)
            }
            helperText={
              formik.touched.customerName
                ? formik.errors.customerName
                  ? formik.errors.customerName
                  : customerNameFieldHelperText
                : customerNameFieldHelperText
            }
            placeholder="First Name"
            variant="filled"
            margin="normal"
            inputProps={{
              style: {
                backgroundColor: colors.white,
                paddingBottom: "1em",
              },
            }}
            InputProps={{
              className: classes.txtInput,
              classes: {
                root: classes.txtInput,
              },
            }}
            size="small"
            onBlur={formik.handleBlur}
          />
        </Container>
        {handleCustomerNumber() ? (
          <Container
            className={classes.textFieldWrapper}
            disableGutters={true}
            id="wrapperContainer"
            maxWidth="sm"
          >
            <Typography
              className={`${classes.textLabel} ${
                formik.values.orderSource === orderSources.Uber.value
                  ? ""
                  : "required"
              }`}
              variant="body2"
              component="div"
            >
              Customer Number
            </Typography>
            <TextField
              className={`${classes.textEntryField} "txtInput"`}
              sx={{ mt: "1vh" }}
              fullWidth
              id="customerNumber"
              name="customerNumber"
              // label="First Name"
              value={formik.values.customerNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.customerNumber &&
                Boolean(formik.errors.customerNumber)
              }
              helperText={
                formik.touched.customerNumber && formik.errors.customerNumber
                  ? formik.errors.customerNumber
                  : ""
              }
              placeholder="0712 345 678"
              variant="filled"
              margin="normal"
              inputProps={{
                style: {
                  backgroundColor: colors.white,
                  paddingBottom: "1em",
                },
              }}
              InputProps={{
                className: classes.txtInput,
                classes: {
                  root: classes.txtInput,
                },
              }}
              size="small"
              onBlur={formik.handleBlur}
            />
          </Container>
        ) : null}
        <Container
          className={classes.textFieldWrapper}
          disableGutters={true}
          id="wrapperContainer"
          maxWidth="sm"
        >
          <Typography
            className={`${classes.textLabel} required`}
            variant="body2"
            component="div"
          >
            Customer's Address
          </Typography>
          <Autocomplete
            sx={{ backgroundColor: colors.white }}
            color="primary"
            fullWidth={true}
            name="customerAddress"
            id="customerAddress"
            getOptionLabel={(option) => {
              if (typeof option === "string" || typeof option === "number") {
                return option.toString();
              } else {
                return option.description;
              }
              // return ( typeof option === "string" ? option : option.description)
            }}
            getOptionSelected={(option, value) =>
              option.description === value.description
            }
            filterOptions={(x) => x}
            options={customerAddressOptions}
            value={formik.values.customerAddress}
            onChange={async (event, newValue) => {
              console.log("new Value is: ", JSON.stringify(newValue));
              setCustomerAddressOptions(
                newValue
                  ? [newValue, ...customerAddressOptions]
                  : customerAddressOptions
              );
              if (newValue) {
                formik.setFieldValue("customerAddress", newValue.description);
                let geocodedResult;
                await geocoder.current.geocode(
                  { placeId: newValue.place_id },
                  (res) => {
                    /* console.log("res is:", JSON.stringify(res));
                    console.log(
                      "location is",
                      res[0].geometry.location.toJSON()
                    ); */
                    setCustomerLocationCoordinates(
                      res[0].geometry.location.toJSON()
                    );

                    geocodedResult = res;
                    let hooks = {
                      showComputedDistance: showComputedDistance,
                      showComputedTime: showComputedTime,
                    };
                    formik.setFieldValue(
                      "customerLat",
                      String(res[0].geometry.location.toJSON().lat)
                    );
                    formik.setFieldValue(
                      "customerLng",
                      String(res[0].geometry.location.toJSON().lng)
                    );
                    handleLocationUpdate({
                      geocodedResult: geocodedResult,
                      orderNumber: orderNumber,
                      hooks: hooks,
                    });
                    // console.log("handleLocationUpdate called");
                  }
                );
                // setCustomerAddressValue(newValue);
                handleDestinationPlaceId(newValue);
              } else {
                // console.log("newValue is falsy");
              }
            }}
            onInputChange={(event, newInputValue) => {
              setCustomerAddressInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                // label="Add an address"
                className={`${classes.autocompleteField} `}
                // color="primary"
                placeholder="Address"
                fullWidth
                // helperText="Enter the Customer’s address (Map View)"
                error={
                  formik.touched.customerAddress &&
                  Boolean(formik.errors.customerAddress)
                }
                helperText={
                  formik.touched.customerAddress &&
                  formik.errors.customerAddress
                }
                variant="filled"
                size="small"
                onBlur={formik.handleBlur}
                // margin="normal"
              />
            )}
            renderOption={(option) => {
              if (typeof option === "object") {
                // console.log("option is", option);
                const matches =
                  option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                  option.structured_formatting.main_text,
                  matches.map((match) => [
                    match.offset,
                    match.offset + match.length,
                  ])
                );

                return (
                  <Grid container alignItems="center">
                    <Grid item>
                      <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}

                      <Typography variant="body2" color="textSecondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              } else {
                return <> </>;
              }
            }}
          />
        </Container>
        <Container
          className={classes.distanceFieldsSideBySide}
          disableGutters={true}
          id="wrapperContainer"
          maxWidth="sm"
        >
          {/* Delivery Time */}
          <Container
            className={classes.textFieldWrapper}
            disableGutters={true}
            id="wrapperContainer"
            maxWidth="sm"
          >
            <Typography
              className={`${classes.textLabel} required`}
              variant="body2"
              component="div"
            >
              Estimated Delivery Time (Minutes)
            </Typography>
            <TextField
              className={`${classes.textEntryField} "txtInput"`}
              sx={{ mt: "1vh" }}
              fullWidth
              id="deliveryTime"
              name="deliveryTime"
              // label="First Name"
              value={formik.values.deliveryTime}
              onChange={formik.handleChange}
              error={
                formik.touched.customerAddress &&
                Boolean(formik.errors.deliveryTime)
              }
              helperText={
                formik.touched.customerAddress && formik.errors.deliveryTime
              }
              placeholder="minutes"
              variant="filled"
              margin="normal"
              inputProps={{
                style: {
                  backgroundColor: colors.white,
                  paddingBottom: "1em",
                },
              }}
              InputProps={{
                className: classes.txtInput,
                classes: {
                  root: classes.txtInput,
                },
              }}
              size="small"
              disabled={true}
            />
          </Container>
          {/* Delivery Distance */}
          <Container
            className={classes.textFieldWrapper}
            disableGutters={true}
            id="wrapperContainer"
            maxWidth="sm"
          >
            <Typography
              className={`${classes.textLabel} required`}
              variant="body2"
              component="div"
            >
              Estimated Distance (Km)
            </Typography>
            <TextField
              className={`${classes.textEntryField} "txtInput"`}
              sx={{ mt: "1vh" }}
              fullWidth
              id="deliveryDistance"
              name="deliveryDistance"
              // label="First Name"
              value={formik.values.deliveryDistance}
              onChange={formik.handleChange}
              error={
                formik.touched.customerAddress &&
                Boolean(formik.errors.deliveryDistance)
              }
              helperText={
                formik.touched.customerAddress && formik.errors.deliveryDistance
              }
              placeholder="km"
              variant="filled"
              margin="normal"
              inputProps={{
                style: {
                  backgroundColor: colors.white,
                  paddingBottom: "1em",
                },
              }}
              InputProps={{
                className: classes.txtInput,
                classes: {
                  root: classes.txtInput,
                },
              }}
              size="small"
              disabled={true}
            />
          </Container>
        </Container>
        <Container
          className={classes.textFieldWrapper}
          disableGutters={true}
          id="wrapperContainer"
          maxWidth="sm"
        >
          <Typography
            className={`${classes.textLabel} required`}
            variant="body2"
            component="div"
          >
            Is this a duplicate order?
          </Typography>
          <FormControl component="fieldset" fullWidth={true}>
            <RadioGroup
              name="duplicateOrder"
              className={classes.duplicateButtonsRowView}
              value={formik.values.duplicateOrder}
              onChange={(event) => {
                formik.setFieldValue(
                  "duplicateOrder",
                  event.currentTarget.value
                );
              }}
              fullWidth={true}
            >
              <FormControlLabel
                fullWidth={true}
                value={"false"}
                control={<Radio color="primary" />}
                label="No"
                onChange={() => {
                  // setSelectedPaymentStatus("Paid");
                  // formik.setFieldValue("orderAmount", 0);
                  // formik.setFieldValue("paymentStatus", "PAID");
                }}
                error={
                  formik.touched.duplicateOrder &&
                  Boolean(formik.errors.duplicateOrder)
                }
                helperText={
                  formik.touched.duplicateOrder && formik.errors.duplicateOrder
                }
              />
              <FormControlLabel
                sx={{
                  display: "flex",
                  width: "100%",
                  padding: "1.2em 1px 0.5em",
                }}
                // className={classes.radioText}
                fullWidth={true}
                value={"true"}
                control={<Radio color="primary" />}
                label="Yes"
                onFocus={(event) => {
                  // setSelectedPaymentStatus(event.target.value);
                  // formik.setFieldValue(
                  //   "orderAmount",
                  //   event.currentTarget.value
                  // );
                }}
              />
            </RadioGroup>
            {formik.values.duplicateOrder === "true" ? (
              /*                     <TextField
                      className={classes.radioTextField}
                      // type="number"
                      fullWidth={true}
                      margin="normal"
                      placeholder="Duplicate Reason"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "duplicateReason",
                          event.target.value
                        );
                        // setSelectedPaymentStatus(event.target.value);
                      }}
                      onFocus={(event) => {
                        formik.setFieldValue("duplicateOrder", "true");
                        formik.setFieldValue(
                          "duplicateReason",
                          event.target.value
                        );
                        // console.log(event.target);
                        // setSelectedOrderSource(event.target.value);
                      }}
                      inputProps={{
                        style: {
                          backgroundColor: colors.white,
                          paddingBottom: "1em",
                        },
                      }}
                      size="small"
                      value={
                        formik.values.duplicateReason
                          ? formik.values.duplicateReason
                          : ""
                      }
                    /> */
              <Autocomplete
                disablePortal
                id="duplicateReason"
                name="duplicateReason"
                value={formik.values.duplicateReason}
                options={duplicateOrderReasons}
                // getOptionLabel={(option) => option.label}
                onChange={(event, value) => {
                  console.log("New Value", value);
                  if (value !== null) {
                    formik.setFieldValue("duplicateReason", value.label);
                  } else {
                    formik.setFieldValue("duplicateReason", "");
                  }
                }}
                error={
                  formik.touched.duplicateReason &&
                  Boolean(
                    formik.errors.duplicateReason.length < 1 ? false : true
                  )
                }
                helperText={
                  formik.touched.duplicateReason &&
                  formik.errors.duplicateReason
                }
                placeholder="Enter the duplicate reason"
                getOptionSelected={(option, value) => {
                  if (typeof value === "string" || typeof value === "number") {
                    return option.label === value;
                  } else {
                    return option.label === value.label;
                  }
                }}
                getOptionLabel={(option) => {
                  if (
                    typeof option === "string" ||
                    typeof option === "number"
                  ) {
                    return option.toString();
                  } else {
                    return option.label;
                  }
                }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Duplicate Reason" />
                )}
              />
            ) : null}
          </FormControl>
        </Container>
        <Container
          className={classes.textFieldWrapper}
          disableGutters={true}
          id="wrapperContainer"
          maxWidth="md"
        >
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <StyledTableCell className={classes.tableRow}>
                    Rider name
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableRow} align="right">
                    Check-in time
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableRow} align="right">
                    Mode of Transportation
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableRow} align="right">
                    Status
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableRow} align="center">
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.checkInTime}</TableCell>
                      <TableCell align="right">
                        {<ModeOfTransport mode={row.modeOfTransportation} />}
                      </TableCell>
                      <TableCell align="right">{row.number}</TableCell>
                      <TableCell align="right">
                        <Button
                          className={classes.selectRiderButton}
                          variant="outlined"
                          onClick={() => {
                            setEnableRiderName(true);
                            // console.log("enableRiderName", enableRiderName);
                            formik
                              .setFieldValue("riderName", row.name)
                              .then(() => {
                                console.log(
                                  "riderName",
                                  formik.values.riderName
                                );
                                formik.validateForm();
                              });
                            // console.log("riderName", formik.values.riderName);
                            formik
                              .setFieldValue("riderId", row.id.toString())
                              .then(() => {
                                console.log("riderId", formik.values.riderId);
                                formik.validateForm();
                              });

                            // console.log("riderId", formik.values.riderId);
                          }}
                        >
                          Select Rider
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
        <Container
          className={classes.textFieldWrapper}
          disableGutters={true}
          id="wrapperContainer"
          maxWidth="sm"
        >
          <Typography
            className={`${classes.textLabel} required`}
            variant="body2"
            component="div"
          >
            Rider's Name
          </Typography>
          <Autocomplete
            disabled={!enableRiderName}
            freeSolo={true}
            fullWidth={true}
            size="large"
            name="riderName"
            id="riderName"
            value={formik.values.riderName}
            onChange={(event, newValue) => {
              // console.log("New Value is:", newValue);
              if (newValue && newValue.label) {
                formik.setFieldValue("riderName", newValue.label);
                formik.setFieldValue("riderId", newValue.riderId.toString());
              } else {
                formik.setFieldValue("riderName", newValue);
                // console.log("Rider name is:", newValue);
              }
              // formik.setFieldValue("riderName", newValue.label);
            }}
            options={riderNames}
            getOptionLabel={(option) => {
              if (typeof option === "string" || typeof option === "number") {
                return option.toString();
              } else {
                return option.label;
              }
            }}
            getOptionSelected={(option, value) => option.label === value.label}
            renderInput={(params) => (
              <TextField
                {...params}
                className={`${classes.autocompleteField} `}
                required
                placeholder="Enter the rider's name"
                error={
                  formik.touched.riderName && Boolean(formik.errors.riderName)
                }
                helperText={formik.touched.riderName && formik.errors.riderName}
                variant="filled"
                size="small"
              />
            )}
          />
        </Container>
        <Container
          className={classes.mapViewWrapper}
          disableGutters={true}
          id="wrapperContainer"
          maxWidth="sm"
        >
          <Typography
            className={`${classes.mapViewText}`}
            sx={{ textAlign: "center" }}
            variant="body2"
            component="div"
          >
            Map View
          </Typography>
          <MapWithADirectionsRenderer
            origin={kitchenOrigin}
            dest={destinationPlaceId}
            deliveryDistance={showComputedDistance}
            deliveryTime={showComputedTime}
          />
        </Container>
        <Container
          className={classes.textFieldWrapper}
          disableGutters={true}
          sx={{ mb: "10vh" }}
          id="wrapperContainer"
          maxWidth="sm"
        >
          {formik.isSubmitting === false ? (
            <>
              <Button
                // type="submit"
                fullWidth={true}
                // className={{classes.}}
                className={classes.submitButton}
                onClick={(event) => {
                  // setSubmiting(true);
                  formik.handleSubmit();
                  // console.log(formik.errors);
                }}
                variant="contained"
                size="large"
                sx={{ mt: "3vh", mb: "5vh" }}
                disabled={formik.isValid ? false : true}
              >
                Submit
              </Button>
              {!formik.isValid ? (
                <Typography
                  className={classes.mapViewText}
                  variant="body2"
                  component="div"
                  sx={{ textAlign: "center" }}
                >
                  {formik.isValid ? "Looking Good!" : "Pls check order fields"}
                </Typography>
              ) : null}
            </>
          ) : (
            <CircularProgress
              alignContent="center"
              alignItems="center"
              sx={{ mt: 3, mb: 2 }}
            />
          )}
        </Container>
      </form>
    </Container>
  );
}


export default BatchedOrder;
