import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  transportationContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: '0.475rem'

  },
  iconContainer: {
    order: 0

  },
  transportText: {
    order: 1
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '0.5em',
    padding: 0
  },
  labelIcon: {
    order: 0,
  },
  labelText: {
    order: 1,
  },
});

export default useStyles;