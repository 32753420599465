import fetch from "../utils/interceptor";
import { config } from "../config";
import login from "../utils/login";

const apiService = {
  signIn: async (data) => {
    // console.log(process.env.REACT_APP_BASE_URL);`${process.env.REACT_APP_BASE_UR}auth/kitchen/login`
    // fetch(`${process.env.REACT_APP_BASE_URL}auth/kitchen/login`, {
    //     method: 'POST',
    //     headers: new Headers({ 'Content-Type': 'application/json' }),
    //     body: JSON.stringify(data),
    //   })
    //     .then((response) => {return response;})
    //     .catch((error) => {
    //         console.log('error', error);
    //         return error
    //     });

    let resp;
    try {
        resp = await fetch(
            `${process.env.REACT_APP_BASE_URL}/auth/kitchen/login`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            //   mode: "no-cors",
            }
          );
          return resp;
    } catch (error) {
        console.log("error", error);
        return error;
    }

    // return resp;
  },
  getQueue: async () => {
    let resp;
    let kitchenId = login.getKitchenId();
    try {
        resp = await fetch(
            `${process.env.REACT_APP_BASE_URL}/checkin/queue?kitchenId=${kitchenId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            //   mode: "no-cors",
            }
          );
          return resp;
    } catch (error) {
        console.log("error", error);
        return error;
    }
  },
  submit: async (data) => {
    let resp;
    console.log("data is:", data);
    try {
        resp = await fetch(
            `${process.env.REACT_APP_BASE_URL}/dispatch/submit`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            //   mode: "no-cors",
            }
          );
          return resp;
    } catch (error) {
        console.log("error", error);
        return error;
    }
  },
  batchSubmit: async (data) => {
    let resp;
    console.log("data is:", data);
    try {
        resp = await fetch(
            `${process.env.REACT_APP_BASE_URL}/dispatch/batched/submit`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            //   mode: "no-cors",
            }
          );
          return resp;
    } catch (error) {
        console.log("error", error);
        return error;
    }
  },
  logoutRequest: async () => {
    let resp;
    try {
        resp = await fetch(
            `${process.env.REACT_APP_BASE_URL}/auth/logout`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
            //   mode: "no-cors",
            }
          );
          return resp;
    } catch (error) {
        console.log("logoutRequest Error:", error);
        return error;
    }
  },
  getKitchenDetails: async () => {
    let resp;
    let kitchenId = login.getKitchenId();
    try {
        resp = await fetch(
            `${process.env.REACT_APP_BASE_URL}/kitchen?kitchenId=${kitchenId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            //   mode: "no-cors",
            }
          );
          return resp;
    } catch (error) {
        console.log("Kitchen Request Error:", error);
        return error;
    }
  },
  getBrands: async () => {
    let resp;
    try {
        resp = await fetch(
            `${process.env.REACT_APP_BASE_URL}/brands/all`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            //   mode: "no-cors",
            }
          );
          return resp;
    } catch (error) {
        console.log("Brands request error:", error);
        return error;
    }
  },
  getRiders: async (props) => {
    let resp, fetchUrl;
    let {checkedIn,online, offset, limit, useKitchenId} = props;
    if (useKitchenId) {
      let kitchenId = login.getKitchenId();
      fetchUrl=`${process.env.REACT_APP_BASE_URL}/rider/search?checkedIn=${checkedIn}&online=${online}&kitchenId=${kitchenId}&offset=${offset}&limit=${limit}`;
    } else {
      fetchUrl= `${process.env.REACT_APP_BASE_URL}/rider/search?checkedIn=${checkedIn}&online=${online}&offset=${offset}&limit=${limit}`;
    }
    try {
        resp = await fetch(
            fetchUrl,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            //   mode: "no-cors",
            }
          );
          return resp;
    } catch (error) {
        console.log("Brands request error:", error);
        return error;
    }
  }, 
  getKitchenUserDetails: async () => {
    let resp;
    let kitchenUserId = login.getKitchenUserId();
    try {
        resp = await fetch(
            `${process.env.REACT_APP_BASE_URL}/kitchen/user?kitchenUserId=${kitchenUserId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            //   mode: "no-cors",
            }
          );
          return resp;
    } catch (error) {
        console.log("Kitchen Request Error:", error);
        return error;
    }
  },
  reassignOrder: async (data) => {
    let resp;
    console.log("data is:", data);
    try {
        resp = await fetch(
            `${process.env.REACT_APP_BASE_URL}/dispatch/reassign`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            //   mode: "no-cors",
            }
          );
          return resp;
    } catch (error) {
        console.log("error", error);
        return error;
    }
  },
  sendNotificationMessage: async (data) => {
    let resp;
    console.log("data is:", data);
    try {
        resp = await fetch(
            `${process.env.REACT_APP_BASE_URL}/notification/add`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            //   mode: "no-cors",
            }
          );
          return resp;
    } catch (error) {
        console.log("error", error);
        return error;
    }
  }

};

export default apiService;
