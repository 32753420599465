import { makeStyles } from "@material-ui/styles";
import * as colors from "../../constants/colors";
import theme from "../../theme";

const useStyles = makeStyles({
    radioButton: {
        backgroundColor: colors.blue_400
    },
    dialogTitle: {
        backgroundColor: colors.blackLighter,
        color: colors.white,
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '0.5em',
        padding: 0
    },
    labelIcon: {
        order: 0,
    },
    labelText: {
        order: 1,

    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    reassignButton: {
        backgroundColor: colors.black,
        color: colors.white,
        borderRadius: 40,
        textTransform: 'none'
    },
    '.MuiButton-root:hover': {
        backgroundColor: `${colors.blackLighter}!important`
    }

});

export default useStyles;
