export const orderSources = {
  Organic: {
    value: 'ORGANIC',
    label: 'Organic'
  },
  Uber: {
    value: "UBER",
    label: "Uber"
  },
  Glovo: {
    value: 'GLOVO',
    label: 'Glovo'
  },
  Staff_DropOff: {
    value: 'STAFF_DROPOFF',
    label: "Staff Dropoff"
  },
  Pickups: {
    value: 'PICKUPS',
    label: "Pickups"
  },
  // Dilli: "Dilli",
  // Khans: "Khan's",
  // Other: ""
};