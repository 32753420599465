import {
  AppBar,
  Badge,
  Box,
  Button,
  Collapse,
  colors,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { useFormik } from "formik";
import useStyles from "./styles";
import theme from "../theme";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import validationSchema from "./validation";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { ThemeProvider, withStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import {
  antiqueWhite,
  black,
  red_300,
  royalBlue,
  white,
} from "../constants/colors";
import { KeyboardTimePicker, TimePicker } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import moment, { duration } from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { deliveryLocations } from "./deliveryLocations";
import MapView from "./MapView";
import { MapWithADirectionsRenderer } from "../withDirectionsRenderer";
import apiService from "../api";
import { useInterval } from "../utils/useInterval";
import { onMessageListener } from "../firebase";
import login from "../utils/login";
import { useHistory } from 'react-router-dom';
import Reassign from "../components/Reassign";
import DriveEtaRoundedIcon from '@material-ui/icons/DriveEtaRounded';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import DirectionsCarRoundedIcon from '@mui/icons-material/DirectionsCarRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import {io} from 'socket.io-client';
import Pickup from "../Pickup";
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import MenuIcon from '@material-ui/icons/Menu';
import Condition from "yup/lib/Condition";

const start = async () => {
  var client = io.connect(`${process.env.REACT_APP_BASE_URL}`, {
      extraHeaders: {
          authorization: login.getToken()
      }
  });

  client.emit('ping', 'pong');

  client.on('/time', (data) => {
      console.info(data);
  });

  client.on(`/kitchen/${login.getKitchenId()}/queue`, (data) => {
      console.info(data);
  });

  client.on('connect_error', (err) => {
      console.log(err);
  });
};



const UPDATE_INTERVAL = 4000;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: black,
    color: white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const itemStyles = {
  normal: {
    backgroundColor: white,
  },
  new: {
    backgroundColor: antiqueWhite,
  },
};

function formatQueueRiders(name, time, mode, postionInQueue, id) {
  // console.log(`id`, id)
  let checkInTime = new Date(time).toLocaleTimeString("en-US");
  // console.log("Checkin Time is:", checkInTime);
  let number;
  if (postionInQueue === 1) {
    number = "Up Next";
  } else {
    number = "In Line";
  }
  let modeOfTransportation = mode;
  let output = { name, checkInTime, modeOfTransportation, number, id: id };
  // console.log(`Output:`, JSON.stringify(output))
  return { name, checkInTime, modeOfTransportation, number, id: id };
}
const getDuration = (start, end) => { };

const orderSources = {
  Organic: {
    value: 'ORGANIC',
    label: 'Organic'
  },
  Uber: {
    value: "UBER",
    label: "Uber"
  },
  Glovo: {
    value: 'GLOVO',
    label: 'Glovo'
  },
  Staff_DropOff: {
    value: 'STAFF_DROPOFF',
    label: "Staff Dropoff"
  },
  Pickups: {
    value: 'PICKUPS',
    label: "Pickups"
  },
  // Dilli: "Dilli",
  // Khans: "Khan's",
  // Other: ""
};
const restaurants = {
  Khans: "Khan's",
  Dilli: "DILLI!",
  Superwings: "Superwings",
  NA: "Not Applicable",
}
// ---------------------AutoComplete Location section-------------------
function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const center = { lat: -1.26606, lng: 36.80394 }; // This is set to Purvi House
// Create a bounding box with sides ~10km away from the center point
const defaultBounds = {
  north: center.lat + 0.5,
  south: center.lat - 0.5,
  east: center.lng + 0.5,
  west: center.lng - 0.5,
};

const autocompleteService = { current: null };
const geocoder = { current: null };

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://andofoods.co/">
        AndoFoods
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function Dispatch() {
  const classes = useStyles();
  const history = useHistory();

  const [numberOfRiders, setNumberOfRiders] = useState(4);
  const [showNewRider, setShowNewRider] = useState(true);
  const [selectedOrderSource, setSelectedOrderSource] = useState(orderSources.Organic.value);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("Paid");
  const [enableRiderName, setEnableRiderName] = useState(false);
  const [destinationPlaceId, setDestinationPlaceId] = useState("");
  const [rows, setRows] = useState([]);
  const [riderNames, setRiderNames] = useState([]);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [severity, setSeverity] = useState("error");
  const [customerLocationCoordinates, setCustomerLocationCoordinates] =
    useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = React.useState(null);
  const profileMenuOpen = Boolean(profileMenuAnchorEl);
  const [notifications, setNotifications] = useState([]);
  const [haveNewNotification, setHaveNewNotification] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);
  const [snackMessage, setSnackMessage] = useState("");
  const [kitchenDetails, setKitchenDetails] = useState({})
  const [kitchenUserDetails, setKitchenUserDetails] = useState({});
  const [kitchenOrigin, setKitchenOrigin] = useState(login.getLocalKitchenDetails());
  const [brands, setBrands] = useState([]);
  const [customerNameFieldText, setCustomerNameFieldText] = useState("Customer Name");
  const [customerNameFieldHelperText, setCustomerNameFieldHelperText] = useState("");
  const [showPickupForm, setShowPickupForm] = useState(false);
  const [socket, setSocket] = useState(null);

  const signOut = async () => {
    let sendSignOutRequest = await login.logOut();
    if (sendSignOutRequest) {
      history.push("/");
      // window.location.reload({ forcedReload: true });
    } else {
      setError("Something went wrong signing out");
      setShowError(true);
    }
  };


  

  // Get brands
  useEffect(() => {
    let fetchBrands;
    async function getKitchenBrands() {
      fetchBrands = await apiService.getBrands();
      fetchBrands = await fetchBrands.json();
      // await sleep(100e3); // For demo purposes.
      setBrands(fetchBrands);
    };
    getKitchenBrands();

  }, []);

  useEffect(() => {
    let kitchenDetails = apiService.getKitchenDetails();
    let kitchenUserDetails = apiService.getKitchenUserDetails();
    async function getKitchenDetails() {
      kitchenDetails = await kitchenDetails;
      kitchenUserDetails = await kitchenUserDetails;
      kitchenUserDetails = await kitchenUserDetails.json();
      kitchenDetails = await kitchenDetails.json()
      console.log("Details are", kitchenDetails)
      setKitchenDetails(kitchenDetails);
      setKitchenOrigin({
        lat: kitchenDetails.lat,
        lng: kitchenDetails.lng
      });
      login.setKitchenDetails(kitchenDetails);
      setKitchenUserDetails(kitchenUserDetails);
      console.log(kitchenUserDetails);
    }
    getKitchenDetails();

  }, [])

  const handleSnack = (Transition) => () => {
    setTransition(() => Transition);
    setOpenSnack(true);
  };

  const tumiaSnack = (message) => {
    setOpenSnack(true);
    setSnackMessage(message);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = (event) => {
    setProfileMenuAnchorEl(null);
  };
  const renderNotificationMenu = (
    <Menu
      className={classes.notificationMenu}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{
        marginTop: 64,
      }}
      open={open}
      onClose={() => {
        setHaveNewNotification(false);
        handleClose();
      }}
    >
      {notifications.length >= 1 ? (
        notifications.map((notification) => {
          let now = moment(new Date());
          let isNew = notification.isNew;
          return (
            <MenuItem
              style={{
                whiteSpace: "normal",
                margin: 0,
                ...(isNew && haveNewNotification
                  ? itemStyles.new
                  : itemStyles.normal),
              }}
              onClick={handleClose}
            >
              <Container
                maxWidth="lg"
                className={classes.menuItemContainer}
                disableGutters={true}
                style={{
                  width: "auto",
                  minWidth: 476,
                  minHeight: "7vh",
                }}
              >
                <Container
                  maxWidth="lg"
                  className={classes.menuItemTextContainer}
                  disableGutters={true}
                  style={{
                    width: "100%",
                  }}
                >
                  <Container
                    maxWidth="md"
                    className={classes.menuItemBodyContainer}
                    disableGutters={true}
                    style={{
                      // width: "auto",
                      maxWidth: "60%",
                      width: "20em",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {notification.isNew && (
                      <span
                        style={{
                          height: 7,
                          width: 7,
                          backgroundColor: red_300,
                          borderRadius: "50%",
                          display: "flow",
                          marginRight: 4,
                        }}
                      />
                    )}
                    <Typography
                      style={{
                        wordWrap: "break-word",
                        width: "90%",
                      }}
                      display="inline"
                      component="p"
                      variant="body1"
                      className={classes.notificationText}
                      noWrap={false}
                    >
                      {notification.body}
                    </Typography>
                  </Container>
                  <Container
                    maxWidth="md"
                    className={classes.menuItemDurationContainer}
                    disableGutters={true}
                    style={{
                      width: "auto",
                      height: "100%",
                    }}
                  >
                    <Typography
                      component="p"
                      variant="subtitle2"
                      className={classes.notificationDurationText}
                    >
                      {moment
                        .duration(-now.diff(notification.addedAt))
                        .humanize(true)}
                    </Typography>
                  </Container>
                </Container>
              </Container>
            </MenuItem>
          );
        })
      ) : (
        <MenuItem
          style={{
            whiteSpace: "normal",
            margin: 0,
          }}
          onClick={() => {
            setManualReassignOpen(true);
            // handleClose()
          }}
        >
          <Container
            maxWidth="lg"
            className={classes.menuItemContainer}
            disableGutters={true}
            style={{
              width: "auto",
              minWidth: 476,
              minHeight: "7vh",
            }}
          >
            <Container
              maxWidth="lg"
              className={classes.menuItemTextContainer}
              disableGutters={true}
              style={{
                width: "100%",
              }}
            >
              <Container
                maxWidth="md"
                className={classes.menuItemBodyContainer}
                disableGutters={true}
                style={{
                  // width: "auto",
                  maxWidth: "60%",
                  width: "20em",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography
                  style={{
                    wordWrap: "break-word",
                    width: "90%",
                  }}
                  display="inline"
                  component="p"
                  variant="body1"
                  className={classes.notificationText}
                  noWrap={false}
                >
                  No new notifications
                </Typography>
              </Container>
              <Container
                maxWidth="md"
                className={classes.menuItemDurationContainer}
                disableGutters={true}
                style={{
                  width: "auto",
                  height: "100%",
                }}
              >
                <Typography
                  component="p"
                  variant="subtitle2"
                  className={classes.notificationDurationText}
                >
                  Now
                </Typography>
              </Container>
            </Container>
          </Container>
        </MenuItem>
      )}
      {/* <MenuItem onClick={handleClose}>
      <Container
        maxWidth="lg"
        className={classes.menuItemContainer}
        disableGutters={true}
        style={{
          width: "auto",
          minWidth: 476,
          minHeight: "7vh",
        }}
      >
        <Container
          maxWidth="lg"
          className={classes.menuItemTextContainer}
          disableGutters={true}
          style={{
            width: "100%",
          }}
        >

            <Container
              maxWidth="md"
              className={classes.menuItemBodyContainer}
              disableGutters={true}
              style={{
                // width: "auto",
                maxWidth: "60%",
              }}
            >
              <Typography
                component="p"
                variant="body1"
                className={classes.notificationText}
              >
                A rider declined your request
              </Typography>
            </Container>
          <Container
            maxWidth="md"
            className={classes.menuItemDurationContainer}
            disableGutters={true}
            style={{
              width: "auto",
              height: "100%",
            }}
          >
            <Typography
              component="p"
              variant="subtitle2"
              className={classes.notificationText}
            >
              5m ago
            </Typography>
          </Container>
        </Container>
      </Container>
    </MenuItem> */}
      {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
    </Menu>
  );
  const profileMenuId = "profileMenu";
  const renderProfileMenu = (
    <Menu
      className={classes.profileMenu}
      anchorEl={profileMenuAnchorEl}
      id={profileMenuId}
      keepMounted
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{
        marginTop: 64,
      }}
      // elevation={0}
      open={profileMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <MenuItem
        // disableGutters={true}
        style={{
          whiteSpace: "normal",
          margin: 0,
          backgroundColor: white,
          boxShadow: "none",
          padding: 0,
        }}
        className={classes.profileMenuItemStyle}
        classes={{
          root: {
            "&:hover": {
              //you want this to be the same as the backgroundColor above
              backgroundColor: "#FFF",
            },
          },
          gutters: {
            "&:hover": {
              //you want this to be the same as the backgroundColor above
              backgroundColor: "#FFF",
            },
          },
        }}
        onClick={() => {
          console.log("Clicked user profile");
        }}
      >
        <Container
          maxWidth="lg"
          className={classes.profileItemContainer}
          // disableGutters={true}
          style={{
            width: "auto",
            minWidth: 279,
            minHeight: "7vh",
          }}
        >
          <Container
            maxWidth="lg"
            className={classes.profileItemTextContainer}
            // disableGutters={true}
            style={{
              width: "100%",
            }}
          >
            <Container
              maxWidth="md"
              className={classes.userProfileContainer}
              disableGutters={true}
              style={{
                // width: "auto",
                // maxWidth: "60%",
                // width: "20em",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography
                style={{
                  wordWrap: "break-word",
                  // marginLeft: "2vh",
                  order: 0,
                  marginBottom: '0.5em',
                  // width: "90%",
                }}
                display="inline"
                component="p"
                variant="body1"
                className={classes.userNameText}
                noWrap={false}
                onClick={() => {
                  console.log("User name clicked")
                }}
              >
                {kitchenUserDetails.username}
              </Typography>
              <Typography
                style={{
                  wordWrap: "break-word",
                  // marginLeft: "2vh",
                  order: 1,
                  // width: "90%",
                }}
                display="inline"
                component="p"
                variant="body2"
                className={classes.userNameText}
                noWrap={false}
                onClick={() => {
                  console.log("User name clicked")
                }}
              >
                {kitchenUserDetails.name}
              </Typography>
              <Typography
                style={{
                  wordWrap: "break-word",
                  // marginLeft: "2vh",
                  order: 2,
                  // width: "90%",
                }}
                display="inline"
                component="p"
                variant="body2"
                className={classes.userNameText}
                noWrap={false}
                onClick={() => {
                  console.log("User name clicked")
                }}
              >
                {kitchenUserDetails.address}
              </Typography>
            </Container>
            {/* <Container
              maxWidth="md"
              className={classes.menuItemDurationContainer}
              disableGutters={true}
              style={{
                width: "auto",
                height: "100%",
              }}
            >
              <Typography
                component="p"
                variant="subtitle2"
                className={classes.notificationDurationText}
              >
                Now
              </Typography>
            </Container> */}
          </Container>
        </Container>
      </MenuItem>
      <MenuItem
        // disableGutters={true}
        style={{
          whiteSpace: "normal",
          margin: 0,
          // backgroundColor: "rgba(61, 89, 130, 0.1)",
          backgroundColor: white,
          boxShadow: "none",
          padding: 0,
        }}
        className={classes.profileMenuItemStyle}
        classes={{
          root: {
            "&:hover": {
              //you want this to be the same as the backgroundColor above
              backgroundColor: "#FFF",
            },
          },
          gutters: {
            "&:hover": {
              //you want this to be the same as the backgroundColor above
              backgroundColor: "#FFF",
            },
          },
        }}
        onClick={signOut}
      >
        <Container
          maxWidth="lg"
          className={classes.profileItemContainer}
          disableGutters={true}
          style={{
            width: "auto",
            minWidth: 279,
            minHeight: "7vh",
          }}
        >
          <Container
            maxWidth="lg"
            className={classes.profileItemTextContainer}
            disableGutters={true}
            style={{
              width: "100%",
            }}
          >
            <Container
              maxWidth="md"
              className={classes.profileItemBodyContainer}
              disableGutters={true}
              style={{
                // width: "auto",
                // maxWidth: "60%",
                // width: "20em",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography
                style={{
                  wordWrap: "break-word",
                  marginLeft: "3rem",
                  // width: "90%",
                }}
                display="inline"
                component="p"
                variant="body1"
                className={classes.logoutText}
                noWrap={false}
                onClick={signOut}
              >
                Log Out
              </Typography>
            </Container>
            {/* <Container
              maxWidth="md"
              className={classes.menuItemDurationContainer}
              disableGutters={true}
              style={{
                width: "auto",
                height: "100%",
              }}
            >
              <Typography
                component="p"
                variant="subtitle2"
                className={classes.notificationDurationText}
              >
                Now
              </Typography>
            </Container> */}
          </Container>
        </Container>
      </MenuItem>
    </Menu>
  );
  // const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [dropDownAnchorEl, setDropDownAnchorEl] = useState(null);
  const isDropDownOpen = Boolean(dropDownAnchorEl);
  const handleDropDownMenuOpen = (event) => {
    setDropDownAnchorEl(event.currentTarget);
    // setIsDropDownOpen()
  };
  const handleDropDownClose = (event) => {
    setDropDownAnchorEl(null);
  };
  const renderDropDown = (
    <Menu
      anchorEl={dropDownAnchorEl}
      // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={"Drop Down Menu"}
      keepMounted
      // transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{
        marginTop: 64,
      }}
      open={isDropDownOpen}
      onClose={handleDropDownClose}
    >
      <MenuItem onClick={() => {
        setShowPickupForm(false);
        handleDropDownClose();
      }}>
      <Container
              className={classes.labelContainer}
              disableGutters={true}
              fullWidth={true}
            >
              <PedalBikeIcon className={classes.labelIcon} />
              <Typography
                className={`${classes.labelText}`}
                variant="body2"
                component="div"
              >
                Dispatch Ops
              </Typography>
            </Container>
      </MenuItem>
      <MenuItem onClick={() => {
        setShowPickupForm(true);
        handleDropDownClose();
      }}>
      <Container
              className={classes.labelContainer}
              disableGutters={true}
              fullWidth={true}
            >
              <DirectionsWalkIcon className={classes.labelIcon} />
              <Typography
                className={`${classes.labelText}`}
                variant="body2"
                component="div"
              >
                Pickups
              </Typography>
            </Container>
      </MenuItem>
    </Menu>
  )
  const renderModeOfTransport = (mode) => {
    switch (mode) {
      case "MOTORCYCLE":
        return (
          <Container
            className={classes.transportationContainer}
            disableGutters={true}
          >
            <DeliveryDiningIcon className={classes.iconContainer} />
            <Typography
              className={`${classes.transportText}`}
              variant="body2"
              component="div"
            >
              Motorcycle
            </Typography>
          </Container>
        );
      case "PEDAL BIKE":
        return (
          <Container
            className={classes.transportationContainer}
            disableGutters={true}
          >
            <PedalBikeIcon className={classes.iconContainer} />
            <Typography
              className={`${classes.transportText}`}
              variant="body2"
              component="div"
            >
              Pedal Bike
            </Typography>
          </Container>
        );
      case "CAR":
        return (
          <Container
            className={classes.transportationContainer}
            disableGutters={true}
          >
            <DirectionsCarRoundedIcon className={classes.iconContainer} />
            <Typography
              className={`${classes.transportText}`}
              variant="body2"
              component="div"
            >
              Car
            </Typography>
          </Container>
        );
      default:
        return (
          <Container
            className={classes.transportationContainer}
            disableGutters={true}
          >
            <DeliveryDiningIcon className={classes.iconContainer} />
            <Typography
              className={`${classes.transportText}`}
              variant="body2"
              component="div"
            >
              {mode}
            </Typography>
          </Container>
        );
    }
  };
  // Manage the manual reassignment state
  const [manualReassignOpen, setManualReassignOpen] = useState(false);
  const [reassignedRiderValue, setReassignedRiderValue] = useState('');

  const handleClickReassignOrder = () => {
    setManualReassignOpen(true);
  };

  const handleReassignDialogClose = (newValue) => {
    setManualReassignOpen(false);

    if (newValue) {
      setReassignedRiderValue(newValue);
    }
  };
  onMessageListener()
    .then((payload) => {
      // setShow(true);
      // setNotification({title: payload.notification.title, body: payload.notification.body})
      let currentNotifications = notifications;
      var now = moment(new Date());
      currentNotifications.forEach((value, index, array) => {
        value.isNew = false;
        let prevDuration = value["duration"];
        value["duration"] = moment
          .duration(-now.diff(prevDuration))
          .humanize(true);
      });
      currentNotifications.unshift({
        title: payload.notification.title,
        body: payload.notification.body,
        addedAt: moment(new Date()),
        isNew: true,
        duration: moment.duration(-now.diff(now)).humanize(true),
      });
      setNotifications(currentNotifications);
      setHaveNewNotification(true);
      tumiaSnack("A rider declined your order request.");
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

    async function getOnlineRiders(getRidersConfig) {
     // Get the online riders and the riders in the queue 
     let onlineRiders = await apiService.getRiders(getRidersConfig);
     onlineRiders = await onlineRiders.json();

     onlineRiders = onlineRiders.map((rider) => {
       return {
         label: rider.name,
         riderId: rider.riderId
       };
     });
     return onlineRiders; 
    }

 

  const orderTime = useRef(null);

  const formik = useFormik({
    initialValues: {
      orderId: undefined,
      orderAcceptedTime: new Date(),
      orderSource: orderSources.Organic.value,
      brandId: "1",
      paymentStatus: "Paid",
      customerName: "",
      customerNumber: "",
      customerAddress: null,
      CBD: "false",
      deliveryTime: "",
      deliveryDistance: "",
      riderName: null,
      riderId: "",
    },
    validationSchema: validationSchema,
    // validateOnBlur: true,
    onSubmit: async (values) => {
      console.log("Submitting");
      formik.setSubmitting(true);
      // Swap the order source value in values for selectedOrder
      let submissionValues = values;

      submissionValues["orderSource"] = selectedOrderSource;

      // Swap the payment status

      submissionValues["paymentStatus"] = selectedPaymentStatus;

      // Add the order amount field selectedPaymentStatus
      if (submissionValues.paymentStatus === "Paid") {
        submissionValues["orderAmount"] = "0";
        submissionValues["paymentStatus"] = "PAID";
      } else {
        submissionValues["orderAmount"] = submissionValues["paymentStatus"];
        submissionValues["paymentStatus"] = "UNPAID";
      }
      // Handle Staff drop off and pickups
      if (submissionValues.orderSource === orderSources.Staff_DropOff.value || submissionValues.orderSource === orderSources.Pickups.value) {
        delete submissionValues.brandId
      } else {
        // do nothing
      }
      // Handle undefined distances
      if(submissionValues.deliveryDistance === "No route found" || submissionValues.deliveryTime === "No route found") {
        formik.setFieldError("deliveryDistance", "Distance is not defined");
        formik.setFieldError("deliveryTime", "Time is not defined");
        formik.setSubmitting(false);
        return;
      }

      // Remove the first zero in the phone number
      if (submissionValues["customerNumber"].length === 10) {
        submissionValues["customerNumber"] = submissionValues["customerNumber"].substring(1);
      } else {
        // Do nothing
      }

      // Add the kitchen id
      let kitchenId = login.getKitchenId();
      submissionValues["kitchenId"] = kitchenId;
      // Add the customer location
      console.log("customer coords:", customerLocationCoordinates);
      submissionValues["customerLat"] =
        customerLocationCoordinates["lat"].toString();
      submissionValues["customerLng"] =
        customerLocationCoordinates["lng"].toString();

        //  Send submission
      let submissionResponse = await apiService.submit(submissionValues);
      console.log(submissionResponse);
      if (submissionResponse.status === 200) {
        let body = await submissionResponse.json();
        console.log("Success, data is:", body);
        setSeverity("success");
        setError("Successfully Dispatched!");
        setShowError(true);
        window.location.reload({ forcedReload: true });
      } else {
        let body = await submissionResponse.json();
        console.log("Failed, data is:", body);
        formik.setSubmitting(false);
        setError("Something went wrong");
        setShowError(true);
      }
      // Await response

      // setValidPhoneNumberAndName(true)
      // alert(JSON.stringify(values, null, 2));
    },
  });
  // console.log(formik.values)
  console.log("formik errors", formik.errors);
  //----------------Location Autocomplete-----------
  const [customerAddressValue, setCustomerAddressValue] = React.useState(null);
  const [customerAddressInputValue, setCustomerAddressInputValue] =
    React.useState("");
  const [customerAddressOptions, setCustomerAddressOptions] = React.useState(
    []
  );
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GCLOUD_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 1500),
    []
  );
  // console.log(formik.errors);

  const showComputedDistance = (value) => {
    if ((value !== null) && (value !== undefined) && (value !== "") && (!isNaN(value)) && (value !== false)) {
      formik.setFieldValue("deliveryDistance", value);
      // setDeliveryDistance(value);
    } else if((value === "No route found")) {
      formik.setFieldValue("deliveryDistance", "No route found");
      // formik.setErrors({deliveryDistance: "No route found"});
    } 
    else {
      console.log("GMaps Says distance is", value);
    }
  };

  const showComputedTime = (value) => {
    if ((value !== null) && (value !== undefined) && (value !== "") && (!isNaN(value)) && (value !== false)) {
      formik.setFieldValue("deliveryTime", value);
      // setDeliveryTime(value);
    } else if((value === "No route found")) {
      formik.setFieldValue("deliveryTime", "No route found");
      // formik.setErrors({deliveryTime: "No route found"});
      formik.setFieldError("deliveryTime", "No route found");
    }  
    else {
      console.log("GMaps Says distance is", value);
    }
  };

  const handleDestinationPlaceId = (value) => {
    if (value) {
      setDestinationPlaceId(value.place_id);
    } else {
      // Do nothing
      console.log("PlaceId is", value);
    }
  };

  // -----------------Location UseEffect------------------------
  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      geocoder.current = new window.google.maps.Geocoder();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (customerAddressInputValue === "") {
      setCustomerAddressOptions(
        formik.values.customerAddress ? [formik.values.customerAddress] : []
      );
      return undefined;
    }

    fetch(
      { input: customerAddressInputValue, bounds: defaultBounds, componentRestrictions: {country: "ke"} },
      (results) => {
        if (active) {
          let newOptions = [];

          if (formik.values.customerAddress) {
            newOptions = [formik.values.customerAddress];
            // setDestinationPlaceId(customerAddressValue.placeId);
            console.log(formik.values.customerAddress);
            console.log(formik.values.customerAddress.place_id);
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setCustomerAddressOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [formik.values.customerAddress, customerAddressInputValue, fetch]);

  const handleCustomerNumber = () => {
    switch (formik.values.orderSource) {
      case orderSources.Uber.value:
        return true;
      case orderSources.Staff_DropOff.value:
        return false;
      case orderSources.Pickups.value:
        return false;
      default:
        return true;
    }
  };
  const handlePaymentStatusView = () => {
    switch (formik.values.orderSource) {
      case orderSources.Staff_DropOff.value:
        return false;
      case orderSources.Pickups.value:
        return false;

      default:
        return true;
    }
  };

  const handleRestaurantsView = () => {
    switch (formik.values.orderSource) {
      case orderSources.Staff_DropOff.value:
        return false;
      case orderSources.Pickups.value:
        return false;

      default:
        return true;
    }
  }

  const showNotification = (severity, text) => {
    setSeverity(severity);
    setShowError(true)
    setError(text);
  };

  useEffect(() => {
    switch (formik.values.orderSource) {
      case orderSources.Staff_DropOff.value:
        setCustomerNameFieldText("Ando Staff Name");
        setCustomerNameFieldHelperText("Enter the first name only");
        setEnableRiderName(true);
        break;
      case orderSources.Pickups.value:
        setCustomerNameFieldText("Store Name");
        setCustomerNameFieldHelperText("Enter the store name");
        setEnableRiderName(true);
        break;

      default:
        setCustomerNameFieldText("Customer Name");
        break;
    }
  }, [customerNameFieldHelperText, customerNameFieldText, formik.values, formik]);
  const orderSourceRef = useRef(formik.values.orderSource);
  React.useEffect(() => {
    // This effect executes on every render (no dependency array specified).
    // Any change to the "participants" state will trigger a re-render
    // which will then cause this effect to capture the current "participants"
    // value in "participantsRef.current".
    orderSourceRef.current = formik.values.orderSource;
});
    //  The socket implementation has 3 useEffect functions: 
    // 1. The initialization useEffect to start the socket connection and instance
    // 2. The main useEffect function with the event handlers
    // 3. The queue update useEffect to emit the event to request the queue. Should this be in the main useEffect? 
    // is there any value in having its own function?
      // A. Initialization useEffect
      useEffect(() => {
        const newSocket = io(`${process.env.REACT_APP_BASE_URL}`, {
          withCredentials: true,
          extraHeaders: {
            authorization: login.getToken(),
          },
        });
        
        setSocket(newSocket);
        return () => newSocket.close();
      }, [setSocket]);
      // B. A useEffect function for the socket events with the handlers in the useEffect function as well
        // The useEffect should handle: 
        // 1. Queue updates
        // 2. Notifications
        useEffect(() => {
          
          // const queueUpdateHandler = (queueArray) => {
          //   console.info("Socket queue", queueArray);
          // };
          const queueUpdateHandler = async (queueArray) => {
            console.info("Socket queue", queueArray);
            let getRidersConfig = {
              checkedIn: false,
              online: true,
              offset: 0,
              limit: 100,
              useKitchenId: false,
            };
            // let queueRiders = await apiService.getQueue();
            let queueRiders =  queueArray;
            let currentRows = [];
            let currentRidersInQueue = [];
        
            currentRows = queueRiders.map((rider, idx) => formatQueueRiders(
              rider.name,
              rider.createdAt,
              rider.modeOfTransport,
              idx + 1,
              rider.riderId
            ));
            currentRidersInQueue = queueRiders.map((rider) => {
              return {
                label: rider.name,
                riderId: rider.riderId
              };
            });
            // Changing the riders based on Staff Drop off or Pickups
            if (orderSourceRef.current === orderSources.Staff_DropOff.value || orderSourceRef.current === orderSources.Pickups.value) {
              // Get the online riders and the riders in the queue 
              let onlineRiders = await getOnlineRiders(getRidersConfig);
              currentRidersInQueue = currentRidersInQueue.concat(onlineRiders);
            } 
            setRows(currentRows);
            setRiderNames(currentRidersInQueue);
        
          }
          if(socket){
          socket.on(`/kitchen/${login.getKitchenId()}/queue`, queueUpdateHandler);
          socket.emit(`/queue`,{ "id": login.getKitchenId()});
          };
      
          return () => {
            if(socket){
            socket.off(`/kitchen/${login.getKitchenId()}/queue`, queueUpdateHandler);
            };
          };
        }, [socket, formik.values.orderSource]);
    
    const CBDOptions = [
      {
        name: "Yes",
        value: true,
      },
      {
        name: "No",
        value: false,
      },
    ];

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        // style={{
        //   backgroundColor: royalBlue,
        //   color: white,
        //   "& .MuiSnackbarContent-root": {
        //     backgroundColor: royalBlue,
        //   color: white,
        //   }
        // }}
        className={classes.snackBar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={openSnack}
        autoHideDuration={15000}
        onClose={handleCloseSnack}
        message={snackMessage}
        TransitionComponent={TransitionUp}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnack}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Container maxWidth="xl" className={classes.rootContainer} disableGutters>
        <AppBar className={classes.appBar} position="static">
          <Toolbar className={classes.toolbar}>
            {/* <Container 
              class
              sx={{  }}> */}
            <Link href="/">
              <img
                className={classes.logoAppbar}
                // sx={{ maxWidth: "inherit", maxHeight: "inherit" }}
                src="/ando-logo.svg"
                alt="ando logo"
                // layout="fixed"
              />
            </Link>
            {/* </Container> */}
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton
                aria-label="show new notifications"
                className={classes.notificationIcon}
                sx={{ mr: "1vw" }}
                onClick={handleMenu}
              >
                <Badge
                  invisible={!haveNewNotification}
                  variant="dot"
                  overlap="circular"
                  color={showNewRider ? "error" : "default"}
                  className={classes.badge}
                >
                  <NotificationsIcon className={classes.notificationIcon} />
                </Badge>
              </IconButton>
              {renderNotificationMenu}
              <Reassign
                classes={{
                  paper: classes.paper,
                }}
                id="ringtone-menu"
                keepMounted
                open={manualReassignOpen}
                onClose={handleReassignDialogClose}
                value={reassignedRiderValue}
              />
              <IconButton
                sx={{ mr: "1vw" }}
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                onClose={handleProfileMenuClose}
                className={classes.notificationIcon}
              >
                <AccountCircle className={classes.notificationIcon} />
              </IconButton>
              <IconButton
                sx={{ mr: "1vw" }}
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={handleDropDownMenuOpen}
                onClose={handleDropDownClose}
                className={classes.notificationIcon}
              >
                <MenuIcon className={classes.notificationIcon} />
              </IconButton>
              {renderProfileMenu}
              {renderDropDown}
            </div>
          </Toolbar>
        </AppBar>
        {showError && (
          <Collapse in={showError}>
            <Alert
              severity={severity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {error}
            </Alert>
          </Collapse>
        )}
        {showPickupForm ? (
          <Pickup showNotification={showNotification} />
        ) : (
          <Container className={classes.contentContainer} maxWidth="lg">
            <Container className={classes.headerContainer}>
              <Typography
                // m={1}
                // display="block"
                sx={{ mt: "3vh" }}
                component="h1"
                variant="h4"
                className={classes.headingText}
              >
                Dispatch Ops
              </Typography>
              <Typography
                // m={1}
                // display="block"
                sx={{ mt: "1.5vh" }}
                component="p"
                variant="body1"
                className={classes.requiredText}
              >
                *Required Field
              </Typography>
            </Container>
            <Container
              className={classes.formContainer}
              disableGutters={true}
              maxWidth="lg"
            >
              <form
                className={classes.formikForm}
                onSubmit={formik.handleSubmit}
              >
                <Container
                  className={`${classes.formikWrapper} "formikOuter"`}
                  id="wrapperContainer"
                  disableGutters={true}
                ></Container>
                <Container
                  className={classes.inputFields}
                  disableGutters={true}
                >
                  <Container
                    className={classes.textFieldWrapper}
                    disableGutters={true}
                    id="wrapperContainer"
                    maxWidth="sm"
                  >
                    <Typography
                      className={classes.textLabel}
                      variant="body2"
                      component="div"
                      maxWidth="sm"
                    >
                      Order Number
                    </Typography>
                    <TextField
                      className={`${classes.textEntryField} "txtInput"`}
                      sx={{ mt: "1vh" }}
                      fullWidth
                      id="orderId"
                      name="orderId"
                      // label="Order Number"
                      value={formik.values.orderId}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.orderId && Boolean(formik.errors.orderId)
                      }
                      helperText={
                        formik.touched.orderId && formik.errors.orderId
                      }
                      placeholder="Order Number"
                      variant="filled"
                      margin="normal"
                      inputProps={{
                        style: { backgroundColor: white, paddingBottom: "1em" },
                      }}
                      InputProps={{
                        className: classes.txtInput,
                        classes: {
                          root: classes.txtInput,
                        },
                      }}
                      size="small"
                    />
                  </Container>
                  <Container
                    className={classes.textFieldWrapper}
                    disableGutters={true}
                    id="wrapperContainer"
                    maxWidth="sm"
                  >
                    <Typography
                      className={`${classes.textLabel} required`}
                      variant="body2"
                      component="div"
                    >
                      Order Accepted Time
                    </Typography>
                    <KeyboardTimePicker
                      // label="Masked timepicker"
                      className={`${classes.timePicker} "txtInput"`}
                      sx={{ mt: "1vh" }}
                      // ref={orderTime}
                      ampm={true}
                      autoOk={false}
                      required={true}
                      variant="dialog"
                      name="orderAcceptedTime"
                      fullWidth
                      id="orderAcceptedTime"
                      key="orderAcceptedTime "
                      helperText={formik.errors.orderAcceptedTime}
                      // placeholder="08:00 AM"
                      mask="__:__ _M"
                      value={formik.values.orderAcceptedTime}
                      onError={(error) => {
                        // handle as a side effect
                        // console.log(error);
                        if (error !== formik.errors.orderAcceptedTime) {
                          formik.setFieldError("orderAcceptedTime", error);
                        } else if (error === "") {
                          // Empty string- do nothing
                        }
                      }}
                      onChange={(value) => {
                        console.log(value);
                        if (value != null) {
                          formik.setFieldValue(
                            "orderAcceptedTime",
                            value,
                            true
                          );
                        } else {
                          formik.setFieldValue("orderAcceptedTime", "", false);
                        }
                      }}
                      // onChange={formik.handleChange}
                      inputProps={{
                        style: { backgroundColor: white, paddingBottom: "1em" },
                      }}
                      keyboardIcon={<ScheduleIcon />}
                      size="small"
                    />
                  </Container>
                  <Container
                    className={classes.textFieldWrapper}
                    disableGutters={true}
                    id="wrapperContainer"
                    maxWidth="sm"
                  >
                    <Typography
                      className={`${classes.textLabel} required`}
                      variant="body2"
                      component="div"
                    >
                      Order Source
                    </Typography>
                    <FormControl component="fieldset" fullWidth={true}>
                      <RadioGroup
                        name="orderSource"
                        value={formik.values.orderSource.toString()}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "orderSource",
                            event.currentTarget.value
                          );
                        }}
                        fullWidth={true}
                        className={classes.radioButtonsRowView}
                      >
                        {Object.keys(orderSources).map((keyName, i) => (
                          <FormControlLabel
                            key={i}
                            fullWidth={true}
                            value={orderSources[keyName]["value"].toString()}
                            control={<Radio color="primary" />}
                            label={orderSources[keyName]["label"].toString()}
                            onChange={() => {
                              setSelectedOrderSource(
                                orderSources[keyName]["value"].toString()
                              );
                            }}
                          />
                        ))}
                        {/* <FormControlLabel
                        sx={{ display: "flex", width: 1 }}
                        // className={classes.radioText}
                        fullWidth={true}
                        value="Other"
                        control={<Radio color="primary" />}
                        label={
                          <TextField
                            className={classes.radioTextField}
                            fullWidth={true}
                            margin="normal"
                            placeholder="Other"
                            onChange={(event) => {
                              // formik.setFieldValue(
                              //   "orderSource",
                              //   event.currentTarget.value
                              // );
                              setSelectedOrderSource(event.target.value);
                            }}
                            onFocus={(event) => {
                              formik.setFieldValue("orderSource", "Other");
                              // console.log(event.target);
                              // setSelectedOrderSource(event.target.value);
                            }}
                            inputProps={{
                              style: {
                                backgroundColor: white,
                                paddingBottom: "1em",
                              },
                            }}
                            size="small"
                          />
                        }
                        onFocus={(event) => {
                          setSelectedOrderSource(event.target.value);
                        }}
                      /> */}
                      </RadioGroup>
                    </FormControl>
                  </Container>
                  {brands.length >= 1 && handlePaymentStatusView() ? (
                    <Container
                      className={classes.textFieldWrapper}
                      disableGutters={true}
                      id="wrapperContainer"
                      maxWidth="sm"
                    >
                      <Typography
                        className={`${classes.textLabel} required`}
                        variant="body2"
                        component="div"
                      >
                        Restaurants
                      </Typography>
                      <FormControl component="fieldset" fullWidth={true}>
                        <RadioGroup
                          name="restaurant"
                          value={formik.values.brandId.toString()}
                          onChange={(event) => {
                            formik.setFieldValue(
                              "brandId",
                              event.currentTarget.value
                            );
                          }}
                          fullWidth={true}
                          className={classes.radioButtonsRowView}
                        >
                          {brands.map((element, index, array) => (
                            <FormControlLabel
                              key={index}
                              fullWidth={true}
                              value={element["id"].toString()}
                              control={<Radio color="primary" />}
                              label={element["name"].toString()}
                              onChange={() => {
                                // formik.setFieldValue(
                                //   "restaurant",
                                //   element["name"].toString()
                                // );
                              }}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Container>
                  ) : (
                    <div />
                  )}
                  {handlePaymentStatusView() ? (
                    <Container
                      className={classes.textFieldWrapper}
                      disableGutters={true}
                      id="wrapperContainer"
                      maxWidth="sm"
                    >
                      <Typography
                        className={`${classes.textLabel} required`}
                        variant="body2"
                        component="div"
                      >
                        Payment Status
                      </Typography>
                      <FormControl component="fieldset" fullWidth={true}>
                        <RadioGroup
                          name="orderSource"
                          value={formik.values.paymentStatus.toString()}
                          onChange={(event) => {
                            formik.setFieldValue(
                              "paymentStatus",
                              event.currentTarget.value
                            );
                          }}
                          fullWidth={true}
                        >
                          <FormControlLabel
                            fullWidth={true}
                            value="Paid"
                            control={<Radio color="primary" />}
                            label="Paid"
                            onChange={() => {
                              setSelectedPaymentStatus("Paid");
                            }}
                            error={
                              formik.touched.paymentStatus &&
                              Boolean(formik.errors.paymentStatus)
                            }
                            helperText={
                              formik.touched.paymentStatus &&
                              formik.errors.paymentStatus
                            }
                          />
                          <FormControlLabel
                            sx={{
                              display: "flex",
                              width: "100%",
                              padding: "1.2em 1px 0.5em",
                            }}
                            // className={classes.radioText}
                            fullWidth={true}
                            value="Amount"
                            control={<Radio color="primary" />}
                            label={
                              <TextField
                                className={classes.radioTextField}
                                // type="number"
                                fullWidth={true}
                                margin="normal"
                                placeholder="Amount"
                                onChange={(event) => {
                                  // formik.setFieldValue(
                                  //   "orderSource",
                                  //   event.currentTarget.value
                                  // );
                                  setSelectedPaymentStatus(event.target.value);
                                }}
                                onFocus={(event) => {
                                  formik.setFieldValue(
                                    "paymentStatus",
                                    "Amount"
                                  );
                                  // console.log(event.target);
                                  // setSelectedOrderSource(event.target.value);
                                }}
                                inputProps={{
                                  style: {
                                    backgroundColor: white,
                                    paddingBottom: "1em",
                                  },
                                }}
                                size="small"
                              />
                            }
                            onFocus={(event) => {
                              setSelectedPaymentStatus(event.target.value);
                            }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Container>
                  ) : (
                    <div />
                  )}
                  <Container
                    className={classes.textFieldWrapper}
                    disableGutters={true}
                    id="wrapperContainer"
                    maxWidth="sm"
                  >
                    <Typography
                      className={`${classes.textLabel} required`}
                      variant="body2"
                      component="div"
                    >
                      {customerNameFieldText}
                    </Typography>
                    <TextField
                      className={`${classes.textEntryField} "txtInput"`}
                      sx={{ mt: "1vh" }}
                      fullWidth
                      id="customerName"
                      name="customerName"
                      // label="First Name"
                      value={formik.values.customerName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.customerName &&
                        Boolean(formik.errors.customerName)
                      }
                      helperText={
                        // () => {
                        //   if (formik.touched.customerName) {
                        //     return customerNameFieldHelperText;
                        //   } else if (
                        //     formik.touched.customerName &&
                        //     formik.errors.customerName
                        //   ) {
                        //     return `${formik.errors.customerName}`;
                        //   } else {
                        //   }
                        // }
                        // formik.touched.customerName && formik.errors.customerName
                        formik.touched.customerName
                          ? formik.errors.customerName
                            ? formik.errors.customerName
                            : customerNameFieldHelperText
                          : customerNameFieldHelperText
                      }
                      placeholder="First Name"
                      variant="filled"
                      margin="normal"
                      inputProps={{
                        style: {
                          backgroundColor: white,
                          paddingBottom: "1em",
                        },
                      }}
                      InputProps={{
                        className: classes.txtInput,
                        classes: {
                          root: classes.txtInput,
                        },
                      }}
                      size="small"
                      onBlur={formik.handleBlur}
                    />
                  </Container>
                  {handleCustomerNumber() ? (
                    <Container
                      className={classes.textFieldWrapper}
                      disableGutters={true}
                      id="wrapperContainer"
                      maxWidth="sm"
                    >
                      <Typography
                        className={`${classes.textLabel} ${
                          formik.values.orderSource === orderSources.Uber.value
                            ? ""
                            : "required"
                        }`}
                        variant="body2"
                        component="div"
                      >
                        Customer Number
                      </Typography>
                      <TextField
                        className={`${classes.textEntryField} "txtInput"`}
                        sx={{ mt: "1vh" }}
                        fullWidth
                        id="customerNumber"
                        name="customerNumber"
                        // label="First Name"
                        value={formik.values.customerNumber}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.customerNumber &&
                          Boolean(formik.errors.customerNumber)
                        }
                        helperText={
                          formik.touched.customerNumber &&
                          formik.errors.customerNumber
                            ? formik.errors.customerNumber
                            : ""
                        }
                        placeholder="0712 345 678"
                        variant="filled"
                        margin="normal"
                        inputProps={{
                          style: {
                            backgroundColor: white,
                            paddingBottom: "1em",
                          },
                        }}
                        InputProps={{
                          className: classes.txtInput,
                          classes: {
                            root: classes.txtInput,
                          },
                        }}
                        size="small"
                        onBlur={formik.handleBlur}
                      />
                    </Container>
                  ) : (
                    <Skeleton />
                  )}
                  <Container
                    className={classes.textFieldWrapper}
                    disableGutters={true}
                    id="wrapperContainer"
                    maxWidth="sm"
                  >
                    <Typography
                      className={`${classes.textLabel} required`}
                      variant="body2"
                      component="div"
                    >
                      Customer's Address
                    </Typography>
                    <Autocomplete
                      sx={{ backgroundColor: white }}
                      color="primary"
                      fullWidth={true}
                      name="customerAddress"
                      id="customerAddress"
                      getOptionLabel={(option) => {
                        if (
                          typeof option === "string" ||
                          typeof option === "number"
                        ) {
                          return option.toString();
                        } else {
                          return option.description;
                        }
                        // return ( typeof option === "string" ? option : option.description)
                      }}
                      getOptionSelected={(option, value) =>
                        option.description === value.description
                      }
                      filterOptions={(x) => x}
                      options={customerAddressOptions}
                      value={formik.values.customerAddress}
                      onChange={(event, newValue) => {
                        console.log("new Value is: ", JSON.stringify(newValue));
                        setCustomerAddressOptions(
                          newValue
                            ? [newValue, ...customerAddressOptions]
                            : customerAddressOptions
                        );
                        if (newValue) {
                          formik.setFieldValue(
                            "customerAddress",
                            newValue.description
                          );

                          geocoder.current.geocode(
                            { placeId: newValue.place_id },
                            (res) => {
                              console.log("res is:", JSON.stringify(res));
                              console.log(
                                "location is",
                                res[0].geometry.location.toJSON()
                              );
                              setCustomerLocationCoordinates(
                                res[0].geometry.location.toJSON()
                              );
                            }
                          );
                          // setCustomerAddressValue(newValue);
                          handleDestinationPlaceId(newValue);
                        } else {
                          console.log("newValue is falsy");
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        setCustomerAddressInputValue(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label="Add an address"
                          className={`${classes.autocompleteField} `}
                          // color="primary"
                          placeholder="Address"
                          fullWidth
                          // helperText="Enter the Customer’s address (Map View)"
                          error={
                            formik.touched.customerAddress &&
                            Boolean(formik.errors.customerAddress)
                          }
                          // helperText={
                          //   formik.touched.customerAddress ?
                          //   formik.errors.customerAddress :
                          //   "Enter the Customer’s address (Map View)"
                          // }
                          helperText={
                            formik.touched.customerAddress &&
                            formik.errors.customerAddress
                          }
                          // inputProps={{
                          //   style: {
                          //     backgroundColor: white,
                          //     paddingBottom: "1em",
                          //     // paddingTop: -20,
                          //   },
                          // }}
                          // InputProps={{
                          //   // root: {
                          //   //   // paddingTop: "2em"
                          //   // }
                          // }}
                          variant="filled"
                          size="small"
                          onBlur={formik.handleBlur}
                          // margin="normal"
                        />
                      )}
                      renderOption={(option) => {
                        if (typeof option === "object") {
                          console.log("option is", option);
                          const matches =
                            option.structured_formatting
                              .main_text_matched_substrings;
                          const parts = parse(
                            option.structured_formatting.main_text,
                            matches.map((match) => [
                              match.offset,
                              match.offset + match.length,
                            ])
                          );

                          return (
                            <Grid container alignItems="center">
                              <Grid item>
                                <LocationOnIcon className={classes.icon} />
                              </Grid>
                              <Grid item xs>
                                {parts.map((part, index) => (
                                  <span
                                    key={index}
                                    style={{
                                      fontWeight: part.highlight ? 700 : 400,
                                    }}
                                  >
                                    {part.text}
                                  </span>
                                ))}

                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {option.structured_formatting.secondary_text}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        } else {
                          return <> </>;
                        }
                      }}
                    />
                  </Container>
                  <Container
                    className={classes.textFieldWrapper}
                    disableGutters={true}
                    id="wrapperContainer"
                    maxWidth="sm"
                  >
                    <Typography
                      className={`${classes.textLabel} required`}
                      variant="body2"
                      component="div"
                    >
                      CBD (Central Business District) Order
                    </Typography>
                    <FormControl component="fieldset" fullWidth={true}>
                      <RadioGroup
                        name="CBD"
                        value={formik.values.CBD.toString()}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "CBD",
                            event.currentTarget.value
                          );
                        }}
                        fullWidth={true}
                      >
                        {CBDOptions.map((element, index, array) => (
                          <FormControlLabel
                            key={index}
                            fullWidth={true}
                            value={element["value"].toString()}
                            control={<Radio color="primary" />}
                            label={element["name"].toString()}
                            onChange={() => {
                              // formik.setFieldValue(
                              //   "CBD",
                              //   element["value"].toString()
                              // );
                            }}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Container>
                  <Container
                    className={classes.distanceFieldsSideBySide}
                    disableGutters={true}
                    id="wrapperContainer"
                    maxWidth="sm"
                  >
                    {/* Delivery Time */}
                    <Container
                      className={classes.textFieldWrapper}
                      disableGutters={true}
                      id="wrapperContainer"
                      maxWidth="sm"
                    >
                      <Typography
                        className={`${classes.textLabel} required`}
                        variant="body2"
                        component="div"
                      >
                        Estimated Delivery Time (Minutes)
                      </Typography>
                      <TextField
                        className={`${classes.textEntryField} "txtInput"`}
                        sx={{ mt: "1vh" }}
                        fullWidth
                        id="deliveryTime"
                        name="deliveryTime"
                        // label="First Name"
                        value={formik.values.deliveryTime}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.customerAddress &&
                          Boolean(formik.errors.deliveryTime)
                        }
                        helperText={
                          formik.touched.customerAddress &&
                          formik.errors.deliveryTime
                        }
                        placeholder="minutes"
                        variant="filled"
                        margin="normal"
                        inputProps={{
                          style: {
                            backgroundColor: white,
                            paddingBottom: "1em",
                          },
                        }}
                        InputProps={{
                          className: classes.txtInput,
                          classes: {
                            root: classes.txtInput,
                          },
                        }}
                        size="small"
                        disabled={true}
                      />
                    </Container>
                    {/* Delivery Distance */}
                    <Container
                      className={classes.textFieldWrapper}
                      disableGutters={true}
                      id="wrapperContainer"
                      maxWidth="sm"
                    >
                      <Typography
                        className={`${classes.textLabel} required`}
                        variant="body2"
                        component="div"
                      >
                        Estimated Distance (Km)
                      </Typography>
                      <TextField
                        className={`${classes.textEntryField} "txtInput"`}
                        sx={{ mt: "1vh" }}
                        fullWidth
                        id="deliveryDistance"
                        name="deliveryDistance"
                        // label="First Name"
                        value={formik.values.deliveryDistance}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.customerAddress &&
                          Boolean(formik.errors.deliveryDistance)
                        }
                        helperText={
                          formik.touched.customerAddress &&
                          formik.errors.deliveryDistance
                        }
                        placeholder="km"
                        variant="filled"
                        margin="normal"
                        inputProps={{
                          style: {
                            backgroundColor: white,
                            paddingBottom: "1em",
                          },
                        }}
                        InputProps={{
                          className: classes.txtInput,
                          classes: {
                            root: classes.txtInput,
                          },
                        }}
                        size="small"
                        disabled={true}
                      />
                    </Container>
                  </Container>
                  <Container
                    className={classes.textFieldWrapper}
                    disableGutters={true}
                    id="wrapperContainer"
                    maxWidth="md"
                  >
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead className={classes.tableHeader}>
                          <TableRow>
                            <StyledTableCell className={classes.tableRow}>
                              Rider name
                            </StyledTableCell>
                            <StyledTableCell
                              className={classes.tableRow}
                              align="right"
                            >
                              Check-in time
                            </StyledTableCell>
                            <StyledTableCell
                              className={classes.tableRow}
                              align="right"
                            >
                              Mode of Transportation
                            </StyledTableCell>
                            <StyledTableCell
                              className={classes.tableRow}
                              align="right"
                            >
                              Status
                            </StyledTableCell>
                            <StyledTableCell
                              className={classes.tableRow}
                              align="center"
                            >
                              Action
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => {
                            // eslint-disable-next-line no-lone-blocks
                            {
                              /* console.log("In map Row:", JSON.stringify(row)); */
                            }
                            return (
                              <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                  {row.name}
                                </TableCell>
                                <TableCell align="right">
                                  {row.checkInTime}
                                </TableCell>
                                <TableCell align="right">
                                  {/* <Container
                              className={classes.transportationContainer} 
                              disableGutters={true}
                              >
                                <DeliveryDiningIcon className={classes.iconContainer}/> 
                                <Typography
                                  className={`${classes.transportText}`}
                                  variant="body2"
                                  component="div"
                                >
                                 {row.modeOfTransportation}
                                </Typography>
                                </Container> */}
                                  {renderModeOfTransport(
                                    row.modeOfTransportation
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  {row.number}
                                </TableCell>
                                <TableCell align="right">
                                  <Button
                                    className={classes.selectRiderButton}
                                    variant="outlined"
                                    onClick={() => {
                                      setEnableRiderName(true);
                                      formik.setFieldValue(
                                        "riderName",
                                        row.name
                                      );
                                      formik.setFieldValue(
                                        "riderId",
                                        row.id.toString()
                                      );
                                    }}
                                  >
                                    Select Rider
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Container>
                  <Container
                    className={classes.textFieldWrapper}
                    disableGutters={true}
                    id="wrapperContainer"
                    maxWidth="sm"
                  >
                    <Typography
                      className={`${classes.textLabel} required`}
                      variant="body2"
                      component="div"
                    >
                      Rider's Name
                    </Typography>
                    <Autocomplete
                      disabled={!enableRiderName}
                      freeSolo={true}
                      fullWidth={true}
                      size="large"
                      name="riderName"
                      id="riderName"
                      value={formik.values.riderName}
                      onChange={(event, newValue) => {
                        console.log("New Value is:", newValue);
                        if (newValue && newValue.label) {
                          formik.setFieldValue("riderName", newValue.label);
                          formik.setFieldValue(
                            "riderId",
                            newValue.riderId.toString()
                          );
                        } else {
                          formik.setFieldValue("riderName", newValue);
                          // console.log("Rider name is:", newValue);
                        }
                        // formik.setFieldValue("riderName", newValue.label);
                      }}
                      options={riderNames}
                      getOptionLabel={(option) => {
                        if (
                          typeof option === "string" ||
                          typeof option === "number"
                        ) {
                          return option.toString();
                        } else {
                          return option.label;
                        }
                      }}
                      getOptionSelected={(option, value) =>
                        option.label === value.label
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={`${classes.autocompleteField} `}
                          required
                          // helperText="Enter the rider's name"
                          placeholder="Enter the rider's name"
                          error={
                            formik.touched.riderName &&
                            Boolean(formik.errors.riderName)
                          }
                          // helperText={
                          //   formik.touched.customerLocation ?
                          //   formik.errors.customerLocation :
                          //   "Type and select the location (DropDown)"
                          // }
                          helperText={
                            formik.touched.riderName && formik.errors.riderName
                          }
                          variant="filled"
                          size="small"
                        />
                      )}
                    />
                  </Container>
                  <Container
                    className={classes.mapViewWrapper}
                    disableGutters={true}
                    id="wrapperContainer"
                    maxWidth="sm"
                  >
                    <Typography
                      className={`${classes.mapViewText}`}
                      sx={{ textAlign: "center" }}
                      variant="body2"
                      component="div"
                    >
                      Map View
                    </Typography>
                    {/* <MapView
                      pickupLocation={{lat:-1.26606, lng: 36.80394}}
                      dropLocation={{lat: -1.2609781530466984, long: 36.80204207090601}}
                      // deliveryDistance={showComputedDistance}
                      // deliveryTime={showComputedTime}
                    /> */}
                    <MapWithADirectionsRenderer
                      origin={kitchenOrigin}
                      dest={destinationPlaceId}
                      deliveryDistance={showComputedDistance}
                      deliveryTime={showComputedTime}
                    />
                  </Container>
                  <Container
                    className={classes.textFieldWrapper}
                    disableGutters={true}
                    sx={{ mb: "10vh" }}
                    id="wrapperContainer"
                    maxWidth="sm"
                  >
                    {formik.isSubmitting === false ? (
                      <Button
                        // type="submit"
                        fullWidth={true}
                        // className={{classes.}}
                        className={classes.submitButton}
                        onClick={(event) => {
                          // setSubmiting(true);
                          formik.handleSubmit();
                          console.log(formik.errors);
                        }}
                        variant="contained"
                        size="large"
                        sx={{ mt: "3vh", mb: "5vh" }}
                      >
                        Submit
                      </Button>
                    ) : (
                      <CircularProgress
                        alignContent="center"
                        alignItems="center"
                        sx={{ mt: 3, mb: 2 }}
                      />
                    )}
                  </Container>
                  <Copyright sx={{ mt: 8, mb: 4 }} />
                </Container>
              </form>
            </Container>

            {/* <Typography>
            formik order source: {formik.values.orderSource.toString()} <br />
            Selected order source is {selectedOrderSource}
            <br />
            formik payment status  {formik.values.paymentStatus.toString()} <br />
            Selected payment status is {selectedPaymentStatus}
            <br />
            Rider Name is {formik.values.riderName}<br />
            RIder Id is {formik.values.riderId} 
          </Typography> */}
          </Container>
        )}
      </Container>
    </ThemeProvider>
  );
}

export default Dispatch;
