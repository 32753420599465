import { Container, Menu, MenuItem, Typography } from "@material-ui/core";
import { white } from "../../constants/colors";
import useStyles from "./styles";

import React from 'react'

const index = () => {
  return (
    <div>index</div>
  )
}


const ProfileMenu = ({
  profileMenuAnchorEl,
  profileMenuId,
  handleProfileMenuClose,
  profileMenuOpen,
  signOut,
  kitchenUserDetails
}) => {

  const classes = useStyles();
  return (
    <Menu
      className={classes.profileMenu}
      anchorEl={profileMenuAnchorEl}
      id={profileMenuId}
      keepMounted
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{
        marginTop: 64,
      }}
      // elevation={0}
      open={profileMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <MenuItem
        // disableGutters={true}
        style={{
          whiteSpace: "normal",
          margin: 0,
          backgroundColor: white,
          boxShadow: "none",
          padding: 0,
        }}
        className={classes.profileMenuItemStyle}
        classes={{
          root: {
            "&:hover": {
              //you want this to be the same as the backgroundColor above
              backgroundColor: "#FFF",
            },
          },
          gutters: {
            "&:hover": {
              //you want this to be the same as the backgroundColor above
              backgroundColor: "#FFF",
            },
          },
        }}
        onClick={() => {
          // console.log("Clicked user profile");
        }}
      >
        <Container
          maxWidth="lg"
          className={classes.profileItemContainer}
          // disableGutters={true}
          style={{
            width: "auto",
            minWidth: 279,
            minHeight: "7vh",
          }}
        >
          <Container
            maxWidth="lg"
            className={classes.profileItemTextContainer}
            // disableGutters={true}
            style={{
              width: "100%",
            }}
          >
            <Container
              maxWidth="md"
              className={classes.userProfileContainer}
              disableGutters={true}
              style={{
                // width: "auto",
                // maxWidth: "60%",
                // width: "20em",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography
                style={{
                  wordWrap: "break-word",
                  // marginLeft: "2vh",
                  order: 0,
                  marginBottom: "0.5em",
                  // width: "90%",
                }}
                display="inline"
                component="p"
                variant="body1"
                className={classes.userNameText}
                noWrap={false}
                onClick={() => {
                  console.log("User name clicked");
                }}
              >
                {kitchenUserDetails.username}
              </Typography>
              <Typography
                style={{
                  wordWrap: "break-word",
                  // marginLeft: "2vh",
                  order: 1,
                  // width: "90%",
                }}
                display="inline"
                component="p"
                variant="body2"
                className={classes.userNameText}
                noWrap={false}
                onClick={() => {
                  console.log("User name clicked");
                }}
              >
                {kitchenUserDetails.name}
              </Typography>
              <Typography
                style={{
                  wordWrap: "break-word",
                  // marginLeft: "2vh",
                  order: 2,
                  // width: "90%",
                }}
                display="inline"
                component="p"
                variant="body2"
                className={classes.userNameText}
                noWrap={false}
                onClick={() => {
                  console.log("User name clicked");
                }}
              >
                {kitchenUserDetails.address}
              </Typography>
            </Container>
          </Container>
        </Container>
      </MenuItem>
      <MenuItem
        // disableGutters={true}
        style={{
          whiteSpace: "normal",
          margin: 0,
          // backgroundColor: "rgba(61, 89, 130, 0.1)",
          backgroundColor: white,
          boxShadow: "none",
          padding: 0,
        }}
        className={classes.profileMenuItemStyle}
        classes={{
          root: {
            "&:hover": {
              //you want this to be the same as the backgroundColor above
              backgroundColor: "#FFF",
            },
          },
          gutters: {
            "&:hover": {
              //you want this to be the same as the backgroundColor above
              backgroundColor: "#FFF",
            },
          },
        }}
        onClick={signOut}
      >
        <Container
          maxWidth="lg"
          className={classes.profileItemContainer}
          disableGutters={true}
          style={{
            width: "auto",
            minWidth: 279,
            minHeight: "7vh",
          }}
        >
          <Container
            maxWidth="lg"
            className={classes.profileItemTextContainer}
            disableGutters={true}
            style={{
              width: "100%",
            }}
          >
            <Container
              maxWidth="md"
              className={classes.profileItemBodyContainer}
              disableGutters={true}
              style={{
                // width: "auto",
                // maxWidth: "60%",
                // width: "20em",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography
                style={{
                  wordWrap: "break-word",
                  marginLeft: "3rem",
                  // width: "90%",
                }}
                display="inline"
                component="p"
                variant="body1"
                className={classes.logoutText}
                noWrap={false}
                onClick={signOut}
              >
                Log Out
              </Typography>
            </Container>
          </Container>
        </Container>
      </MenuItem>
    </Menu>
  );
};

export default ProfileMenu;