import { makeStyles } from "@material-ui/styles";
import * as colors from "../../constants/colors";

const useStyles = makeStyles({
  profileMenu: {
    "& .MuiList-padding": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  profileMenuItemStyle: {
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "rgba(61, 89, 130, 0.1)",
    },
  },
  profileItemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexGrow: 1,
    justifyContent: "center",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "rgba(61, 89, 130, 0.1)",
    },
  },
  profileItemTextContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 0,
    margin: 0,
  },
  profileItemBodyContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    margin: 0,
    flexWrap: "wrap",
    justifyContent: "flex-start",
    // overflow: "auto",
    // textOverflow: "ellipsis"
  },
  userProfileContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: "none",
    order: 0,
    flexGrow: 0,
    margin: 0,
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: "0.5em",
    paddingTop: "0.75em",
    paddingBottom: "0.75em",
  },
  userNameText: {
    color: colors.blackLight,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    // fontWeight: "bold",
    textTransform: "none",
    fontSize: 17,
    fontFeatureSettings: "'ss03' on",
  },
  addressText: {
    color: colors.blackLight,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    textTransform: "none",
    fontSize: 14,
    fontFeatureSettings: "'ss03' on",
  },
  logoutText: {
    color: colors.red,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: 17,
    fontFeatureSettings: "'ss03' on",
    // lineHeight: 20,
  },
});

export default useStyles;
