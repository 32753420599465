import apiService from "../api";


const login = {
    getToken: () => {
        let token = localStorage.getItem('token')
        if (token == null) {
            console.log("Auth Token no set");
            return null;
        } else {
            return token;
        }
    },
    checkLogin: () => {

    },
    useLoginStatus: () => {
        let token = localStorage.getItem('token')
        if (token == null) {
            console.log("Auth Token not set");
            return false;
        } else {
            return true;
        }
    },
    setToken: (bearerToken) => {
        localStorage.setItem('token', bearerToken);
        console.log("token set is", localStorage.getItem('token'));
    },
    setKitchenId: (kitchenId) => {
        localStorage.setItem('kitchenId', kitchenId);
        console.log("Kitchen Id is", localStorage.getItem('kitchenId'));
    },
    setKitchenUserId: (kitchenUserId) => {
        localStorage.setItem('kitchenUserId', kitchenUserId);
        console.log("Kitchen User Id is", localStorage.getItem('kitchenUserId'));
    },
    getKitchenUserId: () => {
        let kitchenUserId = localStorage.getItem('kitchenUserId');
        if (kitchenUserId == null) {
            console.log("Kitchen User Id not set");
            return null;
        } else {
            return kitchenUserId;
        }
    },
    getKitchenId: () => {
        let kitchenId = localStorage.getItem('kitchenId');
        if (kitchenId == null) {
            console.log("Kitchen Id not set");
            return null;
        } else {
            return kitchenId;
        }    
    },
    logOut: async () => {
        let endPointResponse = await apiService.logoutRequest();
        try {
            console.log(endPointResponse.status); // Will show you the status
            if (!endPointResponse.ok) {
                let result = await endPointResponse.json();
                console.log("HTTP status " + endPointResponse.status)
                throw new Error("Logout Failed" + result );
            } else {
                // Remove local storage tokens and return true
                localStorage.removeItem('token');
                localStorage.removeItem('kitchenId');
                // Check if token is present
                let token = localStorage.getItem('token')
                localStorage.clear();
                if (token == null) {
                console.log("Auth Token removed");
                return true;
                } else {
                return false;
                }
            }
          } catch (error) {
            console.log('logout endpoint error:', error);
            localStorage.clear();   
            return true      
          }

    },
    setKitchenDetails: (details) => {
        localStorage.setItem('kitchenLat', details.lat);
        localStorage.setItem('kitchenLng', details.lng);
        console.log("Details set is", localStorage.getItem('kitchenLat'));
    },
    getLocalKitchenDetails: () => {
        let lat = localStorage.getItem('kitchenLat');
        let lng = localStorage.getItem('kitchenLng');
        return {
            lat: lat,
            lng: lng
        };
    },
       
}

export default login