/*global google*/
import React from 'react';
import equal from 'fast-deep-equal';
import Typography from "@material-ui/core/Typography";

const { compose, withProps, lifecycle } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
} = require("react-google-maps");

//const dest = {lat:-1.25901, lng: 36.78584, placeId:"ChIJmwD7XeUXLxgR2oN3fVsfApk"};
const REACT_APP_GCLOUD_API_KEY = process.env.REACT_APP_GCLOUD_API_KEY;
const MapWithWayPoints = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GCLOUD_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `50vh`, width: "100%", marginTop: "1vh", marginBottom: "3vh" }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,  
  lifecycle({
    componentDidMount(props) {
      const DirectionsService = new google.maps.DirectionsService();
      console.log("You are at the first componentDidMount");
      console.log(this.props.dest.placeId);
      if (this.props.dest.placeId) {
        try {
          console.log("PlaceId is not empty!")
          DirectionsService.route({
            origin: new google.maps.LatLng(this.props.origin.lat, this.props.origin.lng),
            
            destination: {placeId: this.props.dest.placeId},
            
            //destination: new google.maps.LatLng(this.props.dest.lat, this.props.dest.lng),
            travelMode: google.maps.TravelMode.DRIVING,
          }, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              this.setState({
                directions: result,
              });
              console.log(result);
              console.log(result.routes[0].legs[0].duration.text)
              const updateDistance = (res) => {
                this.props.deliveryDistance(res.routes[0].legs[0].distance.text)
              };
              updateDistance(result);
              const updateTime = (res) => {
                this.props.deliveryTime(res.routes[0].legs[0].duration.text)
              };
              updateTime(result)
              
            } else {
              console.error(`error fetching directions ${result}`);
            }
            
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          console.log("PlaceId is empty", this.props.dest.placeId)
          console.log(this.props.dest);
          DirectionsService.route({
            origin: new google.maps.LatLng(this.props.origin.lat, this.props.origin.lng),
            
            destination: {placeId: this.props.dest.placeId},
            
            //destination: new google.maps.LatLng(this.props.dest.lat, this.props.dest.lng),
            travelMode: google.maps.TravelMode.DRIVING,
          }, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              this.setState({
                directions: result,
              });
              console.log(result);
              console.log(result.routes[0].legs[0].duration.text)
            } else {
              console.error(`error fetching directions ${result}`);
            }
          });
        } catch (error) {
          console.log("PlaceId is NOT empty")
          // console.log(this.props.dest.placeId);
          console.log(error);
        }
      }
    },
    componentDidUpdate(prevProps) {
      function shortestRoute(routeResults) {
        var shortestRoute = routeResults.routes[0];
        var shortestLength = shortestRoute.legs[0].distance.value;
        for (var i = 1; i < routeResults.routes.length; i++) {
            if (routeResults.routes[i].legs[0].distance.value < shortestLength) {
                shortestRoute = routeResults.routes[i];
                shortestLength = routeResults.routes[i].legs[0].distance.value;
            }
        }
        routeResults.routes = [shortestRoute];
        return routeResults;
      }
  if(!equal(this.props.dest, prevProps.dest)) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
  {
    const DirectionsService = new google.maps.DirectionsService();
    console.log("You are at the componentDidUpdate");
    console.log("Previous props are:", prevProps.dest);
    console.log(this.props.dest);
    console.log(prevProps);
    try {
      console.log("MapsWaypoints",this.props.wayPoints);
      DirectionsService.route({
        //origin: new google.maps.LatLng(khans.lat, khans.lng)
        //destination: new google.maps.LatLng(this.props.dest.lat, this.props.dest.lng),
        origin: new google.maps.LatLng(this.props.origin.lat, this.props.origin.lng),
        destination: {placeId: this.props.dest.placeId},
        waypoints: this.props.wayPoints ? this.props.wayPoints : [],
        travelMode: google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true,
        optimizeWaypoints: false
      }, (result, status) => {
        console.log("status is", status);
        if (status === google.maps.DirectionsStatus.OK) {
          console.log("Initial Result", result);
          result = shortestRoute(result);
          console.log("Shortest result", result);
          this.setState({
            directions: result,
            time: result.routes[0].legs[0].duration.text
          });
          console.log("route hook",this.props.routeHook)
          if (this.props.routeHook !== undefined) {
            this.props.routeHook(result.routes[0]);
          }
          console.log(result);
          console.log(result.routes[0].legs[0].duration.text)
          let duration = result.routes[0].legs[0].duration.value;
          let distance = result.routes[0].legs[0].distance.value;
          let distanceInKm, durationInMin;
          if (distance) {
            distanceInKm = (distance / 1000);
          } else {
            distanceInKm = undefined
          }
          if (duration) {
            durationInMin = Math.round(duration / 60);
          } else {
            durationInMin = undefined
          }
          const updateDistance = (res) => {
            this.props.deliveryDistance(distanceInKm)
          };
          updateDistance(result);
          const updateTime = (res) => {
            this.props.deliveryTime(durationInMin)
          };
          updateTime(result)
        } else if (status === google.maps.DirectionsStatus.ZERO_RESULTS) {
          const updateDistance = (res) => {
            this.props.deliveryDistance("No route found")
          };
          updateDistance(result);
          const updateTime = (res) => {
            this.props.deliveryTime("No route found")
          };
          updateTime(result)
        }
        else {
          console.error(`error fetching directions ${result}`);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
}
    

  })
)(props =>
  <GoogleMap
    defaultZoom={14}
    defaultCenter={new google.maps.LatLng(props.origin.lat, props.origin.lng)}
  > 
          
    {props.directions && <DirectionsRenderer directions={props.directions} />}
  </GoogleMap>
);
 export {MapWithWayPoints};