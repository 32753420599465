import { Container, Menu, MenuItem, Typography } from "@material-ui/core";
import React from "react";
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import useStyles from "./styles";

function DropDown({
  dropDownAnchorEl,
  isDropDownOpen,
  handleDropDownClose,
  setShowPickupForm
}) {
const classes = useStyles();
  return (
    <Menu
      anchorEl={dropDownAnchorEl}
      // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={"Drop Down Menu"}
      keepMounted
      // transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{
        marginTop: 64,
      }}
      open={isDropDownOpen}
      onClose={handleDropDownClose}
    >
      <MenuItem
        onClick={() => {
          setShowPickupForm(false);
          handleDropDownClose();
        }}
      >
        <Container
          className={classes.labelContainer}
          // disableGutters={true}
          fullWidth={true}
        >
          <PedalBikeIcon className={classes.labelIcon} />
          <Typography
            className={`${classes.labelText}`}
            variant="body2"
            component="div"
          >
            Dispatch Ops
          </Typography>
        </Container>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setShowPickupForm(true);
          handleDropDownClose();
        }}
      >
        <Container
          className={classes.labelContainer}
          // disableGutters={true}
          fullWidth={true}
        >
          <DirectionsWalkIcon className={classes.labelIcon} />
          <Typography
            className={`${classes.labelText}`}
            variant="body2"
            component="div"
          >
            Pickups
          </Typography>
        </Container>
      </MenuItem>
    </Menu>
  );
}

export default DropDown;
