import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react'
import { black, white } from '../../constants/colors';


const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: black,
    color: white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default StyledTableCell