import {
  Button,
  CircularProgress,
  Container,
  FilledInput,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "./styles";
import { useFormik } from "formik";
import validationSchema from "./validation";
import * as colors from "../constants/colors";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import apiService from "../api";

export default function Pickup(props) {
  let {showNotification} = props;
  // Needed to
  // 1. Add side bar to top menu bar - Done
  // 2. Set fields -- Done
  // 3. Add validations, phone number parse -- Done
  // 4. Add api -- Done
  // 5. Add alert handler -- Done
  const formikPickup = useFormik({
    initialValues: {
      orderId: undefined,
      customerName: "",
      customerNumber: "",
      message: "Your order from Khan's is ready for pickup",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("Submitting");
      formikPickup.setSubmitting(true);
      let submissionValues = {
        message: values.message,
        number: values.customerNumber,
        customerName: values.customerName,
        orderId: values.orderId
      };

      // Remove the first zero in the phone number
      if (submissionValues["number"].length === 10) {
        submissionValues["number"] = submissionValues["number"].substring(1);
        submissionValues["number"] = '254'+ submissionValues["number"]
      } else {
        // Do nothing
      }
        // Send submission
       let submissionResponse = await apiService.sendNotificationMessage(submissionValues);
       console.log(submissionResponse);
       if (submissionResponse.status === 200) {
         let body = await submissionResponse.json();
         console.log("Success, data is:", body);

        showNotification("success", "Sent SMS!")
         window.location.reload({ forcedReload: true });
       } else {
         let body = await submissionResponse.json();
         console.log("Failed, data is:", body);
         formikPickup.setSubmitting(false);
      showNotification("error", body.message)
       }
    },
  });
  const [characterCount, setCharacterCount] = useState(formikPickup.values.message.length)

  let classes = useStyles();
  return (
    <Container className={classes.contentContainer} maxWidth="lg">
      <Container className={classes.headerContainer}>
        <Typography
          sx={{ mt: "3vh" }}
          component="h1"
          variant="h4"
          className={classes.headingText}
        >
          Customer Pickup
        </Typography>
        <Typography
          sx={{ mt: "1.5vh" }}
          component="p"
          variant="body1"
          className={classes.requiredText}
        >
          *Required Field
        </Typography>
      </Container>
      <Container
        className={classes.formContainer}
        disableGutters={true}
        maxWidth="lg"
      >
        <form
          className={classes.formikForm}
          onSubmit={formikPickup.handleSubmit}
        >
          <Container
            className={`${classes.formikWrapper} "formikOuter"`}
            id="wrapperContainer"
            disableGutters={true}
          >
            <Container
              className={classes.textFieldWrapper}
              disableGutters={true}
              id="wrapperContainer"
              maxWidth="sm"
            >
              <Typography
                className={`${classes.textLabel} required`}
                variant="body2"
                component="div"
                maxWidth="sm"
              >
                Order Number
              </Typography>
              <TextField
                className={`${classes.textEntryField} "txtInput"`}
                sx={{ mt: "1vh" }}
                fullWidth
                id="orderId"
                name="orderId"
                // label="Order Number"
                value={formikPickup.values.orderId}
                onChange={formikPickup.handleChange}
                onBlur={formikPickup.handleBlur}
                error={
                  formikPickup.touched.orderId &&
                  Boolean(formikPickup.errors.orderId)
                }
                helperText={
                  formikPickup.touched.orderId && formikPickup.errors.orderId
                }
                placeholder="Order Number"
                variant="filled"
                margin="normal"
                inputProps={{
                  style: {
                    backgroundColor: colors.white,
                    paddingBottom: "1em",
                  },
                }}
                InputProps={{
                  className: classes.txtInput,
                  classes: {
                    root: classes.txtInput,
                  },
                }}
                size="small"
              />
            </Container>
            <Container
              className={classes.textFieldWrapper}
              disableGutters={true}
              id="wrapperContainer"
              maxWidth="sm"
            >
              <Typography
                className={`${classes.textLabel} required`}
                variant="body2"
                component="div"
                maxWidth="sm"
              >
                Customer Name
              </Typography>
              <TextField
                className={`${classes.textEntryField} "txtInput"`}
                sx={{ mt: "1vh" }}
                fullWidth
                id="customerName"
                name="customerName"
                // label="First Name"
                value={formikPickup.values.customerName}
                onChange={formikPickup.handleChange}
                error={
                  formikPickup.touched.customerName &&
                  Boolean(formikPickup.errors.customerName)
                }
                helperText={
                  formikPickup.touched.customerName &&
                  formikPickup.errors.customerName
                }
                placeholder="First Name"
                variant="filled"
                margin="normal"
                inputProps={{
                  style: {
                    backgroundColor: colors.white,
                    paddingBottom: "1em",
                  },
                }}
                InputProps={{
                  className: classes.txtInput,
                  classes: {
                    root: classes.txtInput,
                  },
                }}
                size="small"
                onBlur={formikPickup.handleBlur}
              />
            </Container>
            <Container
              className={classes.textFieldWrapper}
              disableGutters={true}
              id="wrapperContainer"
              maxWidth="sm"
            >
              <Typography
                className={`${classes.textLabel} required`}
                variant="body2"
                component="div"
              >
                Customer Number
              </Typography>
              <TextField
                className={`${classes.textEntryField} "txtInput"`}
                sx={{ mt: "1vh" }}
                fullWidth
                id="customerNumber"
                name="customerNumber"
                // label="First Name"
                value={formikPickup.values.customerNumber}
                onChange={formikPickup.handleChange}
                onBlur={formikPickup.handleBlur}
                error={
                  formikPickup.touched.customerNumber &&
                  Boolean(formikPickup.errors.customerNumber)
                }
                helperText={
                  formikPickup.touched.customerNumber &&
                  formikPickup.errors.customerNumber
                }
                placeholder="0712 345 678"
                variant="filled"
                margin="normal"
                inputProps={{
                  style: {
                    backgroundColor: colors.white,
                    paddingBottom: "1em",
                  },
                }}
                InputProps={{
                  className: classes.txtInput,
                  classes: {
                    root: classes.txtInput,
                  },
                }}
                size="small"
              />
            </Container>
            <Container
              className={classes.textFieldWrapper}
              disableGutters={true}
              id="wrapperContainer"
              maxWidth="sm"
            >
              <Typography
                className={`${classes.textLabel} required`}
                variant="body2"
                component="div"
              >
                Message
              </Typography>
              <TextField
                className={`${classes.messageEntryField} "txtInput"`}
                sx={{ mt: "1vh" }}
                fullWidth
                margin="normal"
                size="small"
                inputProps={{
                  style: {
                    backgroundColor: colors.white,
                  },
                }}
                InputProps={{
                  className: classes.txtInput,
                  classes: {
                    root: classes.txtInput,
                  },
                }}
                multiline={true}
                minRows={2}
                maxRows={4}
                placeholder="Enter the text Message"
                id="message"
                name="message"
                value={formikPickup.values.message}
                onChange={(e) => {
                  console.log(e);
                  if (e.target.value != null) {
                    formikPickup.setFieldValue("message", e.target.value, true);
                    setCharacterCount(e.target.value.length);
                  }
                }}
                onBlur={formikPickup.handleBlur}
              />
              <Typography
                className={`${classes.characterLabel}`}
                variant="body2"
                component="div"
              >
                {characterCount} characters
              </Typography>
            </Container>
            <Container
                    className={classes.textFieldWrapper}
                    disableGutters={true}
                    sx={{ mb: "10vh" }}
                    id="wrapperContainer"
                    maxWidth="sm"
                  >
                    {formikPickup.isSubmitting === false ? (
                      <Button
                        // type="submit"
                        fullWidth={true}
                        // className={{classes.}}
                        className={classes.submitButton}
                        onClick={(event) => {
                          // setSubmiting(true);
                          formikPickup.handleSubmit();
                          console.log(formikPickup.errors);
                        }}
                        variant="contained"
                        size="large"
                        sx={{ mt: "3vh", mb: "5vh" }}
                      >
                        Submit
                      </Button>
                    ) : (
                      <CircularProgress
                        alignContent="center"
                        alignItems="center"
                        sx={{ mt: 3, mb: 2 }}
                      />
                    )}
                  </Container>
          </Container>
          <Container
            className={classes.inputFields}
            disableGutters={true}
          ></Container>
        </form>
      </Container>
    </Container>
  );
}
