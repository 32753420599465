import React from "react";
import { Route } from "react-router-dom";
import login from "./utils/login";
import SignIn from "./SignIn";

const PrivateRoute = props => {
    const userIsLogged = login.useLoginStatus();
 
    return (
       <Route {...props}>{userIsLogged ? props.children : <SignIn/>}</Route>
    );
 };
 
 export default PrivateRoute;