import React from 'react'
import { Container, Typography } from '@material-ui/core';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import DirectionsCarRoundedIcon from '@mui/icons-material/DirectionsCarRounded';
import useStyles from './styles';

function ModeOfTransport({mode}) {
  const classes = useStyles();
  switch (mode) {
    case "MOTORCYCLE":
      return (
        <Container
          className={classes.transportationContainer}
          disableGutters={true}
        >
          <DeliveryDiningIcon className={classes.iconContainer} />
          <Typography
            className={`${classes.transportText}`}
            variant="body2"
            component="div"
          >
            Motorcycle
          </Typography>
        </Container>
      );
    case "PEDAL BIKE":
      return (
        <Container
          className={classes.transportationContainer}
          disableGutters={true}
        >
          <PedalBikeIcon className={classes.iconContainer} />
          <Typography
            className={`${classes.transportText}`}
            variant="body2"
            component="div"
          >
            Pedal Bike
          </Typography>
        </Container>
      );
    case "CAR":
      return (
        <Container
          className={classes.transportationContainer}
          disableGutters={true}
        >
          <DirectionsCarRoundedIcon className={classes.iconContainer} />
          <Typography
            className={`${classes.transportText}`}
            variant="body2"
            component="div"
          >
            Car
          </Typography>
        </Container>
      );
    default:
      return (
        <Container
          className={classes.transportationContainer}
          disableGutters={true}
        >
          <DeliveryDiningIcon className={classes.iconContainer} />
          <Typography
            className={`${classes.transportText}`}
            variant="body2"
            component="div"
          >
            {mode}
          </Typography>
        </Container>
      );
  }
}

export default ModeOfTransport