import * as yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const validationSchema = yup.object({
    orderId: yup
      .string("Enter the order number")
      .required("Order id is required"),
    customerName: yup
        .string("Enter the customer's name")
        .required("Name is required"),
    customerNumber: yup
        .string("Enter the Customer's phone number")
        // .matches(/^[0]\d{9}/, "Pls enter a 10 digit number of the form 0712345678")
        .test('is-valid-phone', 'Please enter a valid phone number. It should be a 10-digit local number or a valid international number.', (value) => {
          if (!value) return false;
          const localPhoneRegex = /^0\d{9}$/;
          if (localPhoneRegex.test(value)) {
            return true;
          }
          const phoneNumber = parsePhoneNumberFromString(value);
          return phoneNumber ? phoneNumber.isValid() : false;
        })
        .required("Customer Phone Number is required"),
        // Add regex for 10 digit phone number?
    message: yup
        .string("Enter the the test message to send to the customer")
        .required("Message is required")
    
  });

export default validationSchema