import { makeStyles } from "@material-ui/styles";
import * as colors from "../constants/colors";
import theme from "../theme";

const useStyles = makeStyles({
  rootContainer: {
    minWidth: "100%",
    // maxWidth: "100%",
    // width: "100%",
    minHeight: "100vh",
    backgroundColor: colors.ando_offWhite
  },
  toolbar: {
    backgroundColor: colors.white,
    gap: 30,
    
  },
  appBar: {
    backgroundColor: colors.white,
    color: colors.black_10,
    order: 0,
  },
  imageContainer: {
    transform: "scale(0.5, 0.5)",
  },
  logoAppbar: {
    //   maxHeight: "80%",
    // scale: 0.5,
    transform: "scale(0.5, 0.5)",
    // transform: scale(0.5, 0.5);
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
  },
  notificationIcon: {
    color: colors.blackLight,
  },
  badge: {
    color: colors.red_300,
    // backgroundColor: colors.red_300
  },
  notificationMenu: {
    "& .MuiList-padding" : {
      paddingTop: 0,
      paddingBottom: 0
    },
  },
  snackBar: {
    "& .MuiSnackbarContent-root": {
      backgroundColor: colors.royalBlue,
    color: colors.white,
  },
  },
  menuItemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexGrow: 1,
    justifyContent: "center"

  },
  menuItemTextContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 0,
    margin: 0,
    
  },
  menuItemBodyContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    margin: 0,
    flexWrap: "wrap",
    // overflow: "auto", 
    // textOverflow: "ellipsis"
  },
  menuItemDurationContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    flex: "none",
    order: 1,
    flexGrow: 0,
    alignSelf: "center",
    marginLeft: "auto",
    marginRight: 0
  },
  notificationText: {
    color: colors.black_10,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: 14,
    // lineHeight: 20,
  },
  notificationDurationText: {
    color: colors.blackLightest,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: 12,
  },
  profileMenu: {
    "& .MuiList-padding" : {
      paddingTop: 0,
      paddingBottom: 0
    },
  },
  profileMenuItemStyle: {
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "rgba(61, 89, 130, 0.1)"
  }
  },
  profileItemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexGrow: 1,
    justifyContent: "center",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "rgba(61, 89, 130, 0.1)"
  }
  },
  profileItemTextContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 0,
    margin: 0,
  },
  profileItemBodyContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    margin: 0,
    flexWrap: "wrap",
    justifyContent: "flex-start"
    // overflow: "auto", 
    // textOverflow: "ellipsis"
  },
  userProfileContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: "none",
    order: 0,
    flexGrow: 0,
    margin: 0,
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: '0.5em',
    paddingTop: '0.75em',
    paddingBottom: '0.75em',
  },
  userNameText: {
    color: colors.blackLight,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    textTransform: "none",
    fontSize: 17,
    fontFeatureSettings: "'ss03' on",
  },
  addressText: {
    color: colors.blackLight,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    textTransform: "none",
    fontSize: 14,
    fontFeatureSettings: "'ss03' on",
  },
  logoutText: {
    color: colors.red,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: 17,
    fontFeatureSettings: "'ss03' on",
    // lineHeight: 20,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    order: 1,
    backgroundColor: colors.ando_offWhite,
    marginTop: 10

  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

  },
  headingText: {
    textAlign: "center",
  },
  requiredText: {
    color: colors.red_300,
    textAlign: "center"
  },

  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    order: 1,
    flex: 1,
  },
  formikForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexBasis: "100%",
    minWidth: "100%",
    flex: 1,
  },
  formikWrapper: {
    display: "flex",
    flexBasis: "100%",
    minHeight: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    padding: 0,
  },

  inputFields: {
    flexBasis: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // flexWrap: "wrap" ,
    order: 0,
    flex: 1,
  },

  textFieldWrapper: {
    flexBasis: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    order: 0,
    marginTop: "3vh",
    // flex: 1,
  },
  textLabel: {
    order: 0,
    margin: 0,
    color: colors.blackLight,
    // textAlign: "center",
  },
  textEntryField: {
    color: colors.black_10,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    order: 1,
    backgroundColor: colors.white,
    marginTop: "1vh",
    "&.MuiFilledInput-root:hover": {
      backgroundColor: colors.white,
    }
    // alignSelf: "center",
  },
  characterLabel: {
    order: 3,
    margin: 0,
    color: colors.blackLight,
  },
  messageEntryField: {
    color: colors.black_10,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "2em",
    order: 1,
    backgroundColor: colors.white,
    marginTop: "1vh",
    "&.MuiFilledInput-root:hover": {
      backgroundColor: colors.white,
    },
    '& .MuiInputBase-inputMultiline':{
      padding: "27px 12px 10px", 
    }
    // padding: "27px 12px 10px"
    // paddingTop: "0.5em",
    // paddingLeft: "0.5em"
  },
  txtInput: {
    backgroundColor: colors.white,
  },
  timePicker: {
    color: colors.black_10,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    order: 1,
    backgroundColor: colors.white,
    marginTop: "1vh",
    padding: "1em 1px 0em",
    "& .MuiInputAdornment-root": {
      paddingBottom: "1em",
    },
  },
  radioTextField: {
    color: colors.black_10,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    order: 1,
    backgroundColor: colors.white,
    padding: "1em 1px 0em",
  },
  autocompleteField: {
    "& .MuiFilledInput-root": {
      background: colors.white,
      paddingBottom: "1em"
    },
    color: colors.black_10,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    order: 1,
    backgroundColor: colors.white,
    marginTop: "1vh",
  },
  mapViewWrapper: {
    flexBasis: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    order: 0,
    marginTop: "3vh",
  },
  mapViewText: {
    order: 0,
    margin: 0,
    color: colors.blackLight,
    // textAlign: "center",  
  },
  submitButton: {
    backgroundColor: colors.black,
    color: colors.white,
    marginTop: "2vh",
    marginBottom: "3vh",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    borderRadius: 40,
  },
  tableHeader: {
    backgroundColor: colors.black,
    color: colors.white,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
  },
  tableRow: {
    head: {
      backgroundColor: colors.black,
      color: colors.white,
    },
    backgroundColor: colors.black,
    color: colors.white,
  },
  selectRiderButton: {
    backgroundColor: colors.royalBlueBackground,
    color: colors.royalBlue,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    borderRadius: 40,
    marginInline: "2em"
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  adornment: {
    root:{
      padding: "1.2em 1px 0.5em"
      },
  },
  table: {
    minWidth: 650,
  },
  paper: {
    width: '80%',
    maxHeight: "50vh",
  },
  transportationContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: '0.475rem'

  },
  iconContainer: {
    order: 0

  },
  transportText: {
    order: 1
  }
});

export default useStyles;
