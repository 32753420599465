// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

const duplicateOrderReasons = [
  { label: "Large Order", value: "largeOrder", id: 1 },
  { label: "Missing Items", value: "missingItems", id: 2 },
  { label: "Inaccurate Items", value: "inaccurateItems", id: 3 },
  { label: "Customer Not Found", value: "customerNotFound", id: 4 },
  { label: "Customer Not Reachable", value: "customerNotReachable", id: 5 },
  { label: "Future Order", value: "futureOrder", id: 6},
  { label: "Replacement Order", value: "replacementOrder", id: 7 },
]


export default duplicateOrderReasons;