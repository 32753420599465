import React, { useEffect, useState } from 'react'
import useStyles from './styles'
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup, {useRadioGroup} from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { styled } from '@material-ui/styles';
import { blackLight, blackLighter, blackLightest, blue_400, white } from '../../constants/colors';
import apiService from '../../api';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { Container, Typography } from '@material-ui/core';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import DirectionsCarRoundedIcon from '@mui/icons-material/DirectionsCarRounded';

const options = [
'Abraham Koech',
'Benjamin Oliana',
'Bilionis Kimeli',
'Boniface Mkuto',
'Juan Murphy',
'Daren Harman'
  ];

  const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
      '.MuiFormControlLabel-root:hover':  {
        backgroundColor: blue_400,
      },
      'ReassignLabel': {
        textTransform: 'capitalize',
      },
      backgroundColor: checked ? blackLighter : white ,
      color: checked ? white : blackLight
    }),
  );

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();
  
    let checked = false;
    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }
  
    return <StyledFormControlLabel checked={checked} {...props} />;
  }

export default function Reassign(props) {
    let classes = useStyles();
    const { onClose, value: valueProp, open, notificationObject, notificationsArray, setNotificationsArray, dispatchId, setShowError, setError, setSeverity, ...other } = props;
    // console.log("Props are", props);
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef(null);
    const [reassignRiders, setReassignRiders] = useState([]);


    React.useEffect(() => {
        if (!open) {
          setValue(valueProp);
        }
      }, [valueProp, open]);
    
      const handleEntering = () => {
        if (radioGroupRef.current != null) {
          radioGroupRef.current.focus();
        }
      };
    
      const handleCancel = () => {
        onClose();
      };
    
      const handleOk = async () => {
        console.log("id is", dispatchId);
        if (dispatchId) {
          // Send reassign request
          let resp = apiService.reassignOrder({
            dispatchId: dispatchId,
            riderId: value
          });
          resp = await resp;
          if (resp.status === 200) {
            resp = await resp.json();
            let updatedNotificationObject = notificationObject;
            updatedNotificationObject.reassigned = true;
            let receivedNotificationsArray = notificationsArray
            receivedNotificationsArray = receivedNotificationsArray.map((u) =>
              u.data.dispatchId !== updatedNotificationObject.data.dispatchId
                ? u
                : updatedNotificationObject
            );
            setNotificationsArray(receivedNotificationsArray);
            setSeverity("success");
            setError(resp.message);
            setShowError(true);
            onClose(value);
            
          } else {
            console.log("Failed, response is:", resp);
            setError(resp.message);
            setShowError(true);
            onClose(value);
          }
          
        } else {
          // Close the modal
          onClose(value);
        }
        
      };
    
      const handleChange = (event) => {
        setValue(event.target.value);
      };
    useEffect(()=> {
      const getReassignRiders = async () => {
        let getRidersConfig = {
          checkedIn: false,
          online: true,
          offset: 0,
          limit: 100,
          useKitchenId: false,
        };
        // let getRiders = await apiService.getRiders(getRidersConfig);
        let getRiders = await apiService.getQueue();
        getRiders = await getRiders.json();
        setReassignRiders(getRiders);
        console.log("Riders:", getRiders);
      };
      getReassignRiders();

    },[]);
    const handleModeOfTransport =(mode, option) => {
      switch (mode) {
        case "MOTORCYCLE":
          return (
            <Container
              className={classes.labelContainer}
              disableGutters={true}
              fullWidth={true}
            >
              <DeliveryDiningIcon className={classes.labelIcon} />
              <Typography
                className={`${classes.labelText}`}
                variant="body2"
                component="div"
              >
                {option.name}
              </Typography>
            </Container>
          );
        case "PEDAL BIKE":
          return (
            <Container
              className={classes.labelContainer}
              disableGutters={true}
              fullWidth={true}
            >
              <PedalBikeIcon className={classes.labelIcon} />
              <Typography
                className={`${classes.labelText}`}
                variant="body2"
                component="div"
              >
                {option.name}
              </Typography>
            </Container>
          );
        case "CAR":
          return (
            <Container
              className={classes.labelContainer}
              disableGutters={true}
              fullWidth={true}
            >
              <DirectionsCarRoundedIcon className={classes.labelIcon} />
              <Typography
                className={`${classes.labelText}`}
                variant="body2"
                component="div"
              >
                {option.name}
              </Typography>
            </Container>
          );
        default:
          return (
            <Container
              className={classes.labelContainer}
              disableGutters={true}
              fullWidth={true}
            >
              <DeliveryDiningIcon className={classes.labelIcon} />
              <Typography
                className={`${classes.labelText}`}
                variant="body2"
                component="div"
              >
                {option.name}
              </Typography>
            </Container>
          );
      }
    }
    return (
      <Dialog
        maxWidth="xs"
        onEntering={handleEntering}
        aria-labelledby="confirmation-dialog-title"
        open={open}
        disableGutters={true}
        {...other}
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="confirmation-dialog-title"
        >
          Reassign Order
        </DialogTitle>
        <DialogContent dividers>
          <RadioGroup
            ref={radioGroupRef}
            aria-label="manual-reassignment"
            name="manual-reassignment"
            value={value}
            onChange={handleChange}
          >
            {reassignRiders.length >= 1 ? (
              reassignRiders.map((option) => (
                <MyFormControlLabel
                  fullWidth
                  value={option.riderId.toString()}
                  key={option.riderId}
                  control={
                    <Radio
                      style={{
                        backgroundColor: "transparent",
                        color: blackLightest,
                      }}
                      checkedIcon={
                        <RadioButtonCheckedIcon
                          style={{
                            backgroundColor: "transparent",
                            color: white,
                          }}
                        />
                      }
                      className="ReassignLabel"
                    />
                  }
                  label={
                    <Container
                      fullWidth={true}
                      className={classes.labelContainer}
                    >
                      {handleModeOfTransport(option.modeOfTransport, option)}
                    </Container>
                  }
                  className="ReassignLabel"
                />
              ))
            ) : (
              <div />
            )}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleCancel} color="primary">
            Cancel
          </Button> */}
          <Container
            className={classes.buttonContainer}
          >
          <Button
            className={`${classes.reassignButton} ReassignButton`}
            onClick={()=> {
              // console.log("id is", dispatchId)
              handleOk();
              }}
            // color="primary"
            size="large"
            fullWidth={true}
          >
            Reassign Rider
          </Button>
          </Container>
        </DialogActions>
      </Dialog>
    );
}
